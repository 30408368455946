export enum WarehouseItems {
  ManuallyAdded = "manuallyAdded",
  FromOrder = "fromOrder",
}

export enum WarehouseItemStatus {
  InStock = "inStock",
  Planned = "planned",
  Ordered = "ordered",
  Taken = "taken",
  Removed = "removed",
}

export interface IWarehouseItemStatus {
  label: string;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
}

type IWarehouseItemStatusesList = {
  [key in WarehouseItemStatus]: IWarehouseItemStatus;
};

export const WAREHOUE_ITEM_STATUSES: IWarehouseItemStatusesList = {
  [WarehouseItemStatus.InStock]: {
    label: "Noliktavā",
    color: "success",
  },
  [WarehouseItemStatus.Planned]: {
    label: "Ieplānots",
    color: "warning",
  },
  [WarehouseItemStatus.Taken]: {
    label: "Izmantots",
    color: "error",
  },
  [WarehouseItemStatus.Ordered]: {
    label: "Pasūtīts",
    color: "info",
  },
  [WarehouseItemStatus.Removed]: {
    label: "Izņemts",
    color: "default",
  },
};
