import { IProstheticModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import moment from "moment";
import { useFormik } from "formik";

import { Dialog, DialogActions, DialogTitle, Button, Stack, DialogContent } from "@mui/material";

import createProstheticReportSchema, {
  ProstheticReportBase,
} from "../schemas/createProstheticReport";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { MonthCalendar } from "@mui/x-date-pickers";

const ProstheticReportModal: React.FunctionComponent<IProstheticModalProps> = ({
  id,
  clinic,
  callback,
}) => {
  const dispatch = useAppDispatch();

  const { values, setFieldValue, handleSubmit, isSubmitting } = useFormik<ProstheticReportBase>({
    initialValues: { clinic, date: moment().toDate() },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      axios
        .post<{ _id: string }>("/prosthetics", values)
        .then((response) => {
          setSubmitting(false);
          handleClose();
          callback(response.data._id);
        })
        .catch((err) => {
          console.log("err:", err);
          setSubmitting(false);
        });
    },
    validationSchema: createProstheticReportSchema,
  });

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={true} onClose={handleClose}>
      <DialogTitle>Izveidot protezēšanas atskaiti</DialogTitle>
      <DialogContent style={{ paddingTop: 20 }}>
        <Stack alignItems="center">
          <MonthCalendar
            value={moment(values.date)}
            maxDate={moment().endOf("month")}
            onChange={(newValue) => {
              setFieldValue("date", newValue);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Atcelt</Button>
        <LoadingButton loading={isSubmitting} onClick={() => handleSubmit()}>
          Izveidot
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ProstheticReportModal;
