import * as yup from "yup";

const createProstheticReportSchema = yup.object({
  clinic: yup.string().required(),
  date: yup.date().required(),
});

export type ProstheticReportBase = yup.InferType<typeof createProstheticReportSchema>;

export default createProstheticReportSchema;
