import * as yup from "yup";

const userSchema = yup.object({
  name: yup.string().required(),
  dateOfBirth: yup.date().optional(),
});

export type UserBase = yup.InferType<typeof userSchema>;

export default userSchema;
