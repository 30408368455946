import { useEffect, useState, useCallback } from "react";

import axios from "axios";
import { toast } from "react-toastify";

import { Link as RouterLink } from "react-router-dom";

import {
  Stack,
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
  InputAdornment,
  IconButton,
  CardActionArea,
  CardContent,
  Card,
  LinearProgress,
  Link,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import {
  ICategoryResponse,
  IMaterialRawResponse,
  ISupplierResponse,
} from "../../../../types/classifiers";
import PermissionGuard from "../../../../components/PermissionGuard";
import PERMISSIONS from "../../../../constants/permissions";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  MaterialEditBase,
  materialEditSchema,
} from "../../../../schemas/material";
import CurrencyInput from "../../../../components/CurrencyInput";
import { LoadingButton } from "@mui/lab";
import { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router";
import ImageThumbnail from "../../../../components/ImageThumbnail";

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useAppDispatch } from "../../../../hooks/redux";
import { openModal } from "../../../../features/modals";
import MODALS from "../../../../constants/modals";

const Materials: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IMaterialRawResponse>();

  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [categories, setCategories] = useState<ICategoryResponse[]>([]);
  const [suppliers, setSuppliers] = useState<ISupplierResponse[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setUploading(true);

      const formData = new FormData();

      acceptedFiles.forEach((file) => {
        formData.append("images", file);
      });

      axios
        .post(`/classifiers/materials/${id}/images`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            console.log(
              "progress:",
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
            setUploadProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          },
        })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log("err:", err);
        })
        .finally(() => {
          setUploading(false);
          setUploadProgress(0);
        });
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop,
    // maxFiles: 1,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    maxSize: 1024 * 1024 * 10,
    disabled: uploading,
  });

  const formik = useFormik<MaterialEditBase>({
    initialValues: {
      name: data?.name || "",
      category: data?.category || "",
      suppliers: data?.suppliers || [],
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      axios
        .put<IMaterialRawResponse>("/classifiers/materials", {
          _id: id,
          ...values,
        })
        .then((res) => {
          setData(res.data);
          toast.success("Materiāls veiksmīgi saglabāts");
        })
        .catch((err) => {
          console.log("err:", err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    validationSchema: materialEditSchema,
  });
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    submitCount,
    isSubmitting,
    dirty,
  } = formik;

  const handleDelete = useCallback(() => {
    setIsDeleting(true);
    axios
      .delete(`/classifiers/materials/${id}`)
      .then(() => {
        toast.success("Materiāls veiksmīgi dzēsts");
        navigate("/manage/materials");
      })
      .catch((err) => {
        setIsDeleting(false);
        console.log("err:", err);
      });
  }, [id]);

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IMaterialRawResponse>(`/classifiers/materials/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    axios
      .get<ICategoryResponse[]>("/classifiers/categories")
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });

    axios
      .get<ISupplierResponse[]>("/classifiers/suppliers")
      .then((res) => {
        setSuppliers(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  return (
    <Stack spacing={4}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Materiāls</Typography>
        </Grid>
        <PermissionGuard permission={PERMISSIONS.DELETE_MATERIALS}>
          {({ hasAccess }) => (
            <>
              {hasAccess ? (
                <Grid item>
                  <LoadingButton
                    startIcon={<DeleteIcon />}
                    loading={isDeleting}
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      setIsDeleting(true);
                      dispatch(
                        openModal({
                          type: MODALS.Approve,
                          title: "Vai tiešām dzēst materiālu?",
                          message: "Materiāls tiks neatgriezeniski dzēsts.",
                          onApprove: () => {
                            handleDelete();
                          },
                          onReject: () => {
                            setIsDeleting(false);
                          },
                        })
                      );
                    }}
                  >
                    Dzēst
                  </LoadingButton>
                </Grid>
              ) : null}
            </>
          )}
        </PermissionGuard>
      </Grid>

      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Card
                    elevation={0}
                    style={{
                      borderWidth: 2,
                      borderStyle: "dashed",
                      borderColor: isDragActive
                        ? isDragReject
                          ? "rgba(255,0,0,0.5)"
                          : "rgb(0, 255, 0)"
                        : "rgba(0,0,0,0.5)",
                    }}
                  >
                    <CardActionArea>
                      <CardContent
                        style={{
                          width: 116,
                          height: 116,
                        }}
                      >
                        <Stack
                          style={{ height: "100%" }}
                          flex={1}
                          spacing={2}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {uploading ? (
                            <LinearProgress
                              style={{ width: "100%" }}
                              variant="determinate"
                              value={uploadProgress}
                            />
                          ) : (
                            [
                              <AddPhotoAlternateIcon key="icon" />,
                              <Typography
                                key="text"
                                variant="caption"
                                textAlign="center"
                                style={{
                                  marginTop: 0,
                                }}
                              >
                                {isDragActive
                                  ? "Nometiet attēlu šeit"
                                  : "Noklikšķiniet vai velciet attēlu šeit"}
                              </Typography>,
                            ]
                          )}
                        </Stack>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </div>
              </Grid>
              {data &&
                data.images.map((v, i) => (
                  <Grid item key={v._id} spacing={1}>
                    <ImageThumbnail
                      key={v._id}
                      value={v}
                      basePath={"/classifiers/materials/image/"}
                      // onDelete={() => onFileDelete(v)}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormikProvider value={formik}>
              <Stack spacing={2}>
                <TextField
                  id="name"
                  label="Nosaukums"
                  required
                  value={values.name}
                  onChange={handleChange}
                  error={Boolean(errors.name) && submitCount > 0}
                />
                <Autocomplete
                  options={categories}
                  getOptionLabel={(option) => option.name}
                  value={
                    categories.find(
                      (category) => values.category === category._id
                    ) || null
                  }
                  onChange={(
                    event: any,
                    newValue: ICategoryResponse | null
                  ) => {
                    setFieldValue("category", newValue ? newValue._id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Kategorija"
                      error={Boolean(errors.category) && submitCount > 0}
                    />
                  )}
                />
                <Stack spacing={1}>
                  <Typography variant="h6">Piegādātāji</Typography>

                  <FieldArray name="suppliers">
                    {({ push, remove }) => (
                      <Stack flex={1} spacing={2}>
                        {values.suppliers?.map((item, index) => (
                          <Stack
                            key={index}
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Autocomplete
                              fullWidth
                              options={suppliers}
                              getOptionLabel={(option) => option.name}
                              value={
                                suppliers.find(
                                  (supplier) => item.supplier === supplier._id
                                ) || null
                              }
                              onChange={(
                                event: any,
                                newValue: ISupplierResponse | null
                              ) => {
                                setFieldValue(
                                  `suppliers.${index}.supplier`,
                                  newValue ? newValue._id : null
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Piegādātājs"
                                  error={
                                    Boolean(
                                      errors.suppliers &&
                                        errors.suppliers[index]
                                    ) && submitCount > 0
                                  }
                                />
                              )}
                            />
                            <CurrencyInput
                              id={`suppliers.${index}.price`}
                              label="Cena"
                              value={item.price}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    €
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(value) => {
                                setFieldValue(
                                  `suppliers.${index}.price`,
                                  value
                                );
                              }}
                            />
                            <IconButton
                              onClick={() => {
                                remove(index);
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Stack>
                        ))}
                        <Button
                          startIcon={<AddIcon />}
                          onClick={() => {
                            push({ supplier: "", price: 0 });
                          }}
                        >
                          Pievienot piegādātāju
                        </Button>
                      </Stack>
                    )}
                  </FieldArray>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Link
                    component={RouterLink}
                    to="/manage/materials"
                    underline="none"
                  >
                    <Button variant="outlined">Atpakaļ</Button>
                  </Link>
                  <LoadingButton
                    variant="contained"
                    disabled={!dirty}
                    loading={isSubmitting}
                    onClick={() => handleSubmit()}
                  >
                    Saglabāt
                  </LoadingButton>
                </Stack>
              </Stack>
            </FormikProvider>
          </Grid>
        </Grid>
      </div>
    </Stack>
  );
};

export default Materials;
