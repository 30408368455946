import { useCallback, useEffect, useState } from "react";

import ROLES from "../constants/roles";

import { IClinicUserResponse } from "../types/user";

import _ from "lodash";
import axios from "axios";
import moment from "moment";

import {
  Autocomplete,
  Avatar,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import CircleIcon from "@mui/icons-material/Circle";
import { AbsenceBase } from "../schemas/absence";

interface IEmployeeAutocompleteProps {
  label: string;
  value?: string | null;
  clinic: string;
  date?: Date;
  disabled?: boolean;
  roles?: ROLES[];
  onChange: (newValue: string | null) => void;
  error?: boolean;
  helperText?: string;
}

const EmployeeAutocomplete: React.FC<IEmployeeAutocompleteProps> = ({
  value,
  clinic,
  date,
  disabled,
  roles,
  label,
  onChange,
  error,
  helperText,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  // TODO: this should come from global store and requests should be cached
  const [users, setUsers] = useState<IClinicUserResponse[]>([]);
  // TODO: this should come from global store and requests should be cached
  const [absences, setAbsences] = useState<AbsenceBase[]>([]);

  const loadClinicUsers = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IClinicUserResponse[]>(`/clinics/${clinic}/users`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clinic]);

  useEffect(() => {
    // TODO: implement this as global variable and cache requests
    loadClinicUsers();
  }, [loadClinicUsers]);

  const loadAbsences = useCallback(() => {
    if (!date) return;

    axios
      .get<AbsenceBase[]>(`/clinics/${clinic}/absences`, {
        params: {
          date,
        },
      })
      .then((response) => {
        setAbsences(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  }, [date]);

  useEffect(() => {
    // TODO: implement this as global variable and cache requests
    loadAbsences();
  }, [loadAbsences]);

  return (
    <Autocomplete
      value={value ? users.find((user) => user._id === value) ?? null : null}
      onChange={(event: any, newValue) => {
        onChange(newValue ? newValue._id : null);
      }}
      disabled={disabled}
      getOptionDisabled={(option) =>
        absences.findIndex(
          (absence) =>
            absence.user === option._id &&
            moment(date).isBetween(absence.from, absence.to)
        ) !== -1
      }
      renderOption={(props, option) => {
        return (
          <Stack
            {...props}
            component="li"
            direction="row"
            spacing={1}
            alignItems="center"
            key={option._id}
          >
            {option.workingDays &&
            // @ts-ignore
            option.workingDays[
              moment(date).locale("en").format("dddd").toLowerCase()
            ] ? null : (
              <CircleIcon
                color="warning"
                sx={{ fontSize: 10, marginRight: 1 }}
              />
            )}
            <Avatar
              alt={option.name}
              src={option.image}
              sx={{ width: 24, height: 24 }}
            />
            <Typography>{option.name}</Typography>
          </Stack>
        );
      }}
      isOptionEqualToValue={(option, value) => {
        return value && option._id === value._id;
      }}
      getOptionLabel={(option) => option.name}
      options={
        roles
          ? users.filter((user) =>
              _.some(user.roles, (role) => roles.includes(role))
            )
          : users
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            startAdornment: roles ? (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faUser} />
              </InputAdornment>
            ) : undefined,
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default EmployeeAutocomplete;
