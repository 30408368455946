enum MODALS {
  Approve = "Approve",
  Absence = "Absence",
  RequestAbsence = "RequestAbsence",
  AbsenceType = "AbsenceType",
  ProstheticReport = "ProstheticReport",
  ProstheticReportRecord = "ProstheticReportRecord",
  Holiday = "Holiday",
  Clinic = "Clinic",
  Chair = "Chair",
  User = "User",
  Laboratory = "Laboratory",
  Supplier = "Supplier",
  Category = "Category",
  Timesheet = "Timesheet",
  Roentgen = "Roentgen",
  RoentgenReport = "RoentgenReport",
  RoentgenReportRecord = "RoentgenReportRecord",
  Material = "Material",
  TimesheetEvent = "TimesheetEvent",
  Apartment = "Apartment",
  ReserveApartment = "ReserveApartment",
  WarehouseAddItems = "WarehouseAddItems",
  AddToCart = "AddToCart",
  OrderNumber = "OrderNumber",
  ReceivedItemCount = "ReceivedItemCount",
  WarehouseTakeItems = "WarehouseTakeItems",
}

export default MODALS;
