export enum HolidayTypes {
  holiday = "holiday",
  workday = "workday",
}

export interface IHolidayType {
  label: string;
}

type IHolydayTypesList = {
  [key in HolidayTypes]: IHolidayType;
};

export const HOLIDAY_TYPES: IHolydayTypesList = {
  [HolidayTypes.holiday]: {
    label: "Brīvdiena",
  },
  [HolidayTypes.workday]: {
    label: "Darba diena",
  },
};
