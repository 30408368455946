import React from "react";

import _ from "lodash";

import { TextField } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";

interface CurrencyInputProps extends Omit<TextFieldProps, "onChange" | "onBlur" | "type"> {
  onChange: (newValue: string) => void;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({ onChange, ...rest }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let input = event.target.value;
    // only allow digits and one decimal point
    if (!input.match(/^\d*\.?\d{0,2}$/)) {
      return;
    }
    onChange(input);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const rounded = _.round(parseFloat(input), 2).toFixed(2);

    onChange(rounded);
  };

  return <TextField {...rest} onChange={handleChange} onBlur={handleBlur} type="number" />;
};

export default CurrencyInput;
