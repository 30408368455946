import { CSSProperties, useCallback, useEffect, useState } from "react";

import PERMISSIONS from "../constants/permissions";
import { ClinicResponse } from "../types/clinic";

import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  UniqueIdentifier
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Link as RouterLink } from "react-router-dom";
import { useAppDispatch } from "../hooks/redux";
import axios from "axios";

import PermissionGuard from "../components/PermissionGuard";

import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  IconButton,
  CardHeader,
  Skeleton,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import AddIcon from "@mui/icons-material/Add";
import { openModal } from "../features/modals";
import MODALS from "../constants/modals";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import EditIcon from "@mui/icons-material/Edit";

const Clinics: React.FunctionComponent<{}> = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [clinics, setClinics] = useState<ClinicResponse[]>([]);
  
  const [items, setItems] = useState<UniqueIdentifier[]>([]);
  const [isEditable, setIsEditable] = useState(false);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<ClinicResponse[]>("/clinics")
      .then((res) => {
        setClinics(res.data);
        setItems(res.data.map((clinic) => clinic._id));
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        reorderClinic(active.id as string, newIndex);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

  }, []);

  const reorderClinic = (id: string, neworder: number) => {
    axios
      .post(`/clinics/${id}/reorder`, { neworder: neworder })
      .catch((err) => {
        console.log("error:", err);
      });
  };

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h4">Klīnikas</Typography>
        </Grid>
        <PermissionGuard permission={PERMISSIONS.WRITE_CLINICS}>
          {({ hasAccess }) => (
            <>
              {hasAccess ? (
                <Grid item>
                  <Stack direction={"row"}  spacing={1}>
                    <FormGroup>
                      <FormControlLabel
                        control={ <Switch onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                          setIsEditable(event.target.checked);
                          if(!event.target.checked) loadData();
                        }} /> } 
                        label={ <Typography variant="button" color="primary" >KĀRTOT</Typography> } />
                    </FormGroup>
                    <Button
                      startIcon={<AddIcon />}
                      onClick={() => {
                        dispatch(
                          openModal({
                            type: MODALS.Clinic,
                            callback: () => loadData(),
                          })
                        );
                      }}
                    >
                      Pievienot
                    </Button>
                  </Stack>
                </Grid>
              ) : null}
            </>
          )}
        </PermissionGuard>
      </Grid>
      {isEditable 
      ? (<DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd} >
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <Grid container spacing={2}>
              {items.map((id) => (
                <ClinicCard key={id} clinic={clinics.find((clinic) => clinic._id == id)!} callback={() => loadData()} isEditable={isEditable} />
              ))}
          </Grid>
        </SortableContext>
      </DndContext>)
      : (<Grid container spacing={2}>
        {isLoading 
          ? Array.from(Array(2)).map((_, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <SkeletonCard />
            </Grid> 
          ))
          : items.map((id) => (
            <ClinicCard key={id} clinic={clinics.find((clinic) => clinic._id == id)!} callback={() => loadData()} isEditable={isEditable} />
          ))}
      </Grid>)}
    </Stack>
  );
};

const SkeletonCard = () => (
  <Card>
    <CardContent>
      <Skeleton variant="text" sx={{  width: 1/3, fontSize: '1.5rem' }}/>
      <Stack sx={{ pt: 4.5 }}>
        <List>
          <ListItem>
            <Skeleton variant="text" sx={{  width: 3/4}}/>
          </ListItem>
          <ListItem>
            <Skeleton variant="text" sx={{  width: 1/2}}/>
          </ListItem>
        </List>
        <Stack direction="row" justifyContent="flex-end">
          <Skeleton variant="rounded" height={24}>
            <ArrowRightAltIcon />
          </Skeleton>
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

interface IClinicCardProps {
  clinic: ClinicResponse;
  callback: () => void;
  isEditable: boolean;
}

const ClinicCard: React.FC<IClinicCardProps> = ({ clinic, callback, isEditable }) => {
  const dispatch = useAppDispatch();

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: clinic._id });

  const inlineStyles: CSSProperties = {
    opacity: isDragging ? '0.7' : '1',
    transformOrigin: '50% 50%',
    height: '1',
    width: '1',
    cursor: isDragging ? 'grabbing' : 'grab',
    display: 'flex',
    background: "transparent",
    boxShadow: isDragging  ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px' : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  let cardSortableProps = {
    ref: setNodeRef,
    style: inlineStyles
  }

  return (
    <Grid item key={clinic._id} xs={12} sm={6} md={4}>
      <Card {...(isEditable ? {...cardSortableProps, ...attributes, ...listeners} : {})} >
        <CardActionArea component={isEditable ? "div" : RouterLink} to={`/${clinic._id}/schedule`} >
          <CardHeader
            title={clinic.name}
            action={
              <PermissionGuard permission={PERMISSIONS.WRITE_CLINICS}>
                {({ hasAccess }) => (
                  <>
                    {hasAccess ? (
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(
                            openModal({
                              type: MODALS.Clinic,
                              initialValues: clinic,
                              callback: () => callback(),
                            })
                          );
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    ) : null}
                  </>
                )}
              </PermissionGuard>
            }
          />
          <CardContent>
            <Stack>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <LocationOnIcon />
                  </ListItemIcon>
                  <ListItemText primary={clinic.address ? clinic.address : "—"} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText primary={clinic.phone ? clinic.phone : "—"} />
                </ListItem>
              </List>
              <Stack direction="row" justifyContent="flex-end">
                <ArrowRightAltIcon />
              </Stack>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default Clinics;
