import { BasicFile } from "../types/common";

// utils
import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  isOpen: boolean;
  images: string[];
  index: number;
} = {
  isOpen: false,
  images: [],
  index: 0,
};

const lightboxSlice = createSlice({
  name: "lightbox",
  initialState,
  reducers: {
    openLightbox: (
      state,
      {
        payload,
      }: {
        payload: {
          images: string[];
          index: number;
        };
      }
    ) => {
      state.isOpen = true;
      state.index = payload.index;
      state.images = payload.images;
    },
    closeLightbox: (state) => {
      state.isOpen = false;
      state.index = 0;
      state.images = [];
    },
  },
});

export const { openLightbox, closeLightbox } = lightboxSlice.actions;

export default lightboxSlice.reducer;
