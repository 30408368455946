// utils
import FsLightbox from "fslightbox-react";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { closeLightbox } from "../features/lightbox";

const LightboxManager: React.FC = () => {
  const dispatch = useAppDispatch();

  const lightbox = useAppSelector((state) => state.lightbox);

  if (!lightbox.isOpen) {
    return null;
  }

  return (
    <FsLightbox
      toggler={lightbox.isOpen}
      sources={lightbox.images}
      openOnMount
      type="image"
      onClose={() => {
        dispatch(closeLightbox());
      }}
    />
  );
};

export default LightboxManager;
