import * as yup from "yup";

const timesheetSchema = yup.object({
  startedAt: yup.date().nullable(),
  finishedAt: yup.date().nullable(),
});

export type TimesheetBase = yup.InferType<typeof timesheetSchema>;

export default timesheetSchema;
