import "./App.css";

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { routes } from "./config/routes";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import FullScreenLoader from "./components/FullScreenLoader";
import Auth from "./views/Auth";

import { BasicRoute, MenuRoute, RouteType, TRoute } from "./types/route";

function App() {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  // Populate authorization header with bearer token
  axios.interceptors.request.use(
    async (config) => {
      const token = await getAccessTokenSilently();
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  if (isLoading) {
    return <FullScreenLoader />;
  }

  if (!isAuthenticated) {
    return <Auth />;
  }

  return <RouterProvider router={router} />;

  // return (
  //   <BrowserRouter>
  //     <Routes>
  //       {/* <Route path="/" element={<Layout />}>
  //         {routes.map((route, index) =>
  //           route.type !== RouteType.Divider ? (
  //             <Route key={index} path={route.path} index element={route.element} />
  //           ) : null
  //         )}
  //       </Route> */}
  //       <Route element={<>404</>} />
  //     </Routes>
  //   </BrowserRouter>
  // );
}

const recoursiveRouteRenderer = (routes?: TRoute[]): JSX.Element[] | undefined => {
  if (!routes || routes.length <= 0) {
    return undefined;
  }

  return (
    routes.filter((route) => route.type !== RouteType.Divider) as (MenuRoute | BasicRoute)[]
  ).map((route, index) => {
    switch (route.index) {
      case true:
        return <Route key={index} index path={route.path} element={route.element} />;

      default:
        return (
          <Route key={index} path={route.path} element={route.element}>
            {recoursiveRouteRenderer(route.routes)}
          </Route>
        );
    }
  });
};

const router = createBrowserRouter(createRoutesFromElements(recoursiveRouteRenderer(routes)));

export default App;
