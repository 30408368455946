import { IMaterialModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import { useFormik } from "formik";
import axios from "axios";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import PermissionGuard from "../components/PermissionGuard";
import PERMISSIONS from "../constants/permissions";
import materialSchema, { MaterialBase } from "../schemas/material";
import { useEffect, useState } from "react";
import { ICategoryResponse, IMaterialResponse } from "../types/classifiers";
import { useNavigate } from "react-router";

const MaterialModal: React.FunctionComponent<IMaterialModalProps> = ({ id, callback }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [categories, setCategories] = useState<ICategoryResponse[]>([]);

  const { values, handleChange, handleSubmit, setFieldValue, errors, submitCount, isSubmitting } =
    useFormik<MaterialBase>({
      initialValues: {
        name: "",
        category: "",
      },
      enableReinitialize: true,
      onSubmit: (values, { setSubmitting }) => {
        setSubmitting(true);
        axios
          .post<IMaterialResponse>("/classifiers/materials", values)
          .then((res) => {
            handleClose();
            navigate(`/manage/materials/${res.data._id}`);
          })
          .catch((err) => {
            console.log("err:", err);
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
      validationSchema: materialSchema,
    });

  useEffect(() => {
    axios
      .get<ICategoryResponse[]>("/classifiers/categories")
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle>Pievienot jaunu materiālu</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            id="name"
            label="Nosaukums"
            required
            value={values.name}
            onChange={handleChange}
            error={Boolean(errors.name) && submitCount > 0}
          />
          <Autocomplete
            options={categories}
            getOptionLabel={(option) => option.name}
            value={categories.find((category) => values.category === category._id) || null}
            onChange={(event: any, newValue: ICategoryResponse | null) => {
              setFieldValue("category", newValue ? newValue._id : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Kategorija"
                error={Boolean(errors.category) && submitCount > 0}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} justifyContent="flex-end" flex={1}>
          <Button onClick={handleClose}>Aizvērt</Button>
          <PermissionGuard permission={PERMISSIONS.WRITE_MATERIALS}>
            {({ hasAccess }) => (
              <LoadingButton
                disabled={!hasAccess}
                loading={isSubmitting}
                onClick={() => {
                  handleSubmit();
                }}
                variant="contained"
              >
                Pievienot
              </LoadingButton>
            )}
          </PermissionGuard>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default MaterialModal;
