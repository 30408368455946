import * as yup from "yup";

const warehouseManualItemAddSchema = yup.object({
  count: yup.number().required(),
  materialId: yup.string().required(),
});

export type WarehouseManualItemAddBase = yup.InferType<typeof warehouseManualItemAddSchema>;

export default warehouseManualItemAddSchema;
