import { useEffect, useState } from "react";
import { IHolidayModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import { useFormik } from "formik";
import axios from "axios";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  TextField,
  Stack,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { ClinicResponse } from "../types/clinic";
import holidaySchema, { HolidayBase } from "../schemas/holiday";
import _ from "lodash";
import { HOLIDAY_TYPES, HolidayTypes } from "../constants/holidayTypes";

const HolidayModal: React.FunctionComponent<IHolidayModalProps> = ({
  id,
  callback,
  initialValues,
}) => {
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState(false);
  const [clinics, setClinics] = useState<ClinicResponse[]>([]);

  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    submitCount,
  } = useFormik<HolidayBase>({
    initialValues: {
      name: initialValues ? initialValues.name : "",
      from: initialValues ? initialValues.from : moment().toDate(),
      to: initialValues ? initialValues.to : moment().add(1, "day").toDate(),
      type: initialValues ? initialValues.type : HolidayTypes.holiday,
      clinics: initialValues
        ? initialValues.clinics.map((clinic) => clinic._id)
        : [],
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      axios
        .put(
          "/holidays",
          initialValues ? { _id: initialValues._id, ...values } : values
        )
        .then(() => {
          callback();
          handleClose();
        })
        .catch((err) => {
          console.log("err:", err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    validationSchema: holidaySchema,
  });

  const handleDelete = (id: string) => {
    setIsDeleting(true);
    axios
      .delete(`/holidays/${id}`)
      .then(() => {
        callback();
        handleClose();
      })
      .catch((err) => {
        console.log("err:", err);
        setIsDeleting(false);
      });
  };

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    axios
      .get<ClinicResponse[]>("/clinics")
      .then((res) => {
        setClinics(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle>
        {"_id" in values ? "Labot" : "Pievienot"} brīvdienu
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            id="name"
            label="Nosaukums"
            value={values.name}
            onChange={handleChange}
            error={Boolean(errors.name) && submitCount > 0}
          />
          <Stack spacing={2} direction="row">
            <DatePicker
              label="No"
              value={moment(values.from)}
              slotProps={{
                textField: {
                  fullWidth: true,

                  error: Boolean(errors.from) && submitCount > 0,
                },
              }}
              onChange={(newValue) => {
                if (moment(newValue).isAfter(moment(values.to))) {
                  setFieldValue("to", newValue ? newValue : null);
                }
                setFieldValue("from", newValue);
              }}
            />

            <DatePicker
              label="Līdz"
              value={moment(values.to)}
              minDate={moment(values.from)}
              onChange={(newValue) => {
                setFieldValue("to", newValue);
              }}
              slotProps={{
                textField: {
                  fullWidth: true,

                  error: Boolean(errors.to) && submitCount > 0,
                },
              }}
            />
          </Stack>
          <Autocomplete
            options={_.map(HOLIDAY_TYPES, (value, key) => ({
              key,
              label: value.label,
            }))}
            value={
              values.type
                ? { key: values.type, label: HOLIDAY_TYPES[values.type].label }
                : null
            }
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tips"
                error={Boolean(errors.clinics) && submitCount > 0}
              />
            )}
            onChange={(e, value) => {
              setFieldValue("type", value?.key);
            }}
          />
          <Autocomplete
            multiple
            options={clinics}
            getOptionLabel={(option) => option.name}
            value={clinics.filter((clinic) =>
              values.clinics?.includes(clinic._id)
            )}
            onChange={(event: any, newValue: ClinicResponse[] | null) => {
              setFieldValue(
                "clinics",
                newValue ? newValue.map((clinic) => clinic._id) : null
              );
            }}
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                label="Klīnikas"
                error={Boolean(errors.clinics) && submitCount > 0}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Aizvērt</Button>
        {initialValues ? (
          <LoadingButton
            loading={isDeleting}
            onClick={() => {
              handleDelete(initialValues._id);
            }}
            color="error"
            variant="outlined"
          >
            Dzēst
          </LoadingButton>
        ) : null}
        <LoadingButton
          loading={isSubmitting}
          disabled={isDeleting}
          onClick={() => {
            handleSubmit();
          }}
          variant="contained"
        >
          {initialValues ? "Saglabāt" : "Pievienot"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default HolidayModal;
