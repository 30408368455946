import { useEffect, useState } from "react";
import { ITimesheetModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import { useFormik } from "formik";
import axios from "axios";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  TextField,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import timesheetSchema, { TimesheetBase } from "../schemas/timesheet";

const TimesheetModal: React.FunctionComponent<ITimesheetModalProps> = ({
  id,
  callback,
  chairSchedule,
  user,
  initialValues,
}) => {
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState(false);
  const [duration, setDuration] = useState<moment.Duration>();

  const { values, handleChange, setFieldValue, handleSubmit, isSubmitting, errors, submitCount } =
    useFormik<TimesheetBase>({
      initialValues: initialValues || {
        startedAt: null,
        finishedAt: null,
      },
      onSubmit: (values, { setSubmitting }) => {
        setSubmitting(true);
        axios
          .put("/users/timesheet", {
            _id: initialValues?._id,
            scheduleId: chairSchedule._id,
            ...values,
          })
          .then(() => {
            callback();
            handleClose();
          })
          .catch((err) => {
            console.log("err:", err);
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
      validationSchema: timesheetSchema,
    });

  useEffect(() => {
    if (values.startedAt && values.finishedAt) {
      setDuration(moment.duration(moment(values.finishedAt).diff(moment(values.startedAt))));
    }
  }, [values.startedAt, values.finishedAt]);

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle>
        {chairSchedule.chairName} {chairSchedule.clinic?.name} -{" "}
        {moment(chairSchedule.date).format("LL")}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          {/* <Stack spacing={1}>
            <Typography>Krēsls: </Typography>
          </Stack> */}
          <Stack spacing={2}>
            <Typography variant="h6">
              Darba stundas {duration && `(${duration.hours()}h ${duration.minutes()} min)`}
            </Typography>
            <Stack spacing={2} direction="row">
              <TimePicker
                label="Sākuma laiks"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "standard",
                  },
                }}
                value={moment(values.startedAt)}
                onChange={(newValue) => {
                  const startedAt = moment(chairSchedule.date).set({
                    hour: moment(newValue).hour(),
                    minute: moment(newValue).minute(),
                  });
                  if (moment(startedAt).isAfter(moment(values.finishedAt))) {
                    setFieldValue("finishedAt", startedAt ? startedAt : null);
                  }
                  setFieldValue("startedAt", startedAt);
                }}
                minutesStep={15}
              />
              <TimePicker
                label="Beigu laiks"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "standard",
                  },
                }}
                value={moment(values.finishedAt)}
                onChange={(newValue) => {
                  const finishedAt = moment(chairSchedule.date).set({
                    hour: moment(newValue).hour(),
                    minute: moment(newValue).minute(),
                  });
                  setFieldValue("finishedAt", finishedAt);
                }}
                minutesStep={15}
              />
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Aizvērt</Button>
        {/* {initialValues ? (
          <LoadingButton
            loading={isDeleting}
            onClick={() => {
              handleDelete(initialValues._id);
            }}
            color="error"
            variant="outlined"
          >
            Dzēst
          </LoadingButton>
        ) : null} */}
        <LoadingButton
          loading={isSubmitting}
          disabled={isDeleting || moment().isBefore(moment(chairSchedule.date).startOf("day"))}
          onClick={() => {
            handleSubmit();
          }}
          variant="contained"
        >
          Saglabāt
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TimesheetModal;
