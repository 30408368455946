import * as yup from "yup";

const timesheetEventSchema = yup.object({
  clinic: yup.string().required(),
  startedAt: yup.date().nullable(),
  finishedAt: yup.date().nullable(),
});

export type TimesheetEventBase = yup.InferType<typeof timesheetEventSchema>;

export default timesheetEventSchema;
