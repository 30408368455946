import * as yup from "yup";

const prostheticReportRecordSchema = yup.object({
  patient: yup.string().nullable(),
  laboratory: yup.string().nullable(),
  laboratoryPrice: yup.number().nullable(),
  price: yup.number().nullable(),
});

export type ProstheticReportRecordBase = yup.InferType<typeof prostheticReportRecordSchema>;

export default prostheticReportRecordSchema;
