import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

import {
  Typography,
  Stack,
  IconButton,
  Avatar,
  Chip,
  Autocomplete,
  Popover,
  TextField,
  Badge,
} from "@mui/material";

import _ from "lodash";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { IUserBaseResponse } from "../../../types/user";
import { ClinicResponse } from "../../../types/clinic";
import { IRoentgenResponse } from "../../../types/classifiers";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IAllRoentgensReportsResponse } from "../../../types/roentgenReport";
import { REPORT_STATUSES, ReportStatuses } from "../../../constants/reportStatuses";

const ProstheticsReports: React.FunctionComponent<{}> = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [clinic, setClinic] = useState<ClinicResponse>();
  const [roentgens, setRoentgnes] = useState<IRoentgenResponse[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const [date, setDate] = useState<Date>(moment().toDate());
  const [records, setRecords] = useState<IAllRoentgensReportsResponse[]>([]);

  const [clinics, setClinics] = useState<ClinicResponse[]>([]);

  const [filterValues, setFilterValues] = useState<IFilterValues>({});
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFiltersAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFiltersAnchorEl(null);
  };

  const handleFilterChange = useCallback((values: IFilterValues) => {
    setFilterValues(values);
  }, []);

  const loadData = useCallback(() => {
    if (!clinic) {
      setRecords([]);
      return;
    }

    setIsLoading(true);
    axios
      .get<IAllRoentgensReportsResponse[]>(`/roentgens/reports`, {
        params: { date: date, clinic: clinic._id, filters: filterValues },
      })
      .then((response) => {
        setRecords(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
        setRecords([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [date, filterValues, clinic]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!clinic) {
      setRoentgnes([]);
      return;
    }

    axios
      .get<IRoentgenResponse[]>(`/classifiers/roentgens`, {
        params: { clinic: clinic._id },
      })
      .then((res) => {
        setRoentgnes(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, [clinic]);

  useEffect(() => {
    axios
      .get<ClinicResponse[]>("/clinics")
      .then((res) => {
        setClinics(res.data);
        setClinic(res.data[0]);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  useEffect(() => {
    const tmpColumns: GridColDef[] = [
      {
        field: "record.date",
        headerName: "Datums",
        type: "date",
        valueGetter: (params) =>
          params.row.record._id === "total"
            ? null
            : moment(params.row.record.date as Date).format("L"),
        sortable: false,
      },
      {
        field: "status",
        headerName: "Statuss",
        sortable: false,
        renderCell: (params) => {
          if (params.row.record._id === "total") {
            return null;
          }

          const { status } = params.row as IAllRoentgensReportsResponse;

          return (
            <Chip
              label={REPORT_STATUSES[status].label}
              variant={status === ReportStatuses.Opened ? "filled" : "outlined"}
              size="small"
              color={status === ReportStatuses.Opened ? "success" : "default"}
            />
          );
        },
      },
      {
        field: "user",
        headerName: "Darbinieks",
        sortable: false,
        renderCell: (params) => {
          if (params.row.record._id === "total") {
            return null;
          }

          return params.row.user.name;
        },
      },
      {
        field: "record.doctor",
        headerName: "Ārsts",
        sortable: false,
        renderCell: (params) => {
          if (params.row.record._id === "total") {
            return (
              <Stack direction="row" justifyContent="flex-end" flex={1}>
                <Typography>Kopā:</Typography>
              </Stack>
            );
          }

          return params.row.record.doctor.name;
        },
        flex: 1,
      },
      ...roentgens.map(
        (roentgen): GridColDef => ({
          field: `record.roentgens.${roentgen._id}`,
          headerName: roentgen.name,
          sortable: false,
          type: "number",
          valueGetter: (params) => params.row.record.roentgens[roentgen._id],
        })
      ),
    ];

    setColumns(tmpColumns);
  }, [roentgens]);

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h4">Rentgenu pārskats</Typography>
        <Autocomplete
          options={clinics}
          style={{ width: 300 }}
          value={clinic || null}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Klīnika" />}
          onChange={(e, value) => {
            setClinic(value || undefined);
          }}
          size="small"
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton
            onClick={() =>
              setDate((currentDate) => moment(currentDate).subtract(1, "month").toDate())
            }
          >
            <ChevronLeftIcon />
          </IconButton>
          <Typography>
            {moment(date).startOf("month").format("LL")} -{" "}
            {moment(date).endOf("month").format("LL")}
          </Typography>
          <IconButton
            onClick={() => setDate((currentDate) => moment(currentDate).add(1, "month").toDate())}
          >
            <ChevronRightIcon />
          </IconButton>
        </Stack>

        <IconButton onClick={handleFilterClick}>
          <Badge
            color="secondary"
            badgeContent={Object.values(filterValues).filter((item) => item).length}
          >
            <FilterAltIcon />
          </Badge>
        </IconButton>
      </Stack>

      <DataGrid
        sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
        loading={isLoading}
        getRowId={(row) => row.record._id}
        rows={records}
        columns={columns}
        autoHeight
        disableSelectionOnClick
        disableColumnMenu
      />

      <Popover
        open={Boolean(filtersAnchorEl)}
        anchorEl={filtersAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
      >
        <Filters values={filterValues} onFilterChange={handleFilterChange} />
      </Popover>
    </Stack>
  );
};

interface IFilterValues {
  user?: string;
}

interface IFIlterProps {
  values: IFilterValues;
  onFilterChange: (values: IFilterValues) => void;
}

const Filters: React.FunctionComponent<IFIlterProps> = ({ values, onFilterChange }) => {
  const [users, setUsers] = useState<IUserBaseResponse[]>([]);

  useEffect(() => {
    axios
      .get<IUserBaseResponse[]>(`/users/list`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
      });
  }, []);

  useEffect(() => {
    onFilterChange(values);
  }, [values]);

  return (
    <Stack spacing={2} sx={{ padding: 2, width: 400, maxWidth: "100%" }}>
      <Typography variant="h6">Filtri</Typography>
      <Autocomplete
        options={users}
        value={users ? users.find((item) => item._id === values.user) : null}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Darbinieks" />}
        renderOption={(props, option) => {
          return (
            <Stack
              {...props}
              component="li"
              direction="row"
              spacing={1}
              alignItems="center"
              key={option._id}
            >
              <Avatar alt={option.name} src={option.image} sx={{ width: 24, height: 24 }} />
              <Typography>{option.name}</Typography>
            </Stack>
          );
        }}
        onChange={(e, value) => {
          onFilterChange({
            ...values,
            user: value ? value._id : undefined,
          });
        }}
      />
    </Stack>
  );
};

export default ProstheticsReports;
