import { useState, useCallback } from "react";

import MODALS from "../constants/modals";

import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { openModal } from "../features/modals";
import axios from "axios";

import {
  Stack,
  Grid,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Divider,
} from "@mui/material";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import PermissionGuard from "../components/PermissionGuard";
import PERMISSIONS from "../constants/permissions";
import _ from "lodash";
import { ICartItemResponse } from "../types/cartItem";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  decreaseCount,
  getCartItems,
  increaseCount,
  removeItem,
} from "../features/cart";
import ImageThumbnail from "../components/ImageThumbnail";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";

const columns: GridColDef<ICartItemResponse>[] = [
  {
    field: "images",
    headerName: "Attēls",
    sortable: false,
    renderCell: (params) => {
      if (!params.row.material.images) return null;
      return (
        <ImageThumbnail
          value={params.row.material.images[0]}
          basePath="/classifiers/materials/image/"
        />
      );
    },
  },
  {
    field: "material",
    headerName: "Materiāls",
    valueGetter: (params) => params.row.material.name,
    flex: 1,
  },
  {
    field: "supplier",
    headerName: "Piegādātājs",
    valueGetter: (params) => params.row.supplier.name,
    minWidth: 200,
  },
  {
    field: "price",
    headerName: "Cena",
    valueGetter: (params) => {
      const supplier = params.row.material.suppliers.find(
        (supplier) => supplier.supplier._id === params.row.supplier._id
      );

      if (!supplier) {
        return "-";
      }

      return `${supplier.price} €`;
    },
  },
  {
    field: "count",
    headerName: "Skaits",
    minWidth: 130,
    renderCell: (params) => (
      <PermissionGuard permission={[PERMISSIONS.WRITE_BASKET]}>
        {({ hasAccess }) => (
          <>{hasAccess ? <Count row={params.row} /> : params.value}</>
        )}
      </PermissionGuard>
    ),
  },
  {
    field: "total",
    headerName: "Kopā",
    valueGetter: (params) => {
      const supplier = params.row.material.suppliers.find(
        (supplier) => supplier.supplier._id === params.row.supplier._id
      );

      if (!supplier) {
        return "-";
      }

      return `${supplier.price * params.row.count} €`;
    },
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    width: 55,
    renderCell: (params) => (
      <PermissionGuard permission={[PERMISSIONS.DELETE_BASKET]}>
        {({ hasAccess }) => (
          <>{hasAccess ? <RowActions row={params.row} /> : null}</>
        )}
      </PermissionGuard>
    ),
  },
];

const Cart: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const cart = useAppSelector((state) => state.cart);

  const [isSubmitting, setSubmitting] = useState(false);

  const order = () => {
    setSubmitting(true);
    // TODO: add approve dialog and ask if user is sure to send order to suppliers
    axios
      .post("/cart/order", cart.cartItems)
      .then((res) => {
        dispatch(getCartItems());
        navigate("/manage/orders");
        console.log(res);
      })
      .catch((err) => {})
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Pasūtījuma saraksts</Typography>
        </Grid>
      </Grid>

      {_.map(
        _.groupBy(cart.cartItems, (item) => item.clinic.name),
        (group, index) => {
          return (
            <Stack key={group[0].clinic._id} spacing={1}>
              <Typography variant="h5">{index}</Typography>
              <Stack spacing={1}>
                <DataGrid
                  getRowId={(row) => row._id}
                  rows={group}
                  columns={columns}
                  hideFooterPagination
                  autoHeight
                  disableSelectionOnClick
                  disableColumnMenu
                  hideFooter
                />
                <Stack direction="row" spacing={1} justifyContent="flex-end">
                  <Typography variant="h5">
                    {index} kopā:{" "}
                    {_.sumBy(group, (item) => {
                      const supplier = item.material.suppliers.find(
                        (supplier) =>
                          supplier.supplier._id === item.supplier._id
                      );

                      if (!supplier) {
                        return 0;
                      }

                      return supplier.price * item.count;
                    })}{" "}
                    €
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          );
        }
      )}

      <Divider />
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <Typography variant="h5">
          Kopā:{" "}
          {_.sumBy(cart.cartItems, (item) => {
            const supplier = item.material.suppliers.find(
              (supplier) => supplier.supplier._id === item.supplier._id
            );

            if (!supplier) {
              return 0;
            }

            return supplier.price * item.count;
          })}{" "}
          €
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <PermissionGuard permission={[PERMISSIONS.WRITE_ORDERS]}>
          {({ hasAccess }) => (
            <>
              {hasAccess ? (
                <LoadingButton
                  disabled={cart.cartItems.length === 0}
                  loading={isSubmitting}
                  variant="contained"
                  color="primary"
                  onClick={() => order()}
                >
                  Izveidot pasūtījumu(s)
                </LoadingButton>
              ) : null}
            </>
          )}
        </PermissionGuard>
      </Stack>
    </Stack>
  );
};

const Count: React.FC<{ row: ICartItemResponse }> = ({ row }) => {
  const dispatch = useAppDispatch();

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      flex={1}
    >
      <IconButton
        size="small"
        edge="start"
        disabled={row.count === 1}
        onClick={() => dispatch(decreaseCount(row._id))}
      >
        <RemoveIcon />
      </IconButton>
      <Typography variant="body2">{row.count}</Typography>
      <IconButton
        size="small"
        edge="end"
        onClick={() => dispatch(increaseCount(row._id))}
      >
        <AddIcon />
      </IconButton>
    </Stack>
  );
};

const RowActions: React.FC<{ row: ICartItemResponse }> = ({ row }) => {
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = useCallback(() => {
    dispatch(removeItem(row._id));
  }, [dispatch, row]);

  return (
    <Stack direction="row" spacing={1}>
      <IconButton
        size="small"
        disabled={isDeleting}
        onClick={() => {
          setIsDeleting(true);

          dispatch(
            openModal({
              type: MODALS.Approve,
              title: "Vai tiešām izņemt materiālu no pasūtījuma?",

              onApprove: () => {
                handleDelete();
              },
              onReject: () => {
                setIsDeleting(false);
              },
            })
          );
        }}
      >
        {isDeleting ? <CircularProgress size={24} /> : <DeleteIcon />}
      </IconButton>
    </Stack>
  );
};

export default Cart;
