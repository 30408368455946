import * as yup from "yup";

const absenceSchema = yup.object({
  user: yup.string().required(),
  type: yup.string().required(),
  from: yup.date().required(),
  to: yup.date().required(),
});

export type AbsenceBase = yup.InferType<typeof absenceSchema>;

export const requestAbsenceSchema = yup.object({
  type: yup.string().required(),
  from: yup.date().required(),
  to: yup.date().required(),
});

export type RequestAbsenceBase = yup.InferType<typeof requestAbsenceSchema>;

export default absenceSchema;
