import { useCallback, useEffect, useState } from "react";
import { IUserResponse } from "../types/user";
import { IUserModalProps } from "../types/modal";
import ROLES, { IRole, ROLES_LIST } from "../constants/roles";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import _ from "lodash";
import axios from "axios";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  Stack,
  Chip,
  Avatar,
  Typography,
  Divider,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";

import { ClinicResponse } from "../types/clinic";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import userSchema from "../schemas/user";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const UserModal: React.FunctionComponent<IUserModalProps> = ({
  id,
  user,
  callback,
}) => {
  const dispatch = useAppDispatch();

  const [userLocalCopy, setUserLocalCopy] = useState(user);
  const [clinics, setClinics] = useState<ClinicResponse[]>([]);

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    errors,
    submitCount,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userLocalCopy.name,
      dateOfBirth: userLocalCopy.dateOfBirth,
    },
    validationSchema: userSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      axios
        .post(`/users/${user._id}`, values)
        .then((res) => {
          setUserLocalCopy(res.data);
          callback(res.data);
        })
        .catch((err) => {
          console.log("err:", err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    axios
      .get<ClinicResponse[]>("/clinics")
      .then((res) => {
        setClinics(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={true} onClose={handleClose}>
      <DialogTitle>Labot lietotāju</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar alt={user.name} src={user.image} />
            <Stack flex={1} spacing={2}>
              <TextField
                id="name"
                label="Vārds, Uzvārds"
                fullWidth
                value={values.name}
                onChange={handleChange}
                size="small"
                error={Boolean(errors.name) && submitCount > 0}
              />
              <TextField
                id="name"
                label="E-pasts"
                fullWidth
                disabled
                value={userLocalCopy.email}
                size="small"
              />
              <DatePicker
                label="Dzimšanas datums"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                value={moment(values.dateOfBirth)}
                onChange={(newValue) => {
                  setFieldValue("dateOfBirth", newValue);
                }}
              />
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              loading={isSubmitting}
              onClick={() => handleSubmit()}
            >
              Saglabāt
            </LoadingButton>
          </Stack>
          <Divider />
          <Typography>Klīnikas</Typography>
          <div>
            <Grid container spacing={1}>
              {clinics.map((clinic) => {
                return (
                  <Grid key={clinic._id} item>
                    <ClinicChip
                      hasAccess={Boolean(
                        userLocalCopy.clinics &&
                          userLocalCopy.clinics
                            .map((clinic) => clinic._id)
                            .includes(clinic._id)
                      )}
                      clinic={clinic}
                      userId={user._id}
                      callback={(newUser) => {
                        setUserLocalCopy(newUser);
                        callback(newUser);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>

          <Typography>Lomas</Typography>
          <div>
            <Grid container spacing={1}>
              {_.map(ROLES_LIST, (role: IRole, key: ROLES) => {
                return (
                  <Grid key={key} item>
                    <RoleChip
                      hasAccess={Boolean(
                        userLocalCopy.roles && userLocalCopy.roles.includes(key)
                      )}
                      role={key}
                      userId={user._id}
                      callback={(newUser) => {
                        setUserLocalCopy(newUser);
                        callback(newUser);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Aizvērt</Button>
      </DialogActions>
    </Dialog>
  );
};

interface IClinicChipProps {
  hasAccess: boolean;
  clinic: ClinicResponse;
  userId: string;
  callback: (user: IUserResponse) => void;
}

const ClinicChip: React.FunctionComponent<IClinicChipProps> = ({
  hasAccess,
  clinic,
  userId,
  callback,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClick = useCallback(() => {
    setIsLoading(true);
    if (hasAccess) {
      axios
        .delete<IUserResponse>(`/users/${userId}/clinics/${clinic._id}`)
        .then((response) => {
          callback(response.data);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      axios
        .post<IUserResponse>(`/users/${userId}/clinics`, {
          clinicId: clinic._id,
        })
        .then((response) => {
          callback(response.data);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [clinic._id, hasAccess, userId]);

  return (
    <Chip
      icon={
        isLoading ? (
          <CircularProgress color="inherit" size={20} />
        ) : hasAccess ? (
          <DoneIcon />
        ) : undefined
      }
      key={clinic._id}
      label={clinic.name}
      onClick={onClick}
      color={hasAccess ? "success" : undefined}
      variant={hasAccess ? undefined : "outlined"}
    />
  );
};

interface IRoleChipProps {
  hasAccess: boolean;
  role: ROLES;
  userId: string;
  callback: (user: IUserResponse) => void;
}

const RoleChip: React.FunctionComponent<IRoleChipProps> = ({
  hasAccess,
  role,
  userId,
  callback,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClick = useCallback(() => {
    setIsLoading(true);
    if (hasAccess) {
      axios
        .delete<IUserResponse>(`/users/${userId}/roles/${role}`)
        .then((response) => {
          callback(response.data);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      axios
        .post<IUserResponse>(`/users/${userId}/roles`, { role })
        .then((response) => {
          callback(response.data);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [hasAccess, userId]);

  return (
    <Chip
      icon={
        isLoading ? (
          <CircularProgress color="inherit" size={20} />
        ) : hasAccess ? (
          <DoneIcon />
        ) : undefined
      }
      label={ROLES_LIST[role].name}
      onClick={onClick}
      color={hasAccess ? "success" : undefined}
      variant={hasAccess ? undefined : "outlined"}
    />
  );
};

export default UserModal;
