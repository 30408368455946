import { useEffect, useState, useCallback, useMemo } from "react";

import axios from "axios";

import { Link as RouterLink } from "react-router-dom";

import {
  Stack,
  Grid,
  Typography,
  Chip,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  IconButton,
  Link,
} from "@mui/material";

import { IPaginationResponse } from "../../../types/common";

import moment from "moment";
import { IOrderResponse } from "../../../types/order";
import { ORDER_STATUSES } from "../../../constants/orderStatuses";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import { ISupplierResponse } from "../../../types/classifiers";
import { ClinicResponse } from "../../../types/clinic";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

import FileList from "../../../components/FileList";

const Orders: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] =
    useState<IPaginationResponse<IOrderResponse>>();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: "createdAt",
      desc: true,
    },
  ]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );

  const [clinics, setClinics] = useState<ClinicResponse[]>([]);
  const [suppliers, setSuppliers] = useState<ISupplierResponse[]>([]);

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IPaginationResponse<IOrderResponse>>("/order", {
        params: {
          pagination,
          sorting,
          columnFilters,
        },
      })
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pagination, columnFilters, sorting]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    axios
      .get<ClinicResponse[]>("/clinics")
      .then((res) => {
        setClinics(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });

    axios
      .get<ISupplierResponse[]>("/classifiers/suppliers")
      .then((res) => {
        setSuppliers(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  const columns = useMemo<MRT_ColumnDef<IOrderResponse>[]>(
    () => [
      {
        header: "Pasūtījuma numurs",
        accessorKey: "orderNumber",
        maxSize: 70,
      },
      {
        header: "Piegādātājs",
        accessorKey: "supplier",
        accessorFn: (originalRow) => {
          if (!originalRow.supplier) {
            return "";
          }
          return originalRow.supplier.name;
        },
        filterVariant: "multi-select",
        filterSelectOptions: suppliers.map((supplier) => {
          return {
            text: supplier.name,
            value: supplier._id,
          };
        }),
      },
      {
        header: "Klīnika",
        accessorKey: "clinic",
        accessorFn: (originalRow) => {
          if (!originalRow.clinic) {
            return "";
          }
          return originalRow.clinic.name;
        },
        filterVariant: "multi-select",
        filterSelectOptions: clinics.map((clinic) => {
          return {
            text: clinic.name,
            value: clinic._id,
          };
        }),
      },
      {
        header: "Summa",
        maxSize: 70,
        accessorFn: (originalRow) => {
          return `${originalRow.items.reduce((acc, item) => {
            return acc + item.price * item.count;
          }, 0)}€`;
        },
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Statuss",
        accessorKey: "status",
        maxSize: 70,
        accessorFn: (originalRow) => {
          if (!originalRow.status) {
            return "";
          }
          const orderStatus = ORDER_STATUSES[originalRow.status];

          return (
            <Chip
              label={orderStatus.label}
              color={orderStatus.color}
              variant="outlined"
              size="small"
            />
          );
        },
        filterVariant: "multi-select",
        filterSelectOptions: _.map(ORDER_STATUSES, (status, key) => {
          return {
            text: status.label,
            value: key,
          };
        }),
      },
      {
        header: "Izveidots",
        accessorKey: "createdAt",
        maxSize: 70,
        accessorFn: (originalRow) => {
          return moment(originalRow.createdAt).format("L");
        },
        enableColumnFilter: false,
      },
    ],
    [suppliers]
  );

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Pasūtījumi</Typography>
        </Grid>
      </Grid>
      <MaterialReactTable
        columns={columns}
        enableColumnActions={false}
        data={response?.docs || []}
        getRowId={(row) => row._id}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => {
          return (
            <Link
              component={RouterLink}
              to={`/manage/orders/${row.original._id}`}
            >
              <IconButton>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
              </IconButton>
            </Link>
          );
        }}
        localization={{
          actions: "Atvērt",
        }}
        renderDetailPanel={(params) => (
          <DetailPanel row={params.row.original} />
        )}
        manualPagination
        rowCount={response?.totalDocs || 0}
        onPaginationChange={setPagination}
        manualSorting
        onSortingChange={setSorting}
        manualFiltering
        onColumnFiltersChange={setColumnFilters}
        enableGlobalFilter={false}
        state={{
          isLoading,
          pagination,
          columnFilters,
          sorting,
        }}
      />
    </Stack>
  );
};

const DetailPanel: React.FC<{ row: IOrderResponse }> = ({ row }) => {
  return (
    <Stack spacing={2}>
      <Stack>
        <Typography variant="h6" gutterBottom component="div">
          Pasūtījums
        </Typography>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Materiāls</TableCell>
              <TableCell>Skaits</TableCell>
              <TableCell>Cena</TableCell>
              <TableCell>Summa</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {row.items.map((item) => {
              return (
                <TableRow key={item._id}>
                  <TableCell>{item.material.name}</TableCell>
                  <TableCell>{item.count}</TableCell>
                  <TableCell>{item.price}€</TableCell>
                  <TableCell>{item.price * item.count}€</TableCell>
                </TableRow>
              );
            })}
            <TableRow selected>
              <TableCell colSpan={3} align="right" variant="head">
                Kopā:
              </TableCell>
              <TableCell variant="head">
                {row.items.reduce((acc, item) => {
                  return acc + item.price * item.count;
                }, 0)}
                €
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
      {row.files && row.files.length > 0 && <FileList files={row.files} />}
    </Stack>
  );
};

export default Orders;
