import * as yup from "yup";

const apartmentSchema = yup.object({
  name: yup.string().required(),
  clinic: yup.string().required(),
});

export type ApartmentBase = yup.InferType<typeof apartmentSchema>;

export const reserveApartmentSchema = yup.object({
  user: yup.string(),
  from: yup.date().required(),
  to: yup.date().required(),
});

export type ReserveApartmentBase = yup.InferType<typeof reserveApartmentSchema>;

export default apartmentSchema;
