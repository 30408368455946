import { useEffect, useState, useCallback } from "react";

import MODALS from "../constants/modals";

import { useAppDispatch } from "../hooks/redux";
import { openModal } from "../features/modals";
import moment from "moment";
import axios from "axios";

import { Stack, Grid, Typography, Button, Chip } from "@mui/material";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { IHolidayResponse } from "../types/holiday";
import { ClinicResponse } from "../types/clinic";
import PermissionGuard from "../components/PermissionGuard";
import PERMISSIONS from "../constants/permissions";
import { HOLIDAY_TYPES } from "../constants/holidayTypes";

const columns: GridColDef<IHolidayResponse>[] = [
  {
    field: "name",
    headerName: "Nosaukums",
    sortable: false,
    flex: 1,
  },
  {
    field: "clinics",
    headerName: "Klīnikas",
    sortable: false,
    renderCell: (params) => {
      return (
        <Grid container spacing={0.5}>
          {params.value.map((clinic: ClinicResponse) => {
            return (
              <Grid item key={clinic._id}>
                <Chip
                  label={clinic.name}
                  variant="outlined"
                  size="small"
                  sx={{ fontSize: "0.7rem", height: 1 }}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    },
    flex: 1,
  },
  {
    field: "from",
    headerName: "No",
    type: "date",
    valueGetter: (params) => moment(params.row.from as Date).format("L"),
    sortable: false,
  },
  {
    field: "to",
    headerName: "Līdz",
    type: "date",
    valueGetter: (params) => moment(params.row.to as Date).format("L"),
    sortable: false,
  },
  {
    field: "type",
    headerName: "Tips",
    sortable: false,
    renderCell: (params) => {
      return <Chip label={HOLIDAY_TYPES[params.row.type].label} />;
    },
  },
];

const Holidays: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [holidays, setHolidays] = useState<IHolidayResponse[]>([]);

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IHolidayResponse[]>("/holidays")
      .then((res) => {
        setHolidays(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Brīvdienas</Typography>
        </Grid>
        <PermissionGuard permission={PERMISSIONS.WRITE_HOLIDAYS}>
          {({ hasAccess }) => (
            <>
              {hasAccess ? (
                <Grid item>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      dispatch(
                        openModal({
                          type: MODALS.Holiday,
                          callback: () => loadData(),
                        })
                      );
                    }}
                  >
                    Pievienot
                  </Button>
                </Grid>
              ) : null}
            </>
          )}
        </PermissionGuard>
      </Grid>
      <PermissionGuard permission={PERMISSIONS.WRITE_HOLIDAYS}>
        {({ hasAccess }) => (
          <DataGrid
            sx={{
              "& .MuiDataGrid-row": {
                cursor: hasAccess ? "pointer" : "cursor",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: 1,
              },
            }}
            loading={isLoading}
            getRowId={(row) => row._id}
            onRowClick={(params) => {
              if (!hasAccess) {
                return;
              }
              dispatch(
                openModal({
                  type: MODALS.Holiday,
                  initialValues: params.row,
                  callback: () => loadData(),
                })
              );
            }}
            rows={holidays}
            columns={columns}
            getRowHeight={() => "auto"}
            hideFooterPagination
            autoHeight
            disableSelectionOnClick
            disableColumnMenu
            hideFooter
          />
        )}
      </PermissionGuard>
    </Stack>
  );
};

export default Holidays;
