import { IProstheticRecordModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import { useFormik } from "formik";
import _ from "lodash";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Stack,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";

import prostheticReportRecordSchema, {
  ProstheticReportRecordBase,
} from "../schemas/prostheticReportRecord";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { ILaboratoryResponse } from "../types/classifiers";
import { useEffect, useState } from "react";
import { IProstheticReportRecordResponse } from "../types/prostheticReport";

import CurrencyInput from "../components/CurrencyInput";

const ProstheticReportRecordModal: React.FunctionComponent<IProstheticRecordModalProps> = ({
  id,
  report,
  record,
  callback,
}) => {
  const dispatch = useAppDispatch();

  const [laboratories, setLaboratories] = useState<ILaboratoryResponse[]>([]);

  const { values, setFieldValue, handleSubmit, isSubmitting, handleChange } =
    useFormik<ProstheticReportRecordBase>({
      initialValues: {
        patient: record?.patient,
        laboratory: record?.laboratory?._id,
        laboratoryPrice: record?.laboratoryPrice,
        price: record?.price,
      },
      onSubmit: (values, { setSubmitting }) => {
        setSubmitting(true);
        axios
          .put<IProstheticReportRecordResponse>(`/prosthetics/${report}`, {
            ...values,
            _id: record ? record._id : null,
          })
          .then((res) => {
            handleClose();
            callback(res.data);
          })
          .catch((err) => {
            console.log("error:", err);
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
      validationSchema: prostheticReportRecordSchema,
    });

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    axios
      .get<ILaboratoryResponse[]>("/classifiers/laboratories")
      .then((res) => {
        setLaboratories(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={true} onClose={handleClose}>
      <DialogTitle>Pievienot protezēšanas ierakstu</DialogTitle>
      <DialogContent style={{ paddingTop: 20 }}>
        <Stack spacing={2}>
          <TextField
            id="patient"
            label="Pacients"
            variant="standard"
            value={values.patient ? values.patient : ""}
            onChange={handleChange}
          />
          <FormControl variant="standard">
            <InputLabel id="laboratory-select">Zobu tehniķis/ laboratorija</InputLabel>
            <Select
              labelId="laboratory-select"
              id="laboratory"
              label="Zobu tehniķis/ laboratorija"
              value={values.laboratory ? values.laboratory : ""}
              onChange={(event) => {
                setFieldValue("laboratory", event.target.value ? event.target.value : null);
              }}
            >
              <MenuItem value="">Neviens</MenuItem>
              {laboratories.map((laboratory) => (
                <MenuItem key={laboratory._id} value={laboratory._id}>
                  {laboratory.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <CurrencyInput
            label="Laboratorijas cena"
            variant="standard"
            value={values.laboratoryPrice ? values.laboratoryPrice : ""}
            onChange={(newValue) => {
              setFieldValue("laboratoryPrice", newValue);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>,
            }}
          />
          <CurrencyInput
            id="price"
            label="Summa par protezēšanu"
            variant="standard"
            value={values.price ? values.price : ""}
            onChange={(newValue) => {
              setFieldValue("price", newValue);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>,
            }}
          />
          <TextField
            label="Summa pēc labor. izd. atskaitīšanas"
            disabled
            variant="standard"
            value={_.round(
              (values.price ? values.price : 0) -
                (values.laboratoryPrice ? values.laboratoryPrice : 0),
              2
            )}
            InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>,
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Atcelt</Button>
        <LoadingButton loading={isSubmitting} variant="contained" onClick={() => handleSubmit()}>
          {record ? "Saglabāt" : "Pievienot"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ProstheticReportRecordModal;
