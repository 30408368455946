import { useEffect, useState, useCallback, useMemo } from "react";

import MODALS from "../../../../constants/modals";

import { Link as RouterLink } from "react-router-dom";

import { useAppDispatch } from "../../../../hooks/redux";
import { openModal } from "../../../../features/modals";
import axios from "axios";

import {
  Stack,
  Grid,
  Typography,
  Button,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { IPaginationResponse } from "../../../../types/common";
import {
  ICategoryResponse,
  IMaterialResponse,
  ISupplierResponse,
} from "../../../../types/classifiers";
import PermissionGuard from "../../../../components/PermissionGuard";
import PERMISSIONS from "../../../../constants/permissions";

import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import ImageThumbnail from "../../../../components/ImageThumbnail";

const Materials: React.FC = () => {
  const dispatch = useAppDispatch();

  const [suppliers, setSuppliers] = useState<ISupplierResponse[]>([]);
  const [categories, setCategories] = useState<ICategoryResponse[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] =
    useState<IPaginationResponse<IMaterialResponse>>();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IPaginationResponse<IMaterialResponse>>(
        "/classifiers/materials/list",
        {
          params: {
            pagination,
            sorting,
            columnFilters,
          },
        }
      )
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pagination, columnFilters, sorting]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = useMemo<MRT_ColumnDef<IMaterialResponse>[]>(
    () => [
      {
        header: "Attēls",
        sortable: false,
        accessorFn: (params) => {
          if (!params.images) return null;
          return (
            <ImageThumbnail
              value={params.images[0]}
              basePath="/classifiers/materials/image/"
            />
          );
        },
      },
      {
        header: "Nosaukums",
        accessorKey: "name",
      },
      {
        header: "Kategorija",
        accessorKey: "category",
        accessorFn: (originalRow) => {
          if (!originalRow.category) {
            return "";
          }
          return originalRow.category.name;
        },
        filterVariant: "multi-select",
        filterSelectOptions: categories.map((category) => {
          return {
            text: category.name,
            value: category._id,
          };
        }),
      },
      {
        header: "Piegādātāji",
        accessorKey: "suppliers",
        accessorFn: (originalRow) => {
          if (!originalRow.suppliers) {
            return "";
          }
          return originalRow.suppliers
            .map((s) => `${s.supplier.name} (€${s.price})`)
            .join(", ");
        },
        filterVariant: "multi-select",
        filterSelectOptions: suppliers.map((supplier) => {
          return {
            text: supplier.name,
            value: supplier._id,
          };
        }),
      },
    ],
    [suppliers, categories]
  );

  useEffect(() => {
    axios
      .get<ICategoryResponse[]>("/classifiers/categories")
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });

    axios
      .get<ISupplierResponse[]>("/classifiers/suppliers")
      .then((res) => {
        setSuppliers(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Materiāli</Typography>
        </Grid>
        <PermissionGuard permission={PERMISSIONS.WRITE_MATERIALS}>
          {({ hasAccess }) => (
            <>
              {hasAccess ? (
                <Grid item>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      dispatch(
                        openModal({
                          type: MODALS.Material,
                          callback: () => loadData(),
                        })
                      );
                    }}
                  >
                    Pievienot
                  </Button>
                </Grid>
              ) : null}
            </>
          )}
        </PermissionGuard>
      </Grid>

      <MaterialReactTable
        columns={columns}
        enableColumnActions={false}
        data={response?.docs || []}
        getRowId={(row) => row._id}
        enableRowActions
        positionActionsColumn="last"
        localization={{
          actions: "Darbības",
        }}
        renderRowActionMenuItems={({ row }) => {
          return [
            <PermissionGuard
              key="addToCart"
              permission={[PERMISSIONS.WRITE_BASKET]}
            >
              {({ hasAccess }) => (
                <>
                  {hasAccess ? (
                    <MenuItem
                      onClick={() => {
                        dispatch(
                          openModal({
                            type: MODALS.AddToCart,
                            material: row.original,
                          })
                        );
                      }}
                    >
                      <ListItemIcon>
                        <AddShoppingCartIcon />
                      </ListItemIcon>
                      <ListItemText>Pievienot pasūtījumam</ListItemText>
                    </MenuItem>
                  ) : null}
                </>
              )}
            </PermissionGuard>,
            <PermissionGuard
              key="edit"
              permission={[PERMISSIONS.WRITE_MATERIALS]}
            >
              {({ hasAccess }) => (
                <>
                  {hasAccess ? (
                    <Link
                      component={RouterLink}
                      to={`/manage/materials/${row.original._id}`}
                      color="inherit"
                      underline="none"
                    >
                      <MenuItem>
                        <ListItemIcon>
                          <OpenInNewIcon />
                        </ListItemIcon>
                        <ListItemText>Rediģēt</ListItemText>
                      </MenuItem>
                    </Link>
                  ) : null}
                </>
              )}
            </PermissionGuard>,
          ];
        }}
        manualPagination
        rowCount={response?.totalDocs || 0}
        onPaginationChange={setPagination}
        manualSorting
        onSortingChange={setSorting}
        manualFiltering
        onColumnFiltersChange={setColumnFilters}
        enableGlobalFilter={false}
        state={{
          isLoading,
          pagination,
          columnFilters,
          sorting,
        }}
      />
    </Stack>
  );
};

export default Materials;
