import { useEffect, useState, useCallback, useMemo } from "react";

import MODALS from "../constants/modals";
import { IAbsenceResponse } from "../types/absence";

import { useAppDispatch } from "../hooks/redux";
import { openModal } from "../features/modals";
import moment from "moment";
import axios from "axios";

import {
  Stack,
  Grid,
  Typography,
  Button,
  Avatar,
  Chip,
  FormControlLabel,
  Switch,
  IconButton,
  Badge,
  Popover,
  Autocomplete,
  TextField,
} from "@mui/material";

import { DataGrid, GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import PermissionGuard from "../components/PermissionGuard";
import PERMISSIONS from "../constants/permissions";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import ClearIcon from "@mui/icons-material/Clear";
import { IPaginationResponse } from "../types/common";
import { useAuth0 } from "@auth0/auth0-react";
import { AbsenceStatuses, ABSENCES_STATUSES } from "../constants/absenceStatuses";

import { usePermissions } from "../hooks/permissions";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { IUserBaseResponse } from "../types/user";
import { ClinicResponse } from "../types/clinic";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import _ from "lodash";
import { IAbsenceTypeResponse } from "../types/classifiers";

const Absences: React.FC = () => {
  const { user } = useAuth0();
  const dispatch = useAppDispatch();
  const { hasPermission } = usePermissions();

  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<IPaginationResponse<IAbsenceResponse>>();
  const [date, setDate] = useState<Date>(moment().toDate());
  const [filters, setFilters] = useState({
    page: 0,
    pageSize: 25,
  });

  const [filterValues, setFilterValues] = useState<IFilterValues>({});
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFiltersAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFiltersAnchorEl(null);
  };
  const handleFilterChange = useCallback((values: IFilterValues) => {
    setFilterValues(values);
  }, []);

  const loadData = useCallback(() => {
    setIsLoading(true);

    axios
      .get<IPaginationResponse<IAbsenceResponse>>("/absences", {
        params: {
          ...filters,
          date: date,
          filters: filterValues,
        },
      })
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [date, filters, filterValues]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const cancelAbsenceRequest = useCallback(
    (id: string) => {
      axios
        .post(`/absences/${id}/cancel`)
        .then((res) => {
          loadData();
        })
        .catch((err) => {
          console.log("error:", err);
        });
    },
    [loadData]
  );

  const approveAbsenceRequest = useCallback(
    (id: string) => {
      axios
        .post(`/absences/${id}/approve`)
        .then((res) => {
          loadData();
        })
        .catch((err) => {
          console.log("error:", err);
        });
    },
    [loadData]
  );

  const rejectAbsenceRequest = useCallback(
    (id: string) => {
      axios
        .post(`/absences/${id}/reject`)
        .then((res) => {
          loadData();
        })
        .catch((err) => {
          console.log("error:", err);
        });
    },
    [loadData]
  );

  const columns = useMemo<GridColumns<IAbsenceResponse>>(
    () => [
      {
        field: "image",
        headerName: "",
        sortable: false,
        width: 40,
        valueGetter: (params) => params.row.user?.image,
        renderCell: (params) => {
          if (!params.value) {
            return null;
          }

          return <Avatar alt={params.row.user?.name} src={params.value} />;
        },
      },
      {
        field: "name",
        headerName: "Vārds, Uzvārds",
        valueGetter: (params) => params.row.user?.name,
        sortable: false,
        flex: 1,
      },
      {
        field: "status",
        headerName: "Statuss",
        valueGetter: (params) => params.row.status,
        renderCell: (params) => {
          const absenceStatus = ABSENCES_STATUSES[params.row.status];

          return (
            <Chip
              label={absenceStatus?.label}
              color={absenceStatus?.color}
              variant="outlined"
              size="small"
            />
          );
        },
        sortable: false,
      },
      {
        field: "type",
        headerName: "Veids",
        valueGetter: (params) => (params.row.type ? params.row.type.name : ""),
        renderCell: (params) => {
          return <Chip label={params.value} variant="outlined" size="small" />;
        },
        sortable: false,
      },
      {
        field: "from",
        headerName: "No",
        type: "date",
        valueGetter: (params) => moment(params.row.from as Date).format("L"),
        sortable: false,
      },
      {
        field: "to",
        headerName: "Līdz",
        type: "date",
        valueGetter: (params) => moment(params.row.to as Date).format("L"),
        sortable: false,
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => {
          let actions = [];

          if (
            params.row.status === AbsenceStatuses.Requested &&
            hasPermission(PERMISSIONS.APPROVE_ABSENCES)
          ) {
            actions.push(
              <GridActionsCellItem
                icon={<CheckCircleOutlineIcon />}
                label="Apstiprināt"
                onClick={() => {
                  dispatch(
                    openModal({
                      type: MODALS.Approve,
                      title: "Apstiprināt prombūtni",
                      message: "Vai tiešām vēlaties apstiprināt prombūtnes pieteikumu?",
                      onApprove: () => approveAbsenceRequest(params.row._id),
                    })
                  );
                }}
                showInMenu
              />
            );

            actions.push(
              <GridActionsCellItem
                icon={<DoNotDisturbAltIcon />}
                label="Noraidīt"
                onClick={() => {
                  dispatch(
                    openModal({
                      type: MODALS.Approve,
                      title: "Noraidīt prombūtni",
                      message: "Vai tiešām vēlaties noraidīt prombūtnes pieteikumu?",
                      onApprove: () => rejectAbsenceRequest(params.row._id),
                    })
                  );
                }}
                showInMenu
              />
            );
          }

          if (
            user?.sub === params.row.user?.user_id &&
            params.row.status === AbsenceStatuses.Requested &&
            hasPermission(PERMISSIONS.REQUEST_ABSENCES)
          ) {
            actions.push(
              <GridActionsCellItem
                icon={<ClearIcon />}
                label="Atcelt"
                onClick={() => {
                  dispatch(
                    openModal({
                      type: MODALS.Approve,
                      title: "Atcelt prombūtni",
                      message: "Vai tiešām vēlaties atcelt prombūtnes pieteikumu?",
                      onApprove: () => cancelAbsenceRequest(params.row._id),
                    })
                  );
                }}
                showInMenu
              />
            );
          }

          return actions;
        },
      },
    ],
    [
      hasPermission,
      approveAbsenceRequest,
      cancelAbsenceRequest,
      rejectAbsenceRequest,
      dispatch,
      user?.sub,
    ]
  );

  return (
    <Stack spacing={2}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs>
          <Typography variant="h4">Plānotās prombūtnes</Typography>
        </Grid>

        <PermissionGuard permission={PERMISSIONS.REQUEST_ABSENCES}>
          {({ hasAccess }) => (
            <>
              {hasAccess ? (
                <Grid item>
                  <Button
                    startIcon={<QuestionMarkIcon />}
                    onClick={() => {
                      dispatch(
                        openModal({
                          type: MODALS.RequestAbsence,
                          callback: () => loadData(),
                        })
                      );
                    }}
                  >
                    Pieprasīt
                  </Button>
                </Grid>
              ) : null}
            </>
          )}
        </PermissionGuard>
        <PermissionGuard permission={PERMISSIONS.WRITE_ABSENCES}>
          {({ hasAccess }) => (
            <>
              {hasAccess ? (
                <Grid item>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      dispatch(
                        openModal({
                          type: MODALS.Absence,
                          callback: () => loadData(),
                        })
                      );
                    }}
                  >
                    Pievienot
                  </Button>
                </Grid>
              ) : null}
            </>
          )}
        </PermissionGuard>
      </Grid>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton
            onClick={() =>
              setDate((currentDate) => moment(currentDate).subtract(1, "month").toDate())
            }
          >
            <ChevronLeftIcon />
          </IconButton>
          <Typography>
            {moment(date).startOf("month").format("LL")} -{" "}
            {moment(date).endOf("month").format("LL")}
          </Typography>
          <IconButton
            onClick={() => setDate((currentDate) => moment(currentDate).add(1, "month").toDate())}
          >
            <ChevronRightIcon />
          </IconButton>
        </Stack>

        <PermissionGuard permission={PERMISSIONS.WRITE_ABSENCES}>
          {({ hasAccess }) => (
            <>
              {hasAccess ? (
                <>
                  <IconButton onClick={handleFilterClick}>
                    <Badge
                      color="secondary"
                      badgeContent={Object.values(filterValues).filter((item) => item).length}
                    >
                      <FilterAltIcon />
                    </Badge>
                  </IconButton>
                  <Popover
                    open={Boolean(filtersAnchorEl)}
                    anchorEl={filtersAnchorEl}
                    onClose={handleFilterClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                  >
                    <Filters values={filterValues} onFilterChange={handleFilterChange} />
                  </Popover>
                </>
              ) : null}
            </>
          )}
        </PermissionGuard>
      </Stack>
      <DataGrid
        sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
        loading={isLoading}
        getRowId={(row) => row._id}
        onRowClick={(params) => {
          if (hasPermission(PERMISSIONS.WRITE_ABSENCES)) {
            dispatch(
              openModal({
                type: MODALS.Absence,
                initialValues: params.row,
                callback: () => loadData(),
              })
            );
          } else {
            dispatch(
              openModal({
                type: MODALS.RequestAbsence,
                initialValues: params.row,
                callback: () => loadData(),
              })
            );
          }
        }}
        onPageSizeChange={(pageSize) => {
          setFilters({ ...filters, page: 0, pageSize: pageSize });
        }}
        onPageChange={(page) => {
          setFilters({ ...filters, page: page });
        }}
        page={filters.page}
        paginationMode="server"
        pageSize={filters.pageSize}
        rowCount={response ? response.totalDocs : 0}
        rows={response ? response.docs : []}
        columns={columns}
        autoHeight
        disableSelectionOnClick
        disableColumnMenu
      />
    </Stack>
  );
};

interface IFilterValues {
  user?: IUserBaseResponse;
  clinic?: ClinicResponse;
  status?: AbsenceStatuses;
  absenceType?: IAbsenceTypeResponse;
}

interface IFIlterProps {
  values: IFilterValues;
  onFilterChange: (values: IFilterValues) => void;
}

const Filters: React.FunctionComponent<IFIlterProps> = ({ values, onFilterChange }) => {
  const [users, setUsers] = useState<IUserBaseResponse[]>([]);
  const [clinics, setClinics] = useState<ClinicResponse[]>([]);
  const [absenceTypes, setAbsenceTypes] = useState<IAbsenceTypeResponse[]>([]);

  useEffect(() => {
    axios
      .get<IUserBaseResponse[]>(`/users/list`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
      });

    axios
      .get<ClinicResponse[]>("/clinics")
      .then((res) => {
        setClinics(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
    axios
      .get<IAbsenceTypeResponse[]>(`/classifiers/absenceTypes`)
      .then((response) => {
        setAbsenceTypes(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
      });
  }, []);

  useEffect(() => {
    onFilterChange(values);
  }, [values]);

  return (
    <Stack spacing={2} sx={{ padding: 2, width: 400, maxWidth: "100%" }}>
      <Typography variant="h6">Filtri</Typography>
      <Autocomplete
        options={_.map(ABSENCES_STATUSES, (value, key) => ({ key, label: value.label }))}
        value={
          values.status
            ? { key: values.status, label: ABSENCES_STATUSES[values.status].label }
            : null
        }
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label="Statuss" />}
        onChange={(e, value) => {
          onFilterChange({
            ...values,
            status: value ? (value.key as AbsenceStatuses) : undefined,
          });
        }}
      />
      <Autocomplete
        options={absenceTypes}
        value={values.absenceType}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Prombūtnes veids" />}
        onChange={(e, value) => {
          onFilterChange({
            ...values,
            absenceType: value ? value : undefined,
          });
        }}
      />
      <Autocomplete
        options={users}
        value={values.user}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Darbinieks" />}
        renderOption={(props, option) => {
          return (
            <Stack
              {...props}
              component="li"
              direction="row"
              spacing={1}
              alignItems="center"
              key={option._id}
            >
              <Avatar alt={option.name} src={option.image} sx={{ width: 24, height: 24 }} />
              <Typography>{option.name}</Typography>
            </Stack>
          );
        }}
        onChange={(e, value) => {
          onFilterChange({
            ...values,
            user: value ? value : undefined,
          });
        }}
      />
      <Autocomplete
        options={clinics}
        value={values.clinic}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Klīnika" />}
        onChange={(e, value) => {
          onFilterChange({
            ...values,
            clinic: value ? value : undefined,
          });
        }}
      />
    </Stack>
  );
};

export default Absences;
