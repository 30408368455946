import { useCallback, useEffect, useState } from "react";
import { ITimesheetEventModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById, openModal } from "../features/modals";

import { useFormik } from "formik";
import axios from "axios";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  TextField,
  Stack,
  Typography,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import timesheetEventSchema, { TimesheetEventBase } from "../schemas/timesheetEvent";
import { ClinicResponse } from "../types/clinic";
import MODALS from "../constants/modals";

const TimesheetEvent: React.FunctionComponent<ITimesheetEventModalProps> = ({
  id,
  callback,
  date,
  initialValues,
}) => {
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState(false);
  const [duration, setDuration] = useState<moment.Duration>();

  const [clinics, setClinics] = useState<ClinicResponse[]>([]);

  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    submitCount,
    dirty,
  } = useFormik<TimesheetEventBase>({
    initialValues: {
      clinic: initialValues?.clinic._id || "",
      startedAt: initialValues?.startedAt || null,
      finishedAt: initialValues?.finishedAt || null,
    },
    onSubmit: (values, { setSubmitting }) => {
      console.log("values:", values);
      setSubmitting(true);
      axios
        .put("/users/timesheetEvent", {
          _id: initialValues?._id,
          ...values,
        })
        .then(() => {
          callback();
          handleClose();
        })
        .catch((err) => {
          console.log("err:", err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    validationSchema: timesheetEventSchema,
  });

  const loadClinics = useCallback(() => {
    axios
      .get<ClinicResponse[]>("/clinics")
      .then((res) => {
        setClinics(res.data);
        if (!initialValues?._id && res.data.length > 0) {
          setFieldValue("clinic", res.data[0]._id);
        }
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  useEffect(() => {
    loadClinics();
  }, [loadClinics]);

  useEffect(() => {
    if (values.startedAt && values.finishedAt) {
      setDuration(moment.duration(moment(values.finishedAt).diff(moment(values.startedAt))));
    }
  }, [values.startedAt, values.finishedAt]);

  const handleDelete = (id: string) => {
    setIsDeleting(true);
    axios
      .delete(`/users/events/${id}`)
      .then(() => {
        callback();
        handleClose();
      })
      .catch((err) => {
        console.log("err:", err);
        setIsDeleting(false);
      });
  };

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle>Ziņot stundas par: {moment(date).format("LL")}</DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Autocomplete
              options={clinics}
              getOptionLabel={(option) => option.name}
              value={clinics.find((clinic) => values.clinic === clinic._id) || null}
              onChange={(event: any, newValue: ClinicResponse | null) => {
                setFieldValue("clinic", newValue ? newValue._id : null);
              }}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Klīnika"
                  error={Boolean(errors.clinic) && submitCount > 0}
                />
              )}
            />
            <Typography variant="h6">
              Darba stundas {duration && `(${duration.hours()}h ${duration.minutes()} min)`}
            </Typography>
            <Stack spacing={2} direction="row">
              <TimePicker
                label="Sākuma laiks"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "standard",
                  },
                }}
                value={moment(values.startedAt)}
                onChange={(newValue) => {
                  const startedAt = moment(date).set({
                    hour: moment(newValue).hour(),
                    minute: moment(newValue).minute(),
                  });

                  if (moment(startedAt).isAfter(moment(values.finishedAt))) {
                    setFieldValue("finishedAt", startedAt);
                  }

                  setFieldValue("startedAt", newValue);
                }}
                minutesStep={15}
              />
              <TimePicker
                label="Beigu laiks"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "standard",
                  },
                }}
                value={moment(values.finishedAt)}
                onChange={(newValue) => {
                  const finishedAt = moment(date).set({
                    hour: moment(newValue).hour(),
                    minute: moment(newValue).minute(),
                  });

                  if (moment(finishedAt).isBefore(moment(values.startedAt))) {
                    setFieldValue("startedAt", finishedAt);
                  }

                  setFieldValue("finishedAt", finishedAt);
                }}
                minutesStep={15}
              />
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        {initialValues?._id ? (
          <LoadingButton
            loading={isDeleting}
            onClick={() => {
              setIsDeleting(true);
              dispatch(
                openModal({
                  type: MODALS.Approve,
                  title: `Vai tiešām dzēst noziņotās stundas par "${moment(date).format("LL")}"?`,
                  message: "Noziņotās stundas tiks neatgriezeniski dzēstas.",
                  onApprove: () => {
                    handleDelete(initialValues._id);
                  },
                  onReject: () => {
                    setIsDeleting(false);
                  },
                })
              );
            }}
            color="error"
            variant="outlined"
          >
            Dzēst
          </LoadingButton>
        ) : null}

        <Stack direction="row" spacing={2} justifyContent="flex-end" flex={1}>
          <Button onClick={handleClose}>Aizvērt</Button>
          <LoadingButton
            loading={isSubmitting}
            disabled={isDeleting || moment().isBefore(moment(date).startOf("day")) || !dirty}
            onClick={() => {
              handleSubmit();
            }}
            variant="contained"
          >
            {initialValues ? "Saglabāt" : "Pievienot"}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default TimesheetEvent;
