import { IOrderNumberModalProps } from "../types/modal";
import { orderNumberSchema, OrderNumberBase } from "../schemas/order";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import axios from "axios";
import { useFormik } from "formik";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  TextField,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const OrderNumberModal: React.FunctionComponent<IOrderNumberModalProps> = ({
  id,
  order,
  callback,
}) => {
  const dispatch = useAppDispatch();

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    submitCount,
    isSubmitting,
  } = useFormik<OrderNumberBase>({
    initialValues: {
      orderNumber: order.orderNumber ? order.orderNumber : "",
    },
    onSubmit: (values) => {
      axios
        .put(`/order/${order._id}/order-number`, values)
        .then(() => {
          callback();
          handleClose();
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    validationSchema: orderNumberSchema,
  });

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={true} onClose={handleClose}>
      <DialogTitle>Pasūtījuma numurs</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            id="orderNumber"
            label="Numurs"
            variant="standard"
            value={values.orderNumber}
            onChange={handleChange}
            error={Boolean(errors.orderNumber) && submitCount > 0}
            helperText={submitCount > 0 && errors.orderNumber}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Aizvērt</Button>

        <LoadingButton
          loading={isSubmitting}
          onClick={() => {
            handleSubmit();
          }}
          variant="contained"
        >
          Saglabāt
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default OrderNumberModal;
