import { useEffect, useState } from "react";
import { ICategoryModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById, openModal } from "../features/modals";

import { useFormik } from "formik";
import axios from "axios";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  TextField,
  Stack,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import categorySchema from "../schemas/category";
import { ICategoryResponse } from "../types/classifiers";

import PermissionGuard from "../components/PermissionGuard";
import PERMISSIONS from "../constants/permissions";
import MODALS from "../constants/modals";

const CategoryModal: React.FunctionComponent<ICategoryModalProps> = ({
  id,
  callback,
  initialValues,
}) => {
  const dispatch = useAppDispatch();

  const [categories, setCategories] = useState<ICategoryResponse[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    values,
    dirty,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    resetForm,
    submitCount,
  } = useFormik<ICategoryResponse>({
    initialValues: {
      _id: initialValues ? initialValues._id : "",
      name: initialValues ? initialValues.name : "",
      parent: initialValues?.parent,
      children: initialValues ? initialValues.children : []
    },
    onSubmit: (values) => {
      axios
        .put(
          "/classifiers/categories",
          initialValues ? { ...values } : values
        )
        .then(() => {
          callback();
          handleClose();
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    validationSchema: categorySchema,
  });

  const handleDelete = (id: string) => {
    setIsDeleting(true);
    axios
      .delete(`/classifiers/categories/${id}`)
      .then(() => {
        callback();
        handleClose();
      })
      .catch((err) => {
        console.log("err:", err);
        setIsDeleting(false);
      });
  };

  const handleClose = () => {
    dispatch(closeModalById(id));
  };


  useEffect(() => {
    axios
      .get<ICategoryResponse[]>("/classifiers/categories/list", { params: {full: true} })
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={true} onClose={handleClose}>
      <DialogTitle>{"_id" in values ? "Labot" : "Pievienot"} materiālu kategoriju</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            id="name"
            label="Nosaukums"
            variant="standard"
            value={values.name}
            onChange={handleChange}
            error={Boolean(errors.name) && submitCount > 0}
          />
          {categories.length > 0 ? (
          <Autocomplete
            options={categories}
            getOptionLabel={(option) => option.name}
            value={values.parent ? categories.find((category) => category._id == values.parent) : null}
            onChange={(event: any, newValue) => {
              setFieldValue("parent", newValue ? newValue._id : null);
            }}
            isOptionEqualToValue={(option, value) => {
              return value && option._id === value._id;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Virskategorija"
                error={Boolean(errors.parent) && submitCount > 0}
              />
            )}
            filterOptions={(options) => {
              let newOptions: ICategoryResponse[] = [];
              const value = categories.find((category) => category._id === values._id);

              options.forEach((element) => {
                if (!value) {
                  newOptions.push(element);
                } else if (!value!.children.some(child => child.name == element.name) 
                  && element.name !== value!.name ) {
                    newOptions.push(element);
                  }
                }) 
              return newOptions;
            }}

          />
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Aizvērt</Button>
        <PermissionGuard permission={PERMISSIONS.DELETE_CATEGORIES}>
          {({ hasAccess }) => (
            <>
              {hasAccess && initialValues ? (
                <LoadingButton
                  loading={isDeleting}
                  onClick={() => {
                    dispatch(
                      openModal({
                        type: MODALS.Approve,
                        title: "Vai tiešām dzēst materiālu kategoriju?",
                        message: "Materiālu kategorija tiks dzēsta neatgriezeniski.",
                        onApprove: () => {
                          handleDelete(initialValues._id);
                        },
                      })
                    );
                  }}
                  color="error"
                  variant="outlined"
                >
                  Dzēst
                </LoadingButton>
              ) : null}
            </>
          )}
        </PermissionGuard>
        <Button
          disabled={isDeleting}
          onClick={() => {
            handleSubmit();
          }}
          variant="contained"
        >
          {initialValues ? "Saglabāt" : "Pievienot"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryModal