import { useCallback, useEffect, useState } from "react";
import { IRequestAbsenceModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import { useFormik } from "formik";
import axios from "axios";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  TextField,
  Stack,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { requestAbsenceSchema, RequestAbsenceBase } from "../schemas/absence";
import { IAbsenceTypeResponse } from "../types/classifiers";
import { AbsenceStatuses } from "../constants/absenceStatuses";

const RequestAbsenceModal: React.FunctionComponent<IRequestAbsenceModalProps> = ({
  id,
  initialValues,
  callback,
}) => {
  const dispatch = useAppDispatch();

  const [absenceTypes, setAbsenceTypes] = useState<IAbsenceTypeResponse[]>([]);

  const { values, setFieldValue, handleSubmit, isSubmitting, errors, submitCount } =
    useFormik<RequestAbsenceBase>({
      initialValues: {
        type: initialValues && initialValues.type ? initialValues.type._id : "",
        from: initialValues ? initialValues.from : moment().toDate(),
        to: initialValues ? initialValues.to : moment().add(1, "day").toDate(),
      },
      onSubmit: (values, { setSubmitting }) => {
        setSubmitting(true);
        axios
          .put("/absences/request", {
            _id: initialValues ? initialValues._id : undefined,
            ...values,
          })
          .then(() => {
            callback();
            handleClose();
          })
          .catch((err) => {
            console.log("err:", err);
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
      validationSchema: requestAbsenceSchema,
    });

  const handleClose = useCallback(() => {
    dispatch(closeModalById(id));
  }, [id, dispatch]);

  useEffect(() => {
    axios
      .get<IAbsenceTypeResponse[]>(`/classifiers/absenceTypes`)
      .then((response) => {
        setAbsenceTypes(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
        callback();
        handleClose();
      });
  }, [callback, handleClose]);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle>Prombūtne</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="No"
                value={moment(values.from)}
                minDate={moment()}
                onChange={(newValue) => {
                  if (moment(newValue).isAfter(moment(values.to))) {
                    setFieldValue("to", newValue ? newValue : null);
                  }
                  setFieldValue("from", newValue);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "standard",
                    error: Boolean(errors.from) && submitCount > 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Līdz"
                value={moment(values.to)}
                minDate={moment(values.from)}
                onChange={(newValue) => {
                  setFieldValue("to", newValue);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "standard",
                    error: Boolean(errors.to) && submitCount > 0,
                  },
                }}
              />
            </Grid>
          </Grid>

          <FormControl variant="standard" error={Boolean(errors.type) && submitCount > 0}>
            <InputLabel id="type-select">Prombūtnes veids</InputLabel>
            <Select
              labelId="type-select"
              id="type"
              label="Prombūtnes veids"
              value={values.type ? values.type : ""}
              onChange={(event) => {
                setFieldValue("type", event.target.value ? event.target.value : null);
              }}
            >
              <MenuItem value="">Neviens</MenuItem>
              {absenceTypes.map((absenceType) => (
                <MenuItem key={absenceType._id} value={absenceType._id}>
                  {absenceType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Aizvērt</Button>
        <LoadingButton
          loading={isSubmitting}
          disabled={initialValues && initialValues.status !== AbsenceStatuses.Requested}
          onClick={() => {
            handleSubmit();
          }}
          variant="contained"
        >
          {initialValues?._id ? "Saglabāt" : "Pieprasīt"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RequestAbsenceModal;
