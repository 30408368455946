import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTooth,
  faSyringe,
  faBusSimple,
  faTeeth,
  faFaceGrimace,
} from "@fortawesome/free-solid-svg-icons";

export enum ChairTypes {
  Dentist = "dentist",
  Surgeon = "surgeon",
  Bus = "bus",
  Hygienist = "hygienist",
  Orthodontist = "orthodontist",
}

export interface ChairType {
  label: string;
  icon: JSX.Element;
}

type IChairTypesList = {
  [key in ChairTypes]: ChairType;
};

export const CHAIR_TYPES: IChairTypesList = {
  [ChairTypes.Dentist]: {
    label: "Zobārsts",
    icon: <FontAwesomeIcon icon={faTooth} />,
  },
  [ChairTypes.Surgeon]: {
    label: "Ķirurgs",
    icon: <FontAwesomeIcon icon={faSyringe} />,
  },
  [ChairTypes.Bus]: {
    label: "Mobilais autobuss",
    icon: <FontAwesomeIcon icon={faBusSimple} />,
  },
  [ChairTypes.Hygienist]: {
    label: "Higiēnists",
    icon: <FontAwesomeIcon icon={faTeeth} />,
  },
  [ChairTypes.Orthodontist]: {
    label: "Ortodonts",
    icon: <FontAwesomeIcon icon={faFaceGrimace} />,
  },
};
