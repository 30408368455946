import { useState } from "react";
import { IReserveApartmentModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById, openModal } from "../features/modals";

import { useFormik } from "formik";
import axios from "axios";

import { Dialog, DialogActions, DialogTitle, Button, DialogContent, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { ReserveApartmentBase } from "../schemas/apartment";
import apartmentReservationSchema from "../schemas/apartmentReservation";
import MODALS from "../constants/modals";

import { toast } from "react-toastify";
import { usePermissions } from "../hooks/permissions";
import PERMISSIONS from "../constants/permissions";
import { useAuth0 } from "@auth0/auth0-react";
import EmployeeAutocomplete from "../components/EmployeeAutocomplete";
import ROLES from "../constants/roles";

const ReserveApartmentModal: React.FunctionComponent<IReserveApartmentModalProps> = ({
  id,
  callback,
  apartment,
  clinic,
  initialValues,
}) => {
  const { user } = useAuth0();
  const { hasPermission } = usePermissions();
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState(false);

  const { values, setFieldValue, handleSubmit, isSubmitting, errors, submitCount } =
    useFormik<ReserveApartmentBase>({
      initialValues: {
        user: initialValues && "_id" in initialValues ? initialValues.user._id : undefined,
        from: initialValues ? moment.utc(initialValues.from).toDate() : moment().toDate(),
        to: initialValues ? moment.utc(initialValues.to).toDate() : moment().toDate(),
      },
      onSubmit: (values, { setSubmitting }) => {
        setSubmitting(true);
        axios
          .put(`/apartments/${apartment._id}/reserve`, {
            _id: initialValues && "_id" in initialValues ? initialValues._id : undefined,
            ...values,
          })
          .then(() => {
            callback();
            handleClose();
          })
          .catch((err) => {
            if (err.response && err.response.data) {
              toast.error(err.response.data.message);
              callback();
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
      validationSchema: apartmentReservationSchema,
    });

  const handleDelete = (id: string) => {
    axios
      .delete(`/apartments/${apartment._id}/reservations/${id}`)
      .then(() => {
        callback();
        handleClose();
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          toast.error(err.response.data.message);
          callback();
        }
        setIsDeleting(false);
      });
  };

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle>Dienesta dzīvokļa rezervācija</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {hasPermission(PERMISSIONS.WRITE_ALL_RESERVE_APARTMENTS) && (
            <EmployeeAutocomplete
              label="Darbinieks (neobligāts)"
              roles={[ROLES.Apartment]}
              clinic={clinic}
              value={values.user}
              onChange={(newValue) => {
                setFieldValue("user", newValue);
              }}
              error={Boolean(errors.user) && submitCount > 0}
              helperText={errors.user}
            />
          )}
          <Stack spacing={2} direction="row">
            <DatePicker
              label="No"
              value={moment(values.from)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "standard",
                  error: Boolean(errors.from) && submitCount > 0,
                },
              }}
              onChange={(newValue) => {
                if (moment(newValue).isAfter(moment(values.to))) {
                  setFieldValue("to", newValue ? newValue : null);
                }
                setFieldValue("from", newValue);
              }}
            />

            <DatePicker
              label="Līdz"
              value={moment(values.to)}
              minDate={moment(values.from)}
              onChange={(newValue) => {
                setFieldValue("to", newValue);
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "standard",
                  error: Boolean(errors.to) && submitCount > 0,
                },
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        {initialValues &&
        "_id" in initialValues &&
        ((hasPermission(PERMISSIONS.DELETE_RESERVE_APARTMENTS) &&
          initialValues.user.user_id === user?.sub) ||
          hasPermission(PERMISSIONS.DELETE_ALL_RESERVE_APARTMENTS)) ? (
          <LoadingButton
            loading={isDeleting}
            onClick={() => {
              setIsDeleting(true);
              dispatch(
                openModal({
                  type: MODALS.Approve,
                  title: "Vai tiešām dzēst dzīvokļa rezervāciju?",
                  message: "Rezervācija tiks dzēsts neatgriezeniski.",
                  onApprove: () => {
                    handleDelete(initialValues._id);
                  },
                  onReject: () => {
                    setIsDeleting(false);
                  },
                })
              );
            }}
            color="error"
            variant="outlined"
          >
            Dzēst
          </LoadingButton>
        ) : null}
        <Stack direction="row" spacing={2} justifyContent="flex-end" flex={1}>
          <Button onClick={handleClose}>Aizvērt</Button>
          {(initialValues &&
            ((!("_id" in initialValues) && hasPermission(PERMISSIONS.WRITE_RESERVE_APARTMENTS)) ||
              ("_id" in initialValues &&
                hasPermission(PERMISSIONS.WRITE_RESERVE_APARTMENTS) &&
                initialValues.user.user_id === user?.sub))) ||
          hasPermission(PERMISSIONS.WRITE_ALL_RESERVE_APARTMENTS) ? (
            <LoadingButton
              loading={isSubmitting}
              disabled={isDeleting}
              onClick={() => {
                handleSubmit();
              }}
              variant="contained"
            >
              {initialValues && "_id" in initialValues ? "Saglabāt" : "Rezervēt"}
            </LoadingButton>
          ) : null}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ReserveApartmentModal;
