import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0095DA",
    },
  },
  components: {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: "8px !important",
        },
      },
    },
  },
});

export default theme;
