import * as yup from "yup";

export const orderNumberSchema = yup.object({
  orderNumber: yup.string().required(),
});

export type OrderNumberBase = yup.InferType<typeof orderNumberSchema>;

export const receivedItemCountSchema = yup.object({
  receivedCount: yup.number().required(),
});

export type ReceivedItemCountBase = yup.InferType<
  typeof receivedItemCountSchema
>;
