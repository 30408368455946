import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import jwt_decode from "jwt-decode";
import { isArray, isString, some } from "lodash";

interface IPermissionGuardProps {
  permission?: string | string[];
  children: (options: { hasAccess: boolean }) => React.ReactElement;
}

const PermissionGuard: React.FunctionComponent<IPermissionGuardProps> = ({
  permission,
  children,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [permissions, setPermissions] = useState<string[]>([]);

  const getPermissions = async () => {
    const token = await getAccessTokenSilently();
    const decoded = jwt_decode(token);

    // @ts-ignore
    if (decoded.permissions) {
      // @ts-ignore
      setPermissions(decoded.permissions);
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  return children({
    hasAccess:
      !permission ||
      (isArray(permission) && some(permission, (perm) => permissions.includes(perm))) ||
      (isString(permission) && permissions.includes(permission)),
  });
};

export default PermissionGuard;
