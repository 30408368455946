import * as yup from "yup";

const clinicSchema = yup.object({
  name: yup.string().required(),
  address: yup.string().nullable(),
  phone: yup.string().nullable(),
  order: yup.number(),
});

export type ClinicBase = yup.InferType<typeof clinicSchema>;

export default clinicSchema;
