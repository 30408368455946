import React from "react";

// constants & types
import MODALS from "../constants/modals";

// utils
import { useAppSelector } from "../hooks/redux";

// modals
import ApproveModal from "../modals/Approve";
import AbsenceModal from "../modals/Absence";
import RequestAbsenceModal from "../modals/RequestAbsence";
import ProstheticReportModal from "../modals/ProstheticReport";
import ProstheticReportRecordModal from "../modals/ProstheticReportRecord";
import HolidayModal from "../modals/Holiday";
import ClinicModal from "../modals/Clinic";
import ChairModal from "../modals/Chair";
import UserModal from "../modals/User";
import AbsenceTypeModal from "../modals/AbsenceType";
import LaboratoryModal from "../modals/Laboratory";
import SupplierModal from "../modals/Supplier";
import TimesheetModal from "../modals/Timesheet";
import CategoryModal from "../modals/Category";
import RoentgenModal from "../modals/Roentgen";
import RoentgenReportModal from "../modals/RoentgenReport";
import RoentgenReportRecordModal from "../modals/RoentgenReportRecord";
import MaterialModal from "../modals/Material";
import TimesheetEventModal from "../modals/TimesheetEvent";
import ApartmentModal from "../modals/Apartment";
import ReserveApartmentModal from "../modals/ReserveApartment";
import WarehouseAddItemsModal from "../modals/WarehouseAddItems";
import AddToCart from "../modals/AddToCart";
import OrderNumberModal from "../modals/OrderNumber";
import ReceivedItemCountModal from "../modals/ReceivedItemCount";
import WarehouseTakeItemsModal from "../modals/WarehouseTakeItems";

const ModalManager: React.FunctionComponent<{}> = () => {
  const modals = useAppSelector((state) => state.modals);

  return modals.length === 0 ? null : (
    <>
      {modals.map((modal, index) => {
        switch (modal.type) {
          case MODALS.Approve:
            return <ApproveModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.Absence:
            return <AbsenceModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.ProstheticReport:
            return (
              <ProstheticReportModal key={`modal_${modal.id}`} {...modal} />
            );
          case MODALS.ProstheticReportRecord:
            return (
              <ProstheticReportRecordModal
                key={`modal_${modal.id}`}
                {...modal}
              />
            );
          case MODALS.Holiday:
            return <HolidayModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.Clinic:
            return <ClinicModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.Chair:
            return <ChairModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.User:
            return <UserModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.AbsenceType:
            return <AbsenceTypeModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.Category:
            return <CategoryModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.Supplier:
            return <SupplierModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.Laboratory:
            return <LaboratoryModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.RequestAbsence:
            return <RequestAbsenceModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.Timesheet:
            return <TimesheetModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.Roentgen:
            return <RoentgenModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.RoentgenReport:
            return <RoentgenReportModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.RoentgenReportRecord:
            return (
              <RoentgenReportRecordModal key={`modal_${modal.id}`} {...modal} />
            );
          case MODALS.Material:
            return <MaterialModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.TimesheetEvent:
            return <TimesheetEventModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.Apartment:
            return <ApartmentModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.ReserveApartment:
            return (
              <ReserveApartmentModal key={`modal_${modal.id}`} {...modal} />
            );
          case MODALS.WarehouseAddItems:
            return (
              <WarehouseAddItemsModal key={`modal_${modal.id}`} {...modal} />
            );
          case MODALS.AddToCart:
            return <AddToCart key={`modal_${modal.id}`} {...modal} />;
          case MODALS.OrderNumber:
            return <OrderNumberModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.ReceivedItemCount:
            return (
              <ReceivedItemCountModal key={`modal_${modal.id}`} {...modal} />
            );
          case MODALS.WarehouseTakeItems:
            return (
              <WarehouseTakeItemsModal key={`modal_${modal.id}`} {...modal} />
            );
          default:
            console.error("Undefined modal component");
            return null;
        }
      })}
    </>
  );
};

export default ModalManager;
