import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

// reducers
import authReducer from "./features/auth";
import modalsReducer from "./features/modals";
import lightboxReducer from "./features/lightbox";

import cartReducer from "./features/cart";

export const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    modals: modalsReducer,
    lightbox: lightboxReducer,
    cart: cartReducer,
  }),
  middleware: [thunk, createLogger()],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
