import { useCallback, useEffect, useState } from "react";

import MODALS from "../constants/modals";
import ROLES from "../constants/roles";
import { IRoentgenReportRecordModalProps } from "../types/modal";
import { IRoentgenResponse } from "../types/classifiers";
import { IRoentgenReportRecordResponse } from "../types/roentgenReport";
import roentgenReportRecordSchema, {
  RoentgenReportRecordBase,
} from "../schemas/roentgenReportRecord";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById, openModal } from "../features/modals";

import axios from "axios";
import moment from "moment";
import { useFormik } from "formik";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Stack,
  DialogContent,
  TextField,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";

import EmployeeAutocomplete from "../components/EmployeeAutocomplete";

const RoentgenReportRecordModal: React.FunctionComponent<IRoentgenReportRecordModalProps> = ({
  id,
  report,
  date,
  clinic,
  record,
  callback,
}) => {
  const dispatch = useAppDispatch();

  const [isRoentgensLoading, setIsRoentgensLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [roentgens, setRoentgnes] = useState<IRoentgenResponse[]>([]);

  const { values, setFieldValue, handleSubmit, isSubmitting, handleChange, errors, submitCount } =
    useFormik<RoentgenReportRecordBase>({
      initialValues: {
        date: record?.date || moment().toDate(),
        doctor: record?.doctor._id || "",
        roentgens: record?.roentgens || {},
      },
      onSubmit: (values, { setSubmitting }) => {
        setSubmitting(true);
        axios
          .put<IRoentgenReportRecordResponse>(`/roentgens/${report}`, {
            ...values,
            _id: record ? record._id : null,
          })
          .then((res) => {
            handleClose();
            callback();
          })
          .catch((err) => {
            console.log("error:", err);
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
      validationSchema: roentgenReportRecordSchema,
    });

  const handleDelete = useCallback(() => {
    if (!record) {
      return;
    }

    setIsDeleting(true);

    dispatch(
      openModal({
        type: MODALS.Approve,
        title: "Vai tiešām vēlaties dzēst?",
        message: "Ieraksts tiks neatgriezeniski dzēsts",
        onApprove: () => {
          axios.delete(`/roentgens/${report}/${record._id}`).then((res) => {
            handleClose();
            callback();
          });
        },
        onReject: () => {
          setIsDeleting(false);
        },
      })
    );
  }, [record]);

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    setIsRoentgensLoading(true);
    // TODO: this should be in redux store and fetched only once
    axios
      .get<IRoentgenResponse[]>("/classifiers/roentgens", {
        params: {
          clinic,
        },
      })
      .then((res) => {
        setRoentgnes(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setIsRoentgensLoading(false);
      });
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={true} onClose={handleClose}>
      <DialogTitle>Pievienot veiktos rentgenus</DialogTitle>
      <DialogContent style={{ paddingTop: 20 }}>
        <Stack spacing={2}>
          <DatePicker
            label="Datums"
            views={["day"]}
            value={moment(values.date)}
            minDate={moment(date).startOf("month")}
            maxDate={moment(date).endOf("month")}
            onChange={(newValue) => {
              setFieldValue("date", newValue);
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: "standard",
                error: Boolean(errors.date) && submitCount > 0,
              },
            }}
          />
          <EmployeeAutocomplete
            label="Ārsts"
            roles={[ROLES.Doctor]}
            clinic={clinic}
            value={values.doctor}
            onChange={(newValue) => {
              setFieldValue("doctor", newValue);
            }}
            error={Boolean(errors.doctor) && submitCount > 0}
            helperText={errors.doctor}
          />
          {isRoentgensLoading ? (
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          ) : (
            <>
              {roentgens.map((roentgen) => (
                <TextField
                  key={roentgen._id}
                  id={`roentgens.${roentgen._id}`}
                  label={roentgen.name}
                  variant="standard"
                  value={values.roentgens[roentgen._id] ? values.roentgens[roentgen._id] : ""}
                  onChange={handleChange}
                  type="number"
                />
              ))}
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        {record && (
          <LoadingButton
            loading={isDeleting}
            disabled={isRoentgensLoading}
            variant="outlined"
            color="error"
            onClick={() => handleDelete()}
          >
            Dzēst
          </LoadingButton>
        )}

        <Stack direction="row" spacing={2} justifyContent="flex-end" flex={1}>
          <Button onClick={handleClose}>Atcelt</Button>
          <LoadingButton
            loading={isSubmitting}
            disabled={isRoentgensLoading}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            {record ? "Saglabāt" : "Pievienot"}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default RoentgenReportRecordModal;
