export enum AbsenceStatuses {
  Requested = "requested",
  Approved = "approved",
  Canceled = "cancelled",
  Rejected = "rejected",
}

export interface AbsenceStatus {
  label: string;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
}

type IAbsenceStatusesList = {
  [key in AbsenceStatuses]: AbsenceStatus;
};

export const ABSENCES_STATUSES: IAbsenceStatusesList = {
  [AbsenceStatuses.Requested]: {
    label: "Pieprasīts",
    color: "primary",
  },
  [AbsenceStatuses.Approved]: {
    label: "Apstiprināts",
    color: "success",
  },
  [AbsenceStatuses.Canceled]: {
    label: "Atcelts",
    color: "default",
  },
  [AbsenceStatuses.Rejected]: {
    label: "Noraidīts",
    color: "error",
  },
};
