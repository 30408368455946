import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { IOrderFile } from "../types/order";

import {
  faFilePdf,
  faTrash,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface FileListProps {
  files: IOrderFile[];
  onDownload?: (file: IOrderFile) => void;
  onDelete?: (file: IOrderFile) => void;
}

const FileList: React.FC<FileListProps> = ({ files, onDownload, onDelete }) => {
  return (
    <Stack>
      <Typography variant="h6" component="div">
        Faili
      </Typography>
      <List>
        {files.map((file) => {
          return (
            <ListItem
              key={file._id}
              secondaryAction={
                <Stack direction="row" spacing={1}>
                  {onDownload && (
                    <IconButton onClick={() => onDownload(file)}>
                      <FontAwesomeIcon icon={faDownload} size="sm" />
                    </IconButton>
                  )}
                  {onDelete && (
                    <IconButton onClick={() => onDelete(file)}>
                      <FontAwesomeIcon icon={faTrash} size="sm" />
                    </IconButton>
                  )}
                </Stack>
              }
              disableGutters
            >
              <ListItemAvatar>
                <FontAwesomeIcon icon={faFilePdf} size="xl" />
              </ListItemAvatar>
              <ListItemText primary={file.name} />
            </ListItem>
          );
        })}
      </List>
    </Stack>
  );
};

export default FileList;
