import * as yup from "yup";

const addToCartSchema = yup.object({
  count: yup.number().required(),
  supplierId: yup.string().required(),
  clinicId: yup.string().required(),
});

export type AddToCartBase = yup.InferType<typeof addToCartSchema>;

export default addToCartSchema;
