import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router";

import { ReportStatuses, REPORT_STATUSES } from "../constants/reportStatuses";
import MODALS from "../constants/modals";
import { IProstheticReportResponse } from "../types/prostheticReport";

import { useAppDispatch } from "../hooks/redux";
import { openModal } from "../features/modals";
import moment from "moment";
import axios from "axios";

import { Stack, Grid, Typography, Button, Chip } from "@mui/material";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";

const columns: GridColDef[] = [
  {
    field: "year",
    headerName: "Gads",
    type: "date",
    valueGetter: (params) => moment(params.row.date as Date).format("YYYY"),
    sortable: false,
  },
  {
    field: "month",
    headerName: "Mēnesis",
    type: "date",
    valueGetter: (params) => moment(params.row.date as Date).format("MMMM"),
    sortable: false,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params) => {
      const status = params.value as ReportStatuses;

      return (
        <Chip
          label={REPORT_STATUSES[status].label}
          variant={status === ReportStatuses.Opened ? "filled" : "outlined"}
          size="small"
          color={status === ReportStatuses.Opened ? "success" : "default"}
        />
      );
    },
    sortable: false,
  },
  {
    field: "created",
    headerName: "Atvērta",
    type: "date",
    valueFormatter: (params) => moment(params.value as Date).format("L"),
    sortable: false,
  },
  {
    field: "closed",
    headerName: "Aizvērta",
    type: "date",
    valueFormatter: (params) => (params.value ? moment(params.value as Date).format("L") : ""),
    sortable: false,
  },
];

const Prosthetics: React.FunctionComponent<{}> = () => {
  const { clinic } = useParams<{ clinic: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState<IProstheticReportResponse[]>([]);

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IProstheticReportResponse[]>(`/prosthetics/${clinic}`)
      .then((res) => {
        setReports(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clinic]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Protezēšanas atskaites</Typography>
        </Grid>
        <Grid item>
          <Button
            startIcon={<AddIcon />}
            disabled={!Boolean(clinic)}
            onClick={() => {
              if (clinic) {
                dispatch(
                  openModal({
                    type: MODALS.ProstheticReport,
                    clinic,
                    callback: (reportId) => {
                      navigate(`/${clinic}/prosthetics/${reportId}`);
                    },
                  })
                );
              }
            }}
          >
            Izveidot jaunu
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
        loading={isLoading}
        getRowId={(row) => row._id}
        onRowClick={(params) => navigate(`/${clinic}/prosthetics/${params.row._id}`)}
        rows={reports}
        columns={columns}
        hideFooterPagination
        autoHeight
        disableSelectionOnClick
        disableColumnMenu
        hideFooter
      />
    </Stack>
  );
};

export default Prosthetics;
