import React, { useEffect, useState } from "react";

// utils
import { useAuth0 } from "@auth0/auth0-react";

import {
  Link as RouterLink,
  useParams,
  generatePath,
  useLocation,
  Outlet,
} from "react-router-dom";

import { styled } from "@mui/material/styles";

import {
  ListItemIcon,
  Link,
  Grid,
  Container,
  IconButton,
  Divider,
  Toolbar,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  Drawer as MuiDrawer,
  CssBaseline,
  MenuItem,
  Avatar,
  Menu,
  useMediaQuery,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Collapse,
  Badge,
  Popper,
  Fade,
  Popover,
  Button,
  Stack,
  Card,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import LogoutIcon from "@mui/icons-material/Logout";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import ModalManager from "../managers/Modal";
import { routes } from "../config/routes";
import { MenuRoute, RouteType, TRoute } from "../types/route";
import PermissionGuard from "../components/PermissionGuard";
import Copyright from "../components/Copyright";

import logo from "../assets/img/logo.png";
import axios from "axios";
import { ClinicResponse } from "../types/clinic";
import LightboxManager from "../managers/Lightbox";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { getCartItems } from "../features/cart";
import PERMISSIONS from "../constants/permissions";

import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import moment from "moment";
import { IUserBaseResponse, IUserResponse } from "../types/user";
import { IHolidayResponse } from "../types/holiday";

const drawerWidth: number = 260;

const Layout: React.FunctionComponent<{}> = () => {
  const params = useParams();

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useAppDispatch();

  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    if (mdDown) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [mdDown]);

  useEffect(() => {
    dispatch(getCartItems());
  }, []);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open && !mdDown}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            {params.clinic ? <ClinicHeader clinicId={params.clinic} /> : null}
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <EventsDropdown />
              </Grid>
              <Grid item>
                <CartDropdown />
              </Grid>
              <Grid item>
                <ProfileDropdown />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            maxHeight: "100vh",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant={mdDown ? "temporary" : "persistent"}
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
        >
          <DrawerHeader>
            <RouterLink to="/">
              <img src={logo} alt="logo" style={{ maxWidth: 150 }} />
            </RouterLink>

            <IconButton onClick={() => setOpen(false)}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <NavigationRoutes
            routes={recursiveRouteFilter(
              routes[0]
                ? routes[0].type !== RouteType.Divider
                  ? routes[0].routes
                  : undefined
                : undefined
            )}
            path={""}
            setOpenDrawer={(open) => {
              if (mdDown) {
                setOpen(open);
              }
            }}
          />
        </Drawer>
        <Main
          open={open || mdDown}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container
            // maxWidth={false}
            sx={{ mt: 4, mb: 4 }}
          >
            <Outlet />
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Main>
      </Box>
      <ModalManager />
      <LightboxManager />
    </>
  );
};

interface INavigationRoutesProps {
  routes: TRoute[] | undefined;
  path: string;
  nested?: boolean;
  setOpenDrawer: (b: boolean) => void;
}

const NavigationRoutes: React.FunctionComponent<INavigationRoutesProps> = ({
  routes,
  nested,
  path,
  setOpenDrawer,
}) => {
  {
    if (!routes || !routes.length) {
      return null;
    }

    return (
      <Box sx={{ overflowY: "auto" }}>
        <List>
          {routes.map((route, index) => {
            if (route.type === RouteType.Basic) {
              return null;
            }

            if (route.type === RouteType.Divider) {
              return <Divider key={index} sx={{ marginBottom: 2 }} />;
            }

            // let path = "";

            // try {
            //   if (route.path) {
            //     path = generatePath(route.path, params);
            //   }
            // } catch (error) {
            //   return null;
            // }

            return (
              <PermissionGuard key={index} permission={route.permission}>
                {({ hasAccess }) => (
                  <>
                    {hasAccess ? (
                      <NavigationItem
                        route={route}
                        nested={nested}
                        path={path}
                        setOpenDrawer={setOpenDrawer}
                      />
                    ) : null}
                  </>
                )}
              </PermissionGuard>
            );
          })}
        </List>
      </Box>
    );
  }
};

interface INavigationItemProps {
  route: MenuRoute;
  path: string;
  nested?: boolean;
  setOpenDrawer: (b: boolean) => void;
}

const NavigationItem: React.FunctionComponent<INavigationItemProps> = ({
  route,
  nested,
  path,
  setOpenDrawer,
}) => {
  const params = useParams();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  let itemPath = "";

  try {
    if (route.path) {
      itemPath = generatePath(
        `${path !== "" ? `${path}/` : ""}${route.path}`,
        params
      );
    }
  } catch (error) {
    return null;
  }

  if (!route.routes || !route.routes.length) {
    return (
      <Link
        component={RouterLink}
        to={itemPath}
        underline="none"
        color="inherit"
        onClick={() => {
          setOpenDrawer(false);
        }}
      >
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === itemPath}
            sx={nested ? { pl: 4 } : undefined}
          >
            <ListItemIcon>{route.icon}</ListItemIcon>
            <ListItemText primary={route.name} />
          </ListItemButton>
        </ListItem>
      </Link>
    );
  }

  return (
    <>
      <ListItem disablePadding onClick={() => setOpen((e) => !e)}>
        <ListItemButton selected={location.pathname === itemPath}>
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText primary={route.name} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <NavigationRoutes
          routes={route.routes}
          nested
          path={itemPath}
          setOpenDrawer={setOpenDrawer}
        />
      </Collapse>
    </>
  );
};

const recursiveRouteFilter = (
  routesToFilter: TRoute[] | undefined
): TRoute[] | undefined => {
  if (!routesToFilter) {
    return undefined;
  }

  const returnRoutes = routesToFilter
    .filter((route) => [RouteType.Menu, RouteType.Divider].includes(route.type))
    .map((route) => {
      if (route.type === RouteType.Divider) {
        return route;
      }

      return {
        ...route,
        routes: route.routes ? recursiveRouteFilter(route.routes) : undefined,
      };
    });

  return returnRoutes;
};

function EventsDropdown() {
  const [events, setEvents] = useState<
    {
      day: Date;
      namedays: {
        name: string;
        extended: boolean;
      }[];
      users: IUserResponse[];
      holidays: IHolidayResponse[];
    }[]
  >([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCelebrations = () => {
    axios
      .get<
        {
          day: Date;
          namedays: {
            name: string;
            extended: boolean;
          }[];
          users: IUserResponse[];
          holidays: IHolidayResponse[];
        }[]
      >("/events")
      .then((response) => {
        setEvents(response.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  };

  useEffect(() => {
    getCelebrations();
  }, []);

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge
          variant="dot"
          invisible={
            !events[0] ||
            events[0].users.length + events[0].holidays.length === 0
          }
          color="error"
        >
          <CardGiftcardIcon />
        </Badge>
      </IconButton>
      <Popover
        // id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            padding: 1,
          },
        }}
      >
        <Stack
          spacing={2}
          divider={<Divider />}
          sx={{
            width: 240,
          }}
        >
          {events.map((event, index) => (
            <Stack key={moment(event.day).toISOString()} spacing={1}>
              <Stack
                direction="row"
                alignItems="baseline"
                spacing={2}
                sx={{
                  color: moment().isSame(event.day, "date")
                    ? "text.default"
                    : "text.disabled",
                }}
              >
                <Typography variant="h6" color="inherit">
                  {moment(event.day).toDate().getDate()}{" "}
                  {moment(event.day).format("MMM")}
                </Typography>
                <Typography variant="caption" color="inherit">
                  {event.namedays.map((item) => item.name).join(", ")}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                {event.holidays.map((holiday) => (
                  <Card
                    key={holiday._id}
                    sx={{
                      padding: 1,
                      borderLeft: 5,
                      borderColor:
                        holiday.type === "holiday"
                          ? "success.main"
                          : "error.main",
                    }}
                  >
                    <Typography variant="body2">{holiday.name}</Typography>
                  </Card>
                ))}

                {event.users.map((user) => {
                  let celebrates: string[] = [];

                  if (
                    event.namedays.find((item) =>
                      user.name.startsWith(item.name)
                    )
                  ) {
                    celebrates.push("vārda dienu");
                  }

                  if (
                    user.dateOfBirth &&
                    moment(user.dateOfBirth).isSame(event.day, "date")
                  ) {
                    celebrates.push("dzimšanas dienu");
                  }

                  return (
                    <Card
                      sx={{
                        padding: 1,
                      }}
                    >
                      <Stack
                        key={user._id}
                        direction="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <Avatar
                          alt={user.name}
                          src={user.image}
                          sx={{ width: 24, height: 24 }}
                        />
                        <Stack>
                          <Typography variant="body2">{user.name}</Typography>
                          <Typography variant="caption">
                            Svin: {celebrates.join(", ")}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Card>
                  );
                })}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Popover>
    </>
  );
}

function ProfileDropdown() {
  const { logout, user } = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <PersonIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Link
          component={RouterLink}
          to="/profile"
          underline="none"
          color="inherit"
        >
          <MenuItem>
            <Avatar alt={user?.name} src={user?.picture} /> Profile
          </MenuItem>
        </Link>
        <Divider />
        <MenuItem
          onClick={() => {
            logout();
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
    </>
  );
}

function CartDropdown() {
  const cart = useAppSelector((state) => state.cart);

  return (
    <PermissionGuard
      permission={[PERMISSIONS.READ_BASKET, PERMISSIONS.WRITE_BASKET]}
    >
      {({ hasAccess }) => (
        <>
          {hasAccess ? (
            <Link component={RouterLink} to="/cart" color="inherit">
              <IconButton color="inherit">
                <Badge badgeContent={cart.cartItems.length} color="secondary">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </Link>
          ) : null}
        </>
      )}
    </PermissionGuard>
  );
}

const ClinicHeader: React.FunctionComponent<{ clinicId: string }> = ({
  clinicId,
}) => {
  const [clinic, setClinic] = useState<ClinicResponse>();

  useEffect(() => {
    axios
      .get<ClinicResponse>(`/clinics/${clinicId}`)
      .then((response) => {
        setClinic(response.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, [clinicId]);

  return <Typography variant="h5">{clinic?.name}</Typography>;
};

// styled mui components

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

export default Layout;
