import { useState, useEffect } from "react";
import { useParams } from "react-router";
import axios from "axios";

import { Grid, Typography, Stack, Checkbox, Avatar } from "@mui/material";

import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import moment from "moment";
import PermissionGuard from "../components/PermissionGuard";
import PERMISSIONS from "../constants/permissions";
import { IClinicUserResponse } from "../types/user";

const WorkingDays: React.FunctionComponent<{}> = () => {
  const { clinic } = useParams();

  const [data, setData] = useState<IClinicUserResponse[]>([]);

  useEffect(() => {
    axios
      .get<IClinicUserResponse[]>(`/clinics/${clinic}/users`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
      });
  }, [clinic]);

  const columns: GridColDef[] = [
    {
      field: "user",
      headerName: "Darbinieks",
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={params.row.name} src={params.row.image} />
            <Typography>{params.row.name}</Typography>
          </Stack>
        );
      },
      flex: 1,
    },
    ...moment.weekdays(true).map((value) => {
      return {
        field: `${moment().day(value).locale("en").format("dddd").toLowerCase()}`,
        headerName: value,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <PermissionGuard permission={PERMISSIONS.WRITE_WORKING_DAYS}>
              {({ hasAccess }) => (
                <Checkbox
                  disabled={!hasAccess}
                  defaultChecked={
                    params.row.workingDays ? params.row.workingDays[params.field] : false
                  }
                  onChange={(event) => {
                    axios
                      .put<IClinicUserResponse>(`/clinics/${clinic}/workingDays`, {
                        user: params.id,
                        day: params.field,
                        value: event.target.checked,
                      })
                      .then((response) => {
                        setData((e) =>
                          e.map((row) => (row._id === response.data._id ? response.data : row))
                        );
                      });
                  }}
                />
              )}
            </PermissionGuard>
          );
        },
      };
    }),
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Darba dienas</Typography>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <DataGrid
        getRowId={(row) => row._id}
        rows={data}
        columns={columns}
        hideFooterPagination
        autoHeight
        disableSelectionOnClick
        disableColumnMenu
        hideFooter
      />
    </Stack>
  );
};

export default WorkingDays;
