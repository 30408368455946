import * as yup from "yup";

const materialSchema = yup.object({
  name: yup.string().required(),
  category: yup.string().required(),
});

export type MaterialBase = yup.InferType<typeof materialSchema>;

export default materialSchema;

export const materialEditSchema = yup.object({
  name: yup.string().required(),
  category: yup.string().required(),
  suppliers: yup.array(
    yup.object({
      supplier: yup.string().required(),
      price: yup.number().required(),
    })
  ),
});

export type MaterialEditBase = yup.InferType<typeof materialEditSchema>;
