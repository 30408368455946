import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import _ from "lodash";

import {
  Grid,
  Stack,
  TextField,
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  Typography,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
  CardActions,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import moment from "moment";

import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

import { useAppDispatch } from "../hooks/redux";
import PermissionGuard from "../components/PermissionGuard";
import { openModal } from "../features/modals";

import { useParams } from "react-router";

import AddIcon from "@mui/icons-material/Add";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GridOnIcon from "@mui/icons-material/GridOn";

import MODALS from "../constants/modals";
import { LoadingButton } from "@mui/lab";

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import writeXlsxFile from "write-excel-file";
import type { SheetData, Row } from "write-excel-file";
import PERMISSIONS from "../constants/permissions";
import {
  ChairScheduleResponse,
  ClinicChairResponse,
  IWorkloadResponse,
} from "../types/clinic";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import { ScheduleBase } from "../schemas/schedule";
import ROLES from "../constants/roles";
import {
  ContentTable,
  TDocumentDefinitions,
  TableCell,
  Content,
} from "pdfmake/interfaces";
import { IHolidayResponse } from "../types/holiday";

import EditIcon from "@mui/icons-material/Edit";
import CircleIcon from "@mui/icons-material/Circle";
import HistoryIcon from "@mui/icons-material/History";

import { ChairType, ChairTypes, CHAIR_TYPES } from "../constants/chairTypes";
import EmployeeAutocomplete from "../components/EmployeeAutocomplete";
import { useDebouncedCallback } from "use-debounce";
import { HolidayTypes } from "../constants/holidayTypes";

// @ts-ignore
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Schedule: React.FunctionComponent<{}> = () => {
  const { clinic } = useParams();

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [date, setDate] = useState<Date>(moment().toDate());
  const [chairs, setChairs] = useState<ClinicChairResponse[]>([]);
  const [visibleMonth, setVisibleMonth] = useState<Date>(moment().toDate());
  const [workload, setWorkload] = useState<IWorkloadResponse>();
  const [holidays, setHolidays] = useState<IHolidayResponse[]>([]);

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<ClinicChairResponse[]>(`/clinics/${clinic}/chairs/schedule`, {
        params: {
          date,
        },
      })
      .then((response) => {
        setChairs(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
      })
      .finally(() => {
        setIsLoading(true);
      });
  }, [date, clinic]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const loadWorkload = useCallback(() => {
    axios
      .get<IWorkloadResponse>(`/clinics/${clinic}/workload`, {
        params: {
          date: moment.utc(visibleMonth).format(),
        },
      })
      .then((response) => {
        setWorkload(response.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, [visibleMonth]);

  useEffect(() => {
    loadWorkload();
  }, [loadWorkload]);

  const loadHolidays = useCallback(() => {
    axios
      .get<IHolidayResponse[]>(`/clinics/${clinic}/holidays`, {
        params: {
          date: moment(visibleMonth).format(),
        },
      })
      .then((response) => {
        setHolidays(response.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, [visibleMonth]);

  useEffect(() => {
    loadHolidays();
  }, [loadHolidays]);

  const exportPdf = useCallback(async () => {
    if (!workload) {
      return;
    }

    setIsExporting(true);

    let docDefinition: TDocumentDefinitions = {
      content: [
        {
          text: `${workload.clinic.name} (${moment(visibleMonth)
            .startOf("month")
            .format("L")} - ${moment(visibleMonth)
            .endOf("month")
            .format("L")})`,
          style: "header",
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        headerChair: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
          alignment: "center",
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
      },
    };
    let contentTable: ContentTable = {
      style: "tableExample",
      table: {
        widths: [90, ...workload.chairs.map((chair) => "*")],
        body: [],
      },
    };
    let tableHeaders: TableCell[] = [{ text: "Datums", style: "tableHeader" }];
    workload.chairs.forEach((chair) => {
      tableHeaders.push({ text: chair.name, style: "headerChair" });
    });
    contentTable.table.body.push(tableHeaders);

    workload.schedule.forEach((date) => {
      let row: TableCell[] = [moment(date.date).format("L")];
      workload.chairs.forEach((chair) => {
        const tmp = date.chairs.find(
          (scheduleChair) => scheduleChair.chair === chair._id
        );

        if (tmp) {
          switch (tmp.type) {
            case ChairTypes.Dentist:
              row.push(
                `${tmp.doctor ? tmp.doctor.name : ""} \n ${
                  tmp.assistant ? tmp.assistant.name : ""
                }`
              );
              break;
            case ChairTypes.Orthodontist:
              row.push(
                `${tmp.orthodontist ? tmp.orthodontist.name : ""} \n ${
                  tmp.assistant ? tmp.assistant.name : ""
                }`
              );
              break;
            case ChairTypes.Bus:
              row.push(
                `${tmp.specialist ? tmp.specialist.name : ""}
                ${tmp.assistant ? `\n${tmp.assistant.name}` : ""}
                ${tmp.driver ? `\n${tmp.driver.name}` : ""}
                ${tmp.destination ? `\n${tmp.destination}` : ""}`
              );
              break;
            case ChairTypes.Hygienist:
              row.push(`${tmp.hygenist ? tmp.hygenist.name : ""}`);
              break;
            case ChairTypes.Surgeon:
              row.push(
                `${tmp.doctor ? tmp.doctor.name : ""}  ${
                  tmp.assistant ? `\n${tmp.assistant.name}` : ""
                } ${tmp.extraAssistant ? `\n${tmp.extraAssistant.name}` : ""}`
              );
              break;
            default:
              row.push("");
              break;
          }
        } else {
          row.push("");
        }
      });
      contentTable.table.body.push(row);
    });
    (docDefinition.content as Content[]).push(contentTable);
    pdfMake.createPdf(docDefinition).open();

    setIsExporting(false);
  }, [workload]);

  const exportXlsx = useCallback(async () => {
    if (!workload) {
      return;
    }

    setIsExporting(true);

    let tableHeaders: Row = [{ value: "Datums", fontWeight: "bold" }];
    const columns = [{ width: 20 }];
    const defaultFontSize = 12;

    workload.chairs.forEach((chair) => {
      tableHeaders.push({ value: chair.name, fontWeight: "bold" });
      columns.push({ width: 20 });
    });

    const data: SheetData = [tableHeaders];

    workload.schedule.forEach((date) => {
      let row: Row = [
        { value: moment(date.date).format("L"), fontWeight: "bold" },
      ];
      workload.chairs.forEach((chair) => {
        const tmp = date.chairs.find(
          (scheduleChair) => scheduleChair.chair === chair._id
        );

        if (tmp) {
          switch (tmp.type) {
            case ChairTypes.Dentist:
              row.push({
                value: `${tmp.doctor ? tmp.doctor.name : ""} ${
                  tmp.assistant ? `\n${tmp.assistant.name}` : ""
                }`,
              });
              break;
            case ChairTypes.Orthodontist:
              row.push({
                value: `${tmp.orthodontist ? tmp.orthodontist.name : ""} ${
                  tmp.assistant ? `\n${tmp.assistant.name}` : ""
                }`,
              });
              break;
            case ChairTypes.Bus:
              row.push({
                value: `${tmp.specialist ? tmp.specialist.name : ""}
                ${tmp.assistant ? `\n${tmp.assistant.name}` : ""}
                ${tmp.driver ? `\n${tmp.driver.name}` : ""}
                ${tmp.destination ? `\n${tmp.destination}` : ""}`,
              });
              break;
            case ChairTypes.Hygienist:
              row.push({ value: `${tmp.hygenist ? tmp.hygenist.name : ""}` });
              break;
            case ChairTypes.Surgeon:
              row.push({
                value: `${tmp.doctor ? tmp.doctor.name : ""} ${
                  tmp.assistant ? `\n${tmp.assistant.name}` : ""
                } ${tmp.extraAssistant ? `\n${tmp.extraAssistant.name}` : ""}`,
              });
              break;
            default:
              row.push({ value: "" });
              break;
          }
        } else {
          row.push({ value: "" });
        }
      });
      row.forEach((p) => {
        p!.wrap = true;
        p!.height = defaultFontSize * 3 * 1.4;
        p!.alignVertical = "top";
      });
      data.push(row);
    });

    const sheetName = workload.clinic.name.replace(/[\[\]\/\\\:\*\?]/g, "");
    const fileName = `${workload.clinic.name}_${moment(visibleMonth)
      .startOf("month")
      .format("L")}-${moment(visibleMonth).endOf("month").format("L")}`.slice(
      0,
      -1
    );

    await writeXlsxFile(data, {
      columns,
      fileName: fileName + ".xlsx",
      sheet: sheetName,
    });

    setIsExporting(false);
  }, [workload]);

  if (!clinic) {
    // TODO: show error
    return <>error?</>;
  }

  const open = Boolean(menuAnchorEl);

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleExportClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Grafiks</Typography>
        </Grid>
        <Grid item>
          <LoadingButton
            loading={isExporting}
            startIcon={<FileDownloadIcon />}
            aria-controls={open ? "export-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="export as"
            aria-haspopup="menu"
            onClick={handleExportClick}
          >
            Eksportēt
          </LoadingButton>
          <Menu
            id="export-menu"
            anchorEl={menuAnchorEl}
            open={open}
            onClose={handleExportClose}
          >
            <MenuItem
              onClick={() => {
                exportPdf();
                setMenuAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <PictureAsPdfIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="pdf"></ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                exportXlsx();
                setMenuAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <GridOnIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="xlsx"></ListItemText>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <Stack>
        <StaticDatePicker
          sx={{
            padding: 2,
            "& .MuiPickersSlideTransition-root": {
              minHeight: 320,
            },
          }}
          className="picker-with-dots"
          onChange={(newValue) => {
            if (newValue) {
              setDate(newValue.toDate());
            }
          }}
          value={moment(date)}
          onMonthChange={(month) => {
            setVisibleMonth(
              moment(month).utc().add(moment(month).utcOffset(), "m").toDate()
            );
          }}
          shouldDisableDate={(day) => {
            const tmpHoliday = holidays.find((holiday) =>
              moment(day).isBetween(
                moment(holiday.from).startOf("day"),
                moment(holiday.to).endOf("day"),
                undefined,
                "[]"
              )
            );

            if (tmpHoliday && tmpHoliday.type === HolidayTypes.holiday) {
              return true;
            }

            if (
              moment(day).weekday() === 6 &&
              (!tmpHoliday || tmpHoliday.type !== HolidayTypes.workday)
            ) {
              return true;
            }

            return false;
          }}
          displayStaticWrapperAs="desktop"
          slots={{
            day: (props) => {
              const d = workload?.schedule.find((f) =>
                moment(props.day).isSame(
                  moment.utc(f._id, "YYYY-MM-DD").local(),
                  "day"
                )
              );

              const missingSchedule =
                workload?.chairs.length !== d?.chairs.length;

              let missingDoctor = false;
              let missingAssistant = false;
              let missingHygenist = false;
              let missingDestination = false;
              let missingOrthodontist = false;
              let missingDriver = false;

              if (d) {
                for (let chair of d.chairs) {
                  switch (chair.type) {
                    case ChairTypes.Bus:
                      if (_.isEmpty(chair.specialist)) {
                        missingDoctor = true;
                      }
                      if (_.isEmpty(chair.destination)) {
                        missingDestination = true;
                      }
                      if (_.isEmpty(chair.assistant)) {
                        missingAssistant = true;
                      }
                      if (_.isEmpty(chair.driver)) {
                        missingDriver = true;
                      }

                      break;
                    case ChairTypes.Hygienist:
                      if (_.isEmpty(chair.hygenist)) {
                        missingHygenist = true;
                      }
                      break;
                    case ChairTypes.Orthodontist:
                      if (_.isEmpty(chair.orthodontist)) {
                        missingOrthodontist = true;
                      }

                      break;
                    case ChairTypes.Dentist:
                    case ChairTypes.Surgeon:
                    default:
                      if (_.isEmpty(chair.doctor)) {
                        missingDoctor = true;
                      }
                      if (_.isEmpty(chair.assistant)) {
                        missingAssistant = true;
                      }
                      break;
                  }
                }
              }

              return (
                <Stack key={props.key} sx={{ paddingBottom: 1 }}>
                  <PickersDay {...props} />
                  {!props.disabled &&
                  workload &&
                  moment(props.day).isSame(moment(visibleMonth), "month") ? (
                    <Stack direction="row" justifyContent="center">
                      {missingSchedule ? (
                        <CircleIcon color="success" sx={{ fontSize: 7 }} />
                      ) : null}
                      {missingDoctor ? (
                        <CircleIcon color="warning" sx={{ fontSize: 7 }} />
                      ) : null}
                      {missingAssistant ? (
                        <CircleIcon color="secondary" sx={{ fontSize: 7 }} />
                      ) : null}
                      {missingHygenist ? (
                        <CircleIcon color="info" sx={{ fontSize: 7 }} />
                      ) : null}
                      {missingOrthodontist ? (
                        <CircleIcon color="error" sx={{ fontSize: 7 }} />
                      ) : null}
                      {missingDriver ? (
                        <CircleIcon color="action" sx={{ fontSize: 7 }} />
                      ) : null}
                    </Stack>
                  ) : null}
                </Stack>
              );
            },
          }}
        />
        <Stack direction="row" spacing={2} justifyContent="right">
          <Typography variant="caption">
            <CircleIcon color="success" sx={{ fontSize: 10 }} /> brīva iekārta
          </Typography>
          <Typography variant="caption">
            <CircleIcon color="warning" sx={{ fontSize: 10 }} /> trūkst ārsts
          </Typography>
          <Typography variant="caption">
            <CircleIcon color="secondary" sx={{ fontSize: 10 }} /> trūkst
            asistents
          </Typography>
          <Typography variant="caption">
            <CircleIcon color="info" sx={{ fontSize: 10 }} /> trūkst higiēnists
          </Typography>
          <Typography variant="caption">
            <CircleIcon color="error" sx={{ fontSize: 10 }} /> trūkst ortodonts
          </Typography>
          <Typography variant="caption">
            <CircleIcon color="action" sx={{ fontSize: 10 }} /> trūkst šoferis
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4">Krēsli</Typography>
          </Grid>
          <PermissionGuard permission={PERMISSIONS.WRITE_CHAIRS}>
            {({ hasAccess }) => (
              <>
                {hasAccess ? (
                  <Grid item>
                    <Button
                      startIcon={<AddIcon />}
                      onClick={() => {
                        dispatch(
                          openModal({
                            type: MODALS.Chair,
                            clinicId: clinic,
                            callback: () => {
                              loadData();
                              loadWorkload();
                            },
                          })
                        );
                      }}
                    >
                      Pievienot
                    </Button>
                  </Grid>
                ) : null}
              </>
            )}
          </PermissionGuard>
        </Grid>

        <PermissionGuard permission={PERMISSIONS.WRITE_SCHEDULE}>
          {({ hasAccess }) => (
            <div>
              <Grid container spacing={2}>
                {chairs.map((chair) => (
                  <Grid item key={chair._id} xs={12} sm={6} md={4} lg={3}>
                    <Chair
                      disabled={
                        !hasAccess ||
                        holidays.find(
                          (holiday) =>
                            moment(date).isBetween(
                              moment(holiday.from).startOf("day"),
                              moment(holiday.to).endOf("day"),
                              undefined,
                              "[]"
                            ) && holiday.type === HolidayTypes.holiday
                        ) !== undefined
                      }
                      date={date}
                      chair={chair}
                      clinic={clinic}
                      callback={(newValue) => {
                        if (newValue) {
                          setChairs((e) =>
                            e.map((c) =>
                              c._id === chair._id
                                ? { ...c, schedule: newValue }
                                : c
                            )
                          );
                        } else {
                          loadData();
                        }
                        loadWorkload();
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </PermissionGuard>
      </Stack>
    </Stack>
  );
};

interface IChairProps {
  clinic: string;
  date: Date;
  chair: ClinicChairResponse;
  callback: (newValue?: ChairScheduleResponse) => void;
  disabled?: boolean;
}

const Chair: React.FunctionComponent<IChairProps> = ({
  date,
  chair,
  clinic,
  disabled,
  callback,
}) => {
  const dispatch = useAppDispatch();

  const formik = useFormik<ScheduleBase>({
    enableReinitialize: true,
    initialValues: chair.schedule
      ? chair.schedule
      : {
          type: "",
          doctor: null,
          assistant: null,
          specialist: null,
          hygenist: null,
          extraAssistant: null,
          destination: null,
          orthodontist: null,
          driver: null,
        },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      axios
        .put<ChairScheduleResponse>(`/clinics/chairs/${chair._id}/schedule`, {
          ...chair.schedule,
          ...values,
          date,
        })
        .then((response) => {
          callback(response.data);
        })
        .catch((err) => {
          console.log("err:", err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const debounced = useDebouncedCallback(() => {
    formik.handleSubmit();
  }, 800);

  useEffect(() => {
    if (formik.dirty) {
      debounced();
    }
  }, [formik.dirty, formik.values]);

  return (
    <Card>
      <CardHeader
        title={chair.name}
        action={
          <PermissionGuard permission={PERMISSIONS.WRITE_CHAIRS}>
            {({ hasAccess }) => (
              <>
                {hasAccess ? (
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        openModal({
                          type: MODALS.Chair,
                          clinicId: clinic,
                          initialValues: chair,
                          callback: () => callback(),
                        })
                      );
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                ) : null}
              </>
            )}
          </PermissionGuard>
        }
      />
      <CardContent>
        <Stack spacing={2}>
          <PermissionGuard permission={PERMISSIONS.WRITE_SCHEDULE}>
            {({ hasAccess }) => (
              <>
                {hasAccess ? (
                  <Stack direction="row" spacing={2} justifyContent="center">
                    {_.map(
                      CHAIR_TYPES,
                      (chairType: ChairType, key: ChairTypes) => (
                        <Tooltip key={key} title={chairType.label}>
                          <IconButton
                            size="medium"
                            disabled={disabled}
                            color={
                              formik.values.type === key.toString()
                                ? "primary"
                                : "default"
                            }
                            onClick={() => {
                              if (chair.schedule) {
                                dispatch(
                                  openModal({
                                    type: MODALS.Approve,
                                    title: "Mainīt krēsla tipu?",
                                    message:
                                      "Mainot krēsla tipu, ievadītie dati tiks dzēsti. Vai tiešām vēlaties to darīt?",
                                    onApprove: () => {
                                      formik.setFieldValue("type", key);
                                    },
                                  })
                                );
                              } else {
                                formik.setFieldValue("type", key);
                              }
                            }}
                          >
                            {chairType.icon}
                          </IconButton>
                        </Tooltip>
                      )
                    )}
                  </Stack>
                ) : null}
              </>
            )}
          </PermissionGuard>

          <FormikProvider value={formik}>
            <ChairFields date={date} clinic={clinic} disabled={disabled} />
          </FormikProvider>
        </Stack>
      </CardContent>
      <CardActions style={{ justifyContent: "space-between" }}>
        <Stack>
          {chair.schedule && chair.schedule.updatedAt && (
            <Tooltip
              title={`Pēdējo reizi labots: ${moment(
                chair.schedule.updatedAt
              ).format("L LT")} (${chair.schedule.lastModifiedBy?.name})`}
            >
              <HistoryIcon fontSize="small" />
            </Tooltip>
          )}
        </Stack>
        {formik.dirty || formik.isSubmitting ? (
          formik.isSubmitting ? (
            <CircularProgress size={16} />
          ) : (
            <Typography variant="caption" align="right">
              Sagalbā...
            </Typography>
          )
        ) : null}
      </CardActions>
    </Card>
  );
};

interface IChairFieldsProps {
  date: Date;
  clinic: string;
  disabled?: boolean;
}

const ChairFields: React.FC<IChairFieldsProps> = ({
  date,
  clinic,
  disabled,
}) => {
  const { values, submitCount, handleChange, setFieldValue, errors } =
    useFormikContext<ScheduleBase>();

  switch (values.type) {
    case ChairTypes.Orthodontist:
      return (
        <>
          <EmployeeAutocomplete
            label="Ortodonts"
            roles={[ROLES.Orthodontist]}
            date={date}
            disabled={disabled}
            clinic={clinic}
            value={values.orthodontist}
            onChange={(newValue) => {
              setFieldValue("orthodontist", newValue);
            }}
          />
          <EmployeeAutocomplete
            label="Asistents"
            roles={[ROLES.Assistant]}
            date={date}
            disabled={disabled}
            clinic={clinic}
            value={values.assistant}
            onChange={(newValue) => {
              setFieldValue("assistant", newValue);
            }}
          />
        </>
      );
    case ChairTypes.Dentist:
      return (
        <>
          <EmployeeAutocomplete
            label="Dr."
            roles={[ROLES.Doctor]}
            date={date}
            disabled={disabled}
            clinic={clinic}
            value={values.doctor}
            onChange={(newValue) => {
              setFieldValue("doctor", newValue);
            }}
          />
          <EmployeeAutocomplete
            label="Asistents"
            roles={[ROLES.Assistant]}
            date={date}
            disabled={disabled}
            clinic={clinic}
            value={values.assistant}
            onChange={(newValue) => {
              setFieldValue("assistant", newValue);
            }}
          />
        </>
      );
    case ChairTypes.Hygienist:
      return (
        <>
          <EmployeeAutocomplete
            label="Higiēnists"
            roles={[ROLES.Hygienist]}
            date={date}
            disabled={disabled}
            clinic={clinic}
            value={values.hygenist}
            onChange={(newValue) => {
              setFieldValue("hygenist", newValue);
            }}
          />
        </>
      );
    case ChairTypes.Surgeon:
      return (
        <>
          <EmployeeAutocomplete
            label="Dr."
            roles={[ROLES.Doctor]}
            date={date}
            disabled={disabled}
            clinic={clinic}
            value={values.doctor}
            onChange={(newValue) => {
              setFieldValue("doctor", newValue);
            }}
          />
          <EmployeeAutocomplete
            label="Asistents"
            roles={[ROLES.Assistant]}
            date={date}
            disabled={disabled}
            clinic={clinic}
            value={values.assistant}
            onChange={(newValue) => {
              setFieldValue("assistant", newValue);
            }}
          />
          <EmployeeAutocomplete
            label="Papildu asistents"
            roles={[ROLES.Assistant]}
            date={date}
            disabled={disabled}
            clinic={clinic}
            value={values.extraAssistant}
            onChange={(newValue) => {
              setFieldValue("extraAssistant", newValue);
            }}
          />
        </>
      );
    case ChairTypes.Bus:
      return (
        <>
          <EmployeeAutocomplete
            label="Speciālists"
            roles={[ROLES.Doctor, ROLES.Hygienist]}
            date={date}
            disabled={disabled}
            clinic={clinic}
            value={values.specialist}
            onChange={(newValue) => {
              setFieldValue("specialist", newValue);
            }}
          />
          <EmployeeAutocomplete
            label="Asistents"
            roles={[ROLES.Assistant]}
            date={date}
            disabled={disabled}
            clinic={clinic}
            value={values.assistant}
            onChange={(newValue) => {
              setFieldValue("assistant", newValue);
            }}
          />
          <EmployeeAutocomplete
            label="Šoferis"
            roles={[ROLES.Driver]}
            date={date}
            disabled={disabled}
            clinic={clinic}
            value={values.driver}
            onChange={(newValue) => {
              setFieldValue("driver", newValue);
            }}
          />
          <TextField
            id="destination"
            label="Galamērķis"
            variant="standard"
            value={values.destination ? values.destination : ""}
            disabled={disabled}
            onChange={handleChange}
            error={Boolean(errors.destination) && submitCount > 0}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faLocationDot} />
                </InputAdornment>
              ),
            }}
          />
        </>
      );
    default:
      return <></>;
  }
};

export default Schedule;
