import { useEffect, useState, useCallback, useMemo } from "react";

import MODALS from "../constants/modals";

import { useAppDispatch } from "../hooks/redux";
import { openModal } from "../features/modals";
import axios from "axios";

import {
  Stack,
  Grid,
  Typography,
  Button,
  Chip,
  IconButton,
  Box,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import PermissionGuard from "../components/PermissionGuard";
import PERMISSIONS from "../constants/permissions";
import { useParams } from "react-router";
import { IWarehouseItemResponse } from "../types/warehouseItem";
import {
  WAREHOUE_ITEM_STATUSES,
  WarehouseItemStatus,
} from "../constants/warehouse";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import { IPaginationResponse } from "../types/common";

import { faBoxesPacking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageThumbnail from "../components/ImageThumbnail";

const Warehouse: React.FC = () => {
  const { clinic } = useParams<{
    clinic: string;
  }>();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] =
    useState<IPaginationResponse<IWarehouseItemResponse>>();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: "category",
      desc: true,
    },
  ]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IPaginationResponse<IWarehouseItemResponse>>(
        `/clinics/${clinic}/warehouse`,
        {
          params: {
            pagination,
            sorting,
            columnFilters,
          },
        }
      )
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pagination, sorting, columnFilters, clinic]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = useMemo<MRT_ColumnDef<IWarehouseItemResponse>[]>(
    () => [
      {
        header: "Attēls",
        sortable: false,
        accessorFn: (params) => {
          if (!params.images) return null;
          return (
            <ImageThumbnail
              value={params.images[0]}
              basePath="/classifiers/materials/image/"
            />
          );
        },
      },
      {
        header: "Materiāls",
        accessorKey: "name",
      },
      {
        header: "Kategorija",
        accessorKey: "category.name",
      },
      {
        header: "Atlikums",
        accessorFn: (originalRow) => {
          if (!originalRow.statuses) return null;

          return (
            <Stack spacing={0.5} direction="row">
              {originalRow.statuses.map((status) => {
                const itemStatus = WAREHOUE_ITEM_STATUSES[status._id];
                return (
                  <Chip
                    key={status._id}
                    label={`${itemStatus.label}:${status.count}`}
                    color={itemStatus.color}
                    variant="outlined"
                    size="small"
                  />
                );
              })}
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Noliktava</Typography>
        </Grid>
        <PermissionGuard permission={PERMISSIONS.WRITE_HOLIDAYS}>
          {({ hasAccess }) => (
            <>
              {hasAccess ? (
                <Grid item>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      if (!clinic) {
                        return;
                      }

                      dispatch(
                        openModal({
                          type: MODALS.WarehouseAddItems,
                          clinic: clinic,
                          callback: () => loadData(),
                        })
                      );
                    }}
                  >
                    Pievienot
                  </Button>
                </Grid>
              ) : null}
            </>
          )}
        </PermissionGuard>
      </Grid>
      <MaterialReactTable
        columns={columns}
        enableColumnActions={false}
        data={response?.docs || []}
        getRowId={(row) => row._id}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => {
          const inStockCount =
            row.original.statuses.find(
              (status) => status._id === WarehouseItemStatus.InStock
            )?.count || 0;

          return (
            <IconButton
              disabled={inStockCount === 0}
              onClick={() => {
                if (!clinic) {
                  return;
                }

                dispatch(
                  openModal({
                    type: MODALS.WarehouseTakeItems,
                    material: row.original,
                    clinic: clinic,
                    callback: () => loadData(),
                  })
                );
              }}
            >
              <Box width={26} height={26}>
                <FontAwesomeIcon icon={faBoxesPacking} size="xs" />
              </Box>
            </IconButton>
          );
        }}
        localization={{
          actions: "Darbības",
        }}
        manualPagination
        rowCount={response?.totalDocs || 0}
        onPaginationChange={setPagination}
        manualSorting
        onSortingChange={setSorting}
        manualFiltering
        onColumnFiltersChange={setColumnFilters}
        enableGlobalFilter={false}
        state={{
          isLoading,
          pagination,
          columnFilters,
          sorting,
        }}
      />
    </Stack>
  );
};

export default Warehouse;
