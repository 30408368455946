import { useEffect, useState } from "react";
import { IAbsenceModalProps } from "../types/modal";
import { IUserBaseResponse } from "../types/user";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import { useFormik } from "formik";
import axios from "axios";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  Autocomplete,
  TextField,
  InputAdornment,
  Stack,
  Grid,
  Avatar,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import absenceSchema, { AbsenceBase } from "../schemas/absence";
import { IAbsenceTypeResponse } from "../types/classifiers";
import { usePermissions } from "../hooks/permissions";
import PERMISSIONS from "../constants/permissions";

const AbsenceModal: React.FunctionComponent<IAbsenceModalProps> = ({
  id,
  initialValues,
  callback,
}) => {
  const dispatch = useAppDispatch();
  const { hasPermission } = usePermissions();

  const [isDeleting, setIsDeleting] = useState(false);
  const [users, setUsers] = useState<IUserBaseResponse[]>([]);
  const [absenceTypes, setAbsenceTypes] = useState<IAbsenceTypeResponse[]>([]);

  const {
    values,
    dirty,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    resetForm,
    submitCount,
  } = useFormik<AbsenceBase>({
    initialValues: {
      user: initialValues ? initialValues.user._id : "",
      type: initialValues && initialValues.type ? initialValues.type._id : "",
      from: initialValues ? initialValues.from : moment().toDate(),
      to: initialValues ? initialValues.to : moment().add(1, "day").toDate(),
    },
    onSubmit: (values) => {
      axios
        .put("/absences", {
          _id: initialValues ? initialValues._id : undefined,
          ...values,
        })
        .then(() => {
          callback();
          handleClose();
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    validationSchema: absenceSchema,
  });

  const handleDelete = (id: string) => {
    setIsDeleting(true);
    axios
      .delete(`/absences/${id}`)
      .then(() => {
        callback();
        handleClose();
      })
      .catch((err) => {
        console.log("err:", err);
        setIsDeleting(false);
      });
  };

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    axios
      .get<IUserBaseResponse[]>(`/users/list`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
      });

    axios
      .get<IAbsenceTypeResponse[]>(`/classifiers/absenceTypes`)
      .then((response) => {
        setAbsenceTypes(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
      });
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle>Prombūtne</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="No"
                value={moment(values.from)}
                onChange={(newValue) => {
                  if (moment(newValue).isAfter(moment(values.to))) {
                    setFieldValue("to", newValue ? moment(newValue).add(1, "day") : null);
                  }
                  setFieldValue("from", newValue);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "standard",
                    error: Boolean(errors.from) && submitCount > 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Līdz"
                value={moment(values.to)}
                minDate={moment(values.from)}
                onChange={(newValue) => {
                  setFieldValue("to", newValue);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "standard",
                    error: Boolean(errors.to) && submitCount > 0,
                  },
                }}
              />
            </Grid>
          </Grid>

          <FormControl variant="standard" error={Boolean(errors.type) && submitCount > 0}>
            <InputLabel id="type-select">Prombūtnes veids</InputLabel>
            <Select
              labelId="type-select"
              id="type"
              label="Prombūtnes veids"
              value={values.type ? values.type : ""}
              onChange={(event) => {
                setFieldValue("type", event.target.value ? event.target.value : null);
              }}
            >
              <MenuItem value="">Neviens</MenuItem>
              {absenceTypes.map((absenceType) => (
                <MenuItem key={absenceType._id} value={absenceType._id}>
                  {absenceType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {users.length > 0 ? (
            <Autocomplete
              value={values.user ? users.find((user) => user._id === values.user) : null}
              onChange={(event: any, newValue) => {
                setFieldValue("user", newValue ? newValue._id : null);
              }}
              renderOption={(props, option) => {
                return (
                  <Stack
                    {...props}
                    component="li"
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    key={option._id}
                  >
                    <Avatar alt={option.name} src={option.image} sx={{ width: 24, height: 24 }} />
                    <Typography>{option.name}</Typography>
                  </Stack>
                );
              }}
              isOptionEqualToValue={(option, value) => {
                return value && option._id === value._id;
              }}
              getOptionLabel={(option) => option.name}
              options={users}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Darbinieks"
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faUser} />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(errors.user) && submitCount > 0}
                />
              )}
            />
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Aizvērt</Button>
        {initialValues?._id && hasPermission(PERMISSIONS.DELETE_ABSENCES) ? (
          <LoadingButton
            loading={isDeleting}
            onClick={() => {
              handleDelete(initialValues._id);
            }}
            color="error"
            variant="outlined"
          >
            Dzēst
          </LoadingButton>
        ) : null}
        <Button
          disabled={isDeleting}
          onClick={() => {
            handleSubmit();
          }}
          variant="contained"
        >
          {initialValues?._id ? "Saglabāt" : "Pievienot"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AbsenceModal;
