import { useEffect, useState, useCallback } from "react";

import MODALS from "../../../constants/modals";

import { useAppDispatch } from "../../../hooks/redux";
import { openModal } from "../../../features/modals";
import axios from "axios";

import { Stack, Grid, Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Collapse, IconButton, Tooltip, CircularProgress, Box } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { ICategoryResponse } from "../../../types/classifiers";
import PermissionGuard from "../../../components/PermissionGuard";
import PERMISSIONS from "../../../constants/permissions";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';


interface ICategoryTableRow {
  row: ICategoryResponse;
  expandAll: boolean;
  level: number;
  callback: () => void;
}

const CategoryTableRow: React.FunctionComponent<ICategoryTableRow> = ({ row, expandAll, level, callback }) => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if(expanded !== expandAll) {
      setExpanded(expandAll);
      if(expandAll !== open) setOpen(expandAll);
    }
  }, [expandAll]);

  const handleCollapse = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(!open);
  };

  return (
    <TableBody>
      <TableRow hover={true}  onClick={() => {
        dispatch(
          openModal({
            type: MODALS.Category,
            initialValues: row,
            callback: () => callback(),
          })
        );
      }}>
        <TableCell scope="row">
          {row.name}</TableCell>
        <TableCell align="right" >
          {row.children && row.children.length > 0 ? (
            <IconButton 
              aria-label="expand row"
              size="small"
              onClick={handleCollapse}
              sx={{ padding: 0 }}>
              {open ? <ExpandLess /> : <ExpandMore/>}
            </IconButton>
          ) : null}
        </TableCell>

      </TableRow>
        {row.children && row.children.length > 0 ? (
          <TableRow>
            <TableCell sx={{ padding: 0, border: 0 }} colSpan={2}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Table sx={{borderLeft: 15, borderRight: 15, borderTop: 5, borderBottom:5, borderColor: "grey.200"}}>
                  {row.children.map((subcategory) => (
                    <CategoryTableRow key={subcategory._id} row={subcategory} expandAll={expandAll} level={level+1} callback={callback}/>
                  ))}
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>

        ) : null}
    </TableBody>
  );
}

const Categories: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<ICategoryResponse[]>();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [expandAll, setExpandAll] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<ICategoryResponse[]>("/classifiers/categories/list", { params: {full: false} })
      .then((res) => {
        setResponse(getTreeData(res.data));
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getTreeData = (data: ICategoryResponse[]) => {
    data.forEach(row => {
      for (let ind = row.children.length - 1; ind >= 0; ind -= 1) {
        let subcategory = row.children[ind];

        if(row._id != subcategory.parent) {
          let i = row.children.findIndex(category => category._id == subcategory.parent);
          if (row.children[i].children === undefined) row.children[i].children = [];
          row.children[i].children.push(subcategory);
          row.children.splice(ind, 1);
        }
      }
    });

    return data;
  }

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Materiālu kategorijas</Typography>
        </Grid>
        <PermissionGuard permission={PERMISSIONS.WRITE_CATEGORIES}>
          {({ hasAccess }) => (
            <>
              {hasAccess ? (
                <Grid item>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      dispatch(
                        openModal({
                          type: MODALS.Category,
                          callback: () => loadData(),
                        })
                      );
                    }}
                  >
                    Pievienot
                  </Button>
                </Grid>
              ) : null}
            </>
          )}
        </PermissionGuard>
      </Grid>
      {isLoading ? (
        <Box height={100} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress color="primary" size={30} />
        </Box> 
        ) : ( <>
        <TableContainer sx={{border: 1, borderColor: 'grey.300',  borderRadius: 1}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nosaukums</TableCell>
                <TableCell align="right">
                  <Tooltip title={( expandAll ? 'Sakļaut' : 'Izvērst' ) + " visas"}>
                    <IconButton size="large" color="primary" sx={{padding: 0}} onClick={() => {setExpandAll(!expandAll)}}>
                      {expandAll ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            {response ? 
              (response?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <CategoryTableRow key={row._id} row={row} expandAll={expandAll} level={0} callback={loadData}/>
              ))) : 
              <TableBody><TableRow><TableCell align="center" colSpan={2}>No rows</TableCell></TableRow></TableBody>
            }
          </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={response?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </> )
      }
    </Stack>
  );
};

export default Categories;