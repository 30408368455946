import PERMISSIONS from "../constants/permissions";

import { TRoute, RouteType } from "../types/route";

import ProfileView from "../views/Profile";
import ClinicsView from "../views/Clinics";
import UserScheduleView from "../views/UserSchedule";

import WorkingDays from "../views/WorkingDays";
import Absences from "../views/Absences";
import ScheduleView from "../views/Schedule";

import ProstheticsView from "../views/Prosthetics";
import ProstheticsReport from "../views/ProstheticsReport";

import RoentgensView from "../views/Roentgens";
import RoentgensReport from "../views/RoentgensReport";

import AssistantsWorkload from "../views/AssistantsWorkload";
// import Overview from "../views/Overview";

import Holidays from "../views/Holidays";
import Users from "../views/Users";

import ApartmentsSchedule from "../views/ApartmentsSchedule";

import Warehouse from "../views/Warehouse";
import Cart from "../views/Cart";
import Orders from "../views/manage/orders/List";
import ReceiveOrder from "../views/manage/orders/View";

// manage
// classifiers
import Categories from "../views/manage/classifiers/Categories";
import Suppliers from "../views/manage/classifiers/Suppliers";
import Laboratories from "../views/manage/classifiers/Laboratories";
import AbsenceTypes from "../views/manage/classifiers/AbsenceTypes";
import Roentgens from "../views/manage/classifiers/Roentgens";
import Materials from "../views/manage/classifiers/Materials/List";
import MaterialsEdit from "../views/manage/classifiers/Materials/Edit";
import Apartments from "../views/manage/classifiers/Apartments";

// reports
import ProstheticsReports from "../views/reports/Prosthetics";
import TimesheetsReports from "../views/reports/Timesheets";
import RoentgensReports from "../views/reports/Roentgens";

import Layout from "../layouts/Layout";

import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import HomeIcon from "@mui/icons-material/Home";
import DateRangeIcon from "@mui/icons-material/DateRange";
import MoodIcon from "@mui/icons-material/Mood";
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import PeopleIcon from "@mui/icons-material/People";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
// import BarChartIcon from "@mui/icons-material/BarChart";
import ScaleIcon from "@mui/icons-material/Scale";
import SettingsIcon from "@mui/icons-material/Settings";
import BiotechIcon from "@mui/icons-material/Biotech";
import BarChartIcon from "@mui/icons-material/BarChart";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AlarmIcon from "@mui/icons-material/Alarm";
import CategoryIcon from "@mui/icons-material/Category";
import ApartmentIcon from "@mui/icons-material/Apartment";
import WarehouseIcon from "@mui/icons-material/Warehouse";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXRay, faBox, faTruck } from "@fortawesome/free-solid-svg-icons";

export const routes: TRoute[] = [
  {
    type: RouteType.Basic,
    path: "/",
    element: <Layout />,
    routes: [
      {
        type: RouteType.Menu,
        name: "Klīnikas",
        icon: <HomeIcon />,
        element: <ClinicsView />,
        index: true,
      },
      {
        type: RouteType.Menu,
        name: "Mans grafiks",
        path: "/user-schedule",
        icon: <PermContactCalendarIcon />,
        element: <UserScheduleView />,
        permission: [
          PERMISSIONS.READ_USER_SCHEDULE,
          PERMISSIONS.WRITE_TIMESHEET_EVENT,
        ],
      },
      {
        type: RouteType.Basic,
        path: "profile",
        element: <ProfileView />,
      },
      {
        type: RouteType.Basic,

        path: "/cart",
        element: <Cart />,
        permission: [PERMISSIONS.READ_BASKET, PERMISSIONS.WRITE_BASKET],
      },
      {
        type: RouteType.Menu,
        name: "Lietotāji",
        path: "/users",
        element: <Users />,
        icon: <PeopleIcon />,
        permission: PERMISSIONS.READ_USERS,
      },
      {
        type: RouteType.Menu,
        name: "Brīvdienas",
        path: "/holidays",
        element: <Holidays />,
        icon: <EmojiFoodBeverageIcon />,
        permission: PERMISSIONS.READ_HOLIDAYS,
      },
      {
        type: RouteType.Menu,
        name: "Prombūtnes",
        path: "/absences",
        element: <Absences />,
        icon: <CoronavirusIcon />,
        permission: [PERMISSIONS.READ_ABSENCES, PERMISSIONS.READ_ALL_ABSENCES],
      },
      {
        type: RouteType.Menu,
        name: "Pārvaldība",
        path: "/manage",
        icon: <SettingsIcon />,
        permission: [
          PERMISSIONS.READ_MANAGE_CATEGORIES,
          PERMISSIONS.READ_MANAGE_SUPPLIERS,
          PERMISSIONS.READ_MANAGE_LABORATORIES,
          PERMISSIONS.READ_MANAGE_ROENTGENS,
          PERMISSIONS.READ_MANAGE_ABSENCE_TYPES,
          PERMISSIONS.READ_APARTMENTS,
        ],
        routes: [
          {
            type: RouteType.Menu,
            name: "Materiālu kategorijas",
            path: "categories",
            element: <Categories />,
            icon: <CategoryIcon />,
            permission: PERMISSIONS.READ_MANAGE_CATEGORIES,
          },
          {
            type: RouteType.Menu,
            name: "Piegādātāji",
            path: "suppliers",
            element: <Suppliers />,
            icon: <FontAwesomeIcon icon={faTruck} size="xl" />,
            permission: PERMISSIONS.READ_MANAGE_SUPPLIERS,
          },
          {
            type: RouteType.Menu,
            name: "Materiali",
            path: "materials",
            element: <Materials />,
            icon: <FontAwesomeIcon icon={faBox} size="xl" />,
            permission: PERMISSIONS.READ_MATERIALS,
          },
          {
            type: RouteType.Menu,
            name: "Laboratorijas",
            path: "laboratories",
            element: <Laboratories />,
            icon: <BiotechIcon />,
            permission: PERMISSIONS.READ_MANAGE_LABORATORIES,
          },
          {
            type: RouteType.Menu,
            name: "Prombūtnes veidi",
            path: "absence-types",
            element: <AbsenceTypes />,
            icon: <CoronavirusIcon />,
            permission: PERMISSIONS.READ_MANAGE_ABSENCE_TYPES,
          },
          {
            type: RouteType.Menu,
            name: "Rentgeni",
            path: "roentgens",
            element: <Roentgens />,
            icon: <FontAwesomeIcon icon={faXRay} size="xl" />,
            permission: PERMISSIONS.READ_MANAGE_ROENTGENS,
          },
          {
            type: RouteType.Menu,
            name: "Dienesta dzīvokļi",
            path: "apartments",
            element: <Apartments />,
            icon: <ApartmentIcon />,
            permission: PERMISSIONS.READ_APARTMENTS,
          },
          {
            type: RouteType.Menu,
            name: "Pasūtījumi",
            path: "orders",
            element: <Orders />,
            icon: <ShoppingCartIcon />,
            permission: [PERMISSIONS.READ_ORDERS, PERMISSIONS.WRITE_ORDERS],
          },
        ],
      },
      {
        type: RouteType.Menu,
        name: "Atskaites",
        path: "/reports",
        icon: <BarChartIcon />,
        permission: [
          PERMISSIONS.READ_ANALYTICS_PROSTHETICS,
          PERMISSIONS.READ_ANALYTICS_TIMESHEET,
          PERMISSIONS.READ_ANALYTICS_ROENTGENS,
        ],
        routes: [
          {
            type: RouteType.Menu,
            name: "Protezēšanas",
            path: "prosthetics",
            element: <ProstheticsReports />,
            icon: <MoodIcon />,
            permission: PERMISSIONS.READ_ANALYTICS_PROSTHETICS,
          },
          {
            type: RouteType.Menu,
            name: "Noziņotās stundas",
            path: "timehseets",
            element: <TimesheetsReports />,
            icon: <AlarmIcon />,
            permission: PERMISSIONS.READ_ANALYTICS_TIMESHEET,
          },
          {
            type: RouteType.Menu,
            name: "Rentgeni",
            path: "roentgens",
            element: <RoentgensReports />,
            icon: <FontAwesomeIcon icon={faXRay} size="xl" />,
            permission: PERMISSIONS.READ_ANALYTICS_ROENTGENS,
          },
        ],
      },
      {
        type: RouteType.Divider,
      },
      {
        type: RouteType.Menu,
        name: "Darba dienas",
        path: "/:clinic/working-days",
        element: <WorkingDays />,
        icon: <FitnessCenterIcon />,
        permission: PERMISSIONS.READ_WORKING_DAYS,
      },
      {
        type: RouteType.Menu,
        name: "Asistentu noslodze",
        path: "/:clinic/assistatnts-workload",
        element: <AssistantsWorkload />,
        icon: <ScaleIcon />,
        permission: PERMISSIONS.READ_ASSISTANTS_WORKLOAD,
      },
      {
        type: RouteType.Menu,
        name: "Grafiks",
        path: "/:clinic/schedule",
        element: <ScheduleView />,
        icon: <DateRangeIcon />,
      },
      {
        type: RouteType.Menu,
        name: "Protezēšana",
        path: "/:clinic/prosthetics",
        element: <ProstheticsView />,
        icon: <MoodIcon />,
        permission: PERMISSIONS.WRITE_PROSTHETICS,
      },
      {
        type: RouteType.Basic,
        path: "/:clinic/prosthetics/:id",
        element: <ProstheticsReport />,
        permission: PERMISSIONS.WRITE_PROSTHETICS,
      },
      {
        type: RouteType.Menu,
        name: "Rentgeni",
        path: "/:clinic/roentgens",
        element: <RoentgensView />,
        icon: <FontAwesomeIcon icon={faXRay} size="xl" />,
        permission: PERMISSIONS.WRITE_ROENTGENS,
      },
      {
        type: RouteType.Basic,
        path: "/:clinic/roentgens/:id",
        element: <RoentgensReport />,
        // permission: PERMISSIONS.WRITE_PROSTHETICS,
      },
      {
        type: RouteType.Menu,
        name: "Dienesta dzīvokļi",
        path: "/:clinic/apartments",
        element: <ApartmentsSchedule />,
        icon: <ApartmentIcon />,
        permission: PERMISSIONS.READ_RESERVE_APARTMENTS,
      },
      {
        type: RouteType.Menu,
        name: "Noliktava",
        path: "/:clinic/warehouse",
        element: <Warehouse />,
        icon: <WarehouseIcon />,
        permission: PERMISSIONS.READ_WAREHOUSE,
      },
      {
        type: RouteType.Basic,
        path: "/manage/materials/:id",
        element: <MaterialsEdit />,
      },
      {
        type: RouteType.Basic,
        path: "/manage/orders/:id",
        element: <ReceiveOrder />,
      },

      // {
      //   type: RouteType.Menu,
      //   name: "Pārskats",
      //   path: "/:clinic/overview",
      //   element: Overview,
      //   icon: <BarChartIcon />,
      //   // permission: PERMISSIONS.WRITE_PROSTHETICS,
      // },
    ],
  },
];
