export enum OrderStatus {
  New = "new",
  Paid = "paid",
  Completed = "completed",
  Cancelled = "cancelled",
}

export interface IOrderStatus {
  label: string;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
}

type IOrderStatusesList = {
  [key in OrderStatus]: IOrderStatus;
};

export const ORDER_STATUSES: IOrderStatusesList = {
  [OrderStatus.New]: {
    label: "Pasūtīts",
    color: "info",
  },
  [OrderStatus.Paid]: {
    label: "Apmaksāts",
    color: "warning",
  },
  [OrderStatus.Completed]: {
    label: "Saņemts",
    color: "success",
  },
  [OrderStatus.Cancelled]: {
    label: "Atcelts",
    color: "error",
  },
};
