import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import MODALS from "../constants/modals";
import { IRoentgenReportRecordResponse, IRoentgenReportResponse } from "../types/roentgenReport";
import { useAppDispatch } from "../hooks/redux";
import { openModal } from "../features/modals";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { toast } from "react-toastify";

import {
  Stack,
  Grid,
  Typography,
  IconButton,
  Skeleton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

import { ReportStatuses } from "../constants/reportStatuses";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IRoentgenResponse } from "../types/classifiers";

const ViewRoentgensReport: React.FunctionComponent = () => {
  const { clinic, id } = useParams<{ clinic: string; id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState<IRoentgenReportResponse>();
  const [roentgens, setRoentgnes] = useState<IRoentgenResponse[]>([]);
  const [records, setRecords] = useState<IRoentgenReportRecordResponse[]>([]);

  const [columns, setColumns] = useState<GridColDef[]>([]);

  const [lastActiveId, setLastActiveId] = useState<string | null>(null);

  const handleReportClose = useCallback(() => {
    if (report) {
      dispatch(
        openModal({
          type: MODALS.Approve,
          title: "Aizvērt un nosūtīt atskaiti?",
          message:
            "Atskaiti vairs nebūs iespējams rediģēt un tā tiks nosūtīta uz gramatvediba@gimeneszobarstnieciba.lv",
          onApprove: () => {
            const toastId = toast.loading("Notiek atskaites sūtīšana...");
            axios
              .post<IRoentgenReportResponse>(`/roentgens/close/${report._id}`)
              .then((res) => {
                toast.update(toastId, {
                  render: "Atskaite veiksmīgi nosūtīta",
                  type: "success",
                  isLoading: false,
                  autoClose: 5000,
                  closeOnClick: true,
                });
                setReport(res.data);
              })
              .catch((err) => {
                toast.update(toastId, {
                  render: "Notika neparedzēta kļūda, atskaite, iespējams, netika nosūtīta",
                  type: "error",
                  isLoading: false,
                  autoClose: 5000,
                  closeOnClick: true,
                });
                console.log("error:", err);
              });
          },
        })
      );
    }
  }, [report]);

  const handleReportDelete = useCallback(() => {
    if (!report) {
      return;
    }
    dispatch(
      openModal({
        type: MODALS.Approve,
        title: "Vai tiešām vēlaties dzēst?",
        message: "Atskaite tiks neatgriezeniski dzēsta",
        onApprove: () => {
          axios
            .delete<IRoentgenReportRecordResponse>(`/roentgens/${report._id}`)
            .then((res) => {
              navigate(`/${clinic}/roentgens`);
            })
            .catch((err) => {
              console.log("error:", err);
            });
        },
      })
    );
  }, [report]);

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<{ report: IRoentgenReportResponse; records: IRoentgenReportRecordResponse[] }>(
        `/roentgens/${clinic}/${id}`
      )
      .then((res) => {
        setReport(res.data.report);
        setRecords(res.data.records);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clinic, id]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (lastActiveId) {
      setTimeout(() => {
        setLastActiveId(null);
      }, 1700);
    }
  }, [lastActiveId]);

  useEffect(() => {
    // setIsRoentgensLoading(true);
    // TODO: this should be in redux store and fetched only once
    axios
      .get<IRoentgenResponse[]>("/classifiers/roentgens", {
        params: {
          clinic,
        },
      })
      .then((res) => {
        setRoentgnes(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        // setIsRoentgensLoading(false);
      });
  }, [clinic]);

  useEffect(() => {
    const tmpColumns: GridColDef[] = [
      {
        field: "date",
        headerName: "Datums",
        type: "date",
        valueGetter: (params) =>
          params.row._id === "total" ? null : moment(params.row.date as Date).format("L"),
        sortable: false,
      },
      {
        field: "doctor",
        headerName: "Ārsts",
        sortable: false,
        renderCell: (params) => {
          if (params.row._id === "total") {
            return (
              <Stack direction="row" justifyContent="flex-end" flex={1}>
                <Typography>Kopā:</Typography>
              </Stack>
            );
          }

          return params.row.doctor.name;
        },
        flex: 1,
      },
      ...roentgens.map(
        (roentgen): GridColDef => ({
          field: `roentgens.${roentgen._id}`,
          headerName: roentgen.name,
          sortable: false,
          type: "number",
          valueGetter: (params) => params.row.roentgens[roentgen._id] || null,
        })
      ),
    ];

    setColumns(tmpColumns);
  }, [roentgens]);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item container alignItems="center" spacing={1} xs>
          <Grid item>
            <IconButton component={RouterLink} to={`/${clinic}/roentgens`}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item md>
            <Stack direction="row">
              <Typography variant="h4">
                Rentgenu atskaite:{" "}
                {!isLoading && report ? moment(report.date).format("MMMM, YYYY") : null}
              </Typography>
              {isLoading ? <Skeleton width={200} height={40} /> : null}
            </Stack>
          </Grid>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              startIcon={<AddIcon />}
              disabled={
                !Boolean(clinic) || !id || isLoading || report?.status !== ReportStatuses.Opened
              }
              onClick={() => {
                if (!report || !clinic) {
                  return;
                }

                dispatch(
                  openModal({
                    type: MODALS.RoentgenReportRecord,
                    report: report._id,
                    date: report.date,
                    clinic: clinic,
                    callback: loadData,
                  })
                );
              }}
            >
              Pievienot
            </LoadingButton>
            <IconButton aria-haspopup="true" onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  handleReportClose();
                }}
                disableRipple
              >
                <ListItemIcon>
                  <ForwardToInboxIcon />
                </ListItemIcon>
                <ListItemText>
                  {report?.status === ReportStatuses.Closed
                    ? "Nosūtīt atkārtoti"
                    : "Aizvērt un nosūtīt"}
                </ListItemText>
              </MenuItem>
              <MenuItem
                disabled={report?.status !== ReportStatuses.Opened}
                onClick={() => {
                  handleMenuClose();
                  handleReportDelete();
                }}
                disableRipple
              >
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText>Dzēst atskaiti</ListItemText>
              </MenuItem>
            </Menu>
          </Stack>
        </Grid>
      </Grid>

      <DataGrid
        sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
        loading={isLoading}
        getRowId={(row) => row._id}
        onRowClick={(params) => {
          if (
            !report ||
            !clinic ||
            params.row._id === "total" ||
            report.status !== ReportStatuses.Opened
          ) {
            return;
          }

          dispatch(
            openModal({
              type: MODALS.RoentgenReportRecord,
              report: report._id,
              date: report.date,
              clinic: clinic,
              record: params.row,
              callback: loadData,
            })
          );
        }}
        rows={[
          ...records,
          {
            _id: "total",
            date: "Kopā",
            doctor: {
              name: "",
              image: "",
            },
            roentgens: _.reduce(
              roentgens,
              (acc, roentgen) => {
                acc[roentgen._id] = _.sumBy(
                  records,
                  (record) => record.roentgens[roentgen._id] || 0
                );
                return acc;
              },
              {} as Record<string, number>
            ),
          },
        ]}
        columns={columns}
        hideFooterPagination
        autoHeight
        disableSelectionOnClick
        disableColumnMenu
        hideFooter
      />
    </Stack>
  );
};

export default ViewRoentgensReport;
