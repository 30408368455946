import { IconButton, Stack, Typography } from "@mui/material";
import moment from "moment";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TodayIcon from "@mui/icons-material/Today";

const CustomToolbar = (toolbar: any) => {
  const handleBack = () => {
    toolbar.onNavigate("PREV");
  };
  const handleNext = () => {
    toolbar.onNavigate("NEXT");
  };
  const handleCurrent = () => {
    toolbar.onNavigate("TODAY");
  };

  const ToolbarLabel = () => {
    const date = moment(toolbar.date);
    return (
      <Typography>
        {date.format("MMMM")} {date.format("YYYY")}
      </Typography>
    );
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack></Stack>
      <ToolbarLabel />
      <Stack direction="row" spacing={2}>
        <IconButton onClick={handleBack}>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton onClick={handleCurrent}>
          <TodayIcon />
        </IconButton>
        <IconButton onClick={handleNext}>
          <ChevronRightIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default CustomToolbar;
