import { useCallback, useState } from "react";

import { useDropzone } from "react-dropzone";

import {
  Card,
  CardActionArea,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";

import { faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface FileUploadProps {
  handleUpload: (acceptedFiles: File[]) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ handleUpload }) => {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      console.log("acceptedFiles:", acceptedFiles);
      if (acceptedFiles.length > 0) {
        //   setUploading(true);

        console.log("before call");
        handleUpload(acceptedFiles);
      }
    },
    [handleUpload]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop,
    // maxFiles: 1,
    accept: {
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
      "application/vnd.oasis.opendocument.text": [".odt"],
      "application/pdf": [".pdf"],
    },
    maxSize: 1024 * 1024 * 50, // 50MB
    disabled: uploading,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Card
        elevation={0}
        style={{
          borderWidth: 2,
          borderStyle: "dashed",
          borderColor: isDragActive
            ? isDragReject
              ? "rgba(255,0,0,0.5)"
              : "rgb(0, 255, 0)"
            : "rgba(0,0,0,0.5)",
        }}
      >
        <CardActionArea>
          <CardContent
            style={{
              height: 200,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Stack
              style={{ height: "100%" }}
              flex={1}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              {uploading ? (
                <LinearProgress
                  style={{ width: "100%" }}
                  variant="determinate"
                  value={uploadProgress}
                />
              ) : (
                [
                  <FontAwesomeIcon
                    key="icon"
                    icon={faFileCirclePlus}
                    size="5x"
                  />,
                  <Typography
                    key="text"
                    variant="caption"
                    textAlign="center"
                    style={{
                      marginTop: 0,
                    }}
                  >
                    {isDragActive
                      ? "Nometiet failu šeit"
                      : "Noklikšķiniet vai velciet failus šeit"}
                  </Typography>,
                ]
              )}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default FileUpload;
