import * as yup from "yup";

const roentgenReportRecordSchema = yup.object({
  date: yup.date().required(),
  doctor: yup.string().required(),
  roentgens: yup.lazy((value) => {
    const newEntries = Object.keys(value).reduce(
      (acc, val) => ({
        ...acc,
        [val]: yup.number(),
      }),
      {}
    );

    return yup.object().shape(newEntries);
  }),
});

export type RoentgenReportRecordBase = Omit<
  yup.Asserts<typeof roentgenReportRecordSchema>,
  "roentgens"
> & {
  roentgens: {
    [key: string]: number;
  };
};

export default roentgenReportRecordSchema;
