import * as yup from "yup";

const absenceTypeSchema = yup.object({
  name: yup.string().required(),
  abbreviation: yup.string().required().max(3),
});

export type AbsenceTypeBase = yup.InferType<typeof absenceTypeSchema>;

export default absenceTypeSchema;
