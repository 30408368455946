enum PERMISSIONS {
  READ_CLINICS = "read:clinics",
  WRITE_CLINICS = "write:clinics",
  DELETE_CLINICS = "delete:clinics",
  READ_CHAIRS = "read:chairs",
  WRITE_CHAIRS = "write:chairs",
  DELETE_CHAIRS = "delete:chairs",
  WRITE_SCHEDULE = "write:schedule",
  WRITE_PROSTHETICS = "write:prosthetics",
  READ_HOLIDAYS = "read:holidays",
  WRITE_HOLIDAYS = "write:holidays",
  READ_USERS = "read:users",
  WRITE_USERS = "write:users",
  SYNC_USERS = "sync:users",
  READ_WORKING_DAYS = "read:working_days",
  WRITE_WORKING_DAYS = "write:working_days",
  READ_ASSISTANTS_WORKLOAD = "read:assistants_workload",
  READ_ANALYTICS_PROSTHETICS = "read:analytics_prosthetics",
  READ_USER_SCHEDULE = "read:user_schedule",

  // Suppliers
  READ_MANAGE_SUPPLIERS = "read:manage_suppliers",
  WRITE_SUPPLIERS = "write:suppliers",
  DELETE_SUPPLIERS = "delete:suppliers",

  // Timesheet
  READ_ANALYTICS_TIMESHEET = "read:analytics_timesheet",
  WRITE_TIMESHEET = "write:timesheet",
  WRITE_TIMESHEET_EVENT = "write:timesheet_event",
  READ_TIMESHEET = "read:timesheet",

  // Laboratories
  READ_MANAGE_LABORATORIES = "read:manage_laboratories",
  WRITE_LABORATORIES = "write:laboratories",
  DELETE_LABORATORIES = "delete:laboratories",

  // Absence types
  READ_MANAGE_ABSENCE_TYPES = "read:manage_absence_types",
  WRITE_ABSENCE_TYPES = "write:absence_types",
  DELETE_ABSENCE_TYPES = "delete:absence_types",

  // Roentgens
  READ_ANALYTICS_ROENTGENS = "read:analytics_roentgens",
  READ_MANAGE_ROENTGENS = "read:manage_roentgens",
  WRITE_ROENTGENS = "write:roentgens",
  DELETE_ROENTGENS = "delete:roentgens",

  // Absences
  READ_ABSENCES = "read:absences",
  WRITE_ABSENCES = "write:absences",
  REQUEST_ABSENCES = "request:absences",
  APPROVE_ABSENCES = "approve:absences",
  READ_ALL_ABSENCES = "read:absences:all",
  DELETE_ABSENCES = "delete:absences",

  // Material categories
  READ_MANAGE_CATEGORIES = "read:manage_categories",
  WRITE_CATEGORIES = "write:categories",
  DELETE_CATEGORIES = "delete:categories",

  // Materials
  READ_MATERIALS = "read:materials",
  WRITE_MATERIALS = "write:materials",
  DELETE_MATERIALS = "delete:materials",

  // Apartments
  READ_APARTMENTS = "read:apartments",
  WRITE_APARTMENTS = "write:apartments",
  DELETE_APARTMENTS = "delete:apartments",

  // Apartment reservations
  READ_RESERVE_APARTMENTS = "read:reserve_apartments",
  WRITE_RESERVE_APARTMENTS = "write:reserve_apartments",
  DELETE_RESERVE_APARTMENTS = "delete:reserve_apartments",
  WRITE_ALL_RESERVE_APARTMENTS = "write:reserve_apartments:all",
  DELETE_ALL_RESERVE_APARTMENTS = "delete:reserve_apartments:all",

  // Orders
  READ_ORDERS = "read:orders",
  WRITE_ORDERS = "write:orders",
  DELETE_ORDERS = "delete:orders",

  // Basket
  READ_BASKET = "read:basket",
  WRITE_BASKET = "write:basket",
  DELETE_BASKET = "delete:basket",

  // Warehouse
  READ_WAREHOUSE = "read:warehouse",
  WRITE_WAREHOUSE = "write:warehouse",
  DELETE_WAREHOUSE = "delete:warehouse",
}

export default PERMISSIONS;
