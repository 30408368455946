import "react-big-calendar/lib/css/react-big-calendar.css";

import { useCallback, useEffect, useState } from "react";

import MODALS from "../constants/modals";
import { ITimesheetEventResponse, TimesheetResponse } from "../types/timesheet";
import { IUserScheduleResponse, IScheduleEvent } from "../types/user";
import { IAbsenceResponse } from "../types/absence";

import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { useAppDispatch } from "../hooks/redux";
import { openModal } from "../features/modals";

import { Grid, Typography, Stack, IconButton, Box, Autocomplete, TextField } from "@mui/material";

import { Calendar, Event, momentLocalizer } from "react-big-calendar";
import { usePermissions } from "../hooks/permissions";
import PERMISSIONS from "../constants/permissions";
import { ChairTypes } from "../constants/chairTypes";
import { ABSENCES_STATUSES, AbsenceStatuses } from "../constants/absenceStatuses";
import { IHolidayResponse } from "../types/holiday";
import CustomToolbar from "../components/CalendarCustomToolbar";
import { IApartmentResponse } from "../types/classifiers";
import { useParams } from "react-router";
import { IApartmentReservationResponse } from "../types/apartmentReservation";
import ColorHash from "color-hash";

const ApartmentsSchedule: React.FunctionComponent<{}> = () => {
  var colorHash = new ColorHash();

  const { clinic } = useParams<{ clinic: string }>();

  const dispatch = useAppDispatch();
  const { hasPermission } = usePermissions();

  const [apartemnts, setApartments] = useState<IApartmentResponse[]>([]);
  const [selectedApartment, setSelectedApartment] = useState<IApartmentResponse>();

  const [reservations, setReservations] = useState<Event[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState<Date>(moment().toDate());

  const loadData = useCallback(() => {
    if (!selectedApartment) {
      return;
    }
    setIsLoading(true);

    axios
      .get<IApartmentReservationResponse[]>(`/apartments/${selectedApartment._id}/reservations`, {
        params: {
          date: date,
        },
      })
      .then((res) => {
        setReservations(
          res.data.map((r) => ({
            start: new Date(r.from),
            end: new Date(r.to),
            allDay: true,
            title: r.user.name,
            resource: r,
          }))
        );
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [date, selectedApartment]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!clinic) {
      return;
    }

    axios.get<IApartmentResponse[]>(`/classifiers/apartments/clinic/${clinic}`).then((res) => {
      setApartments(res.data);
      setSelectedApartment(res.data[0]);
    });
  }, [clinic]);

  const localizer = momentLocalizer(moment);
  const onNavigate = useCallback((newDate: Date) => setDate(newDate), [setDate]);

  if (!clinic) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Dienesta dzīvokļu grafiks</Typography>
        </Grid>
        <Grid item>
          <Autocomplete
            options={apartemnts}
            style={{ width: 300 }}
            value={selectedApartment || null}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Dienesta dzīvoklis" />}
            onChange={(e, value) => {
              setSelectedApartment(value || undefined);
            }}
            size="small"
          />
        </Grid>
      </Grid>
      <Box sx={{ height: 800 }}>
        <Calendar
          date={date}
          events={reservations}
          localizer={localizer}
          views={["month", "week"]}
          drilldownView={null}
          onNavigate={onNavigate}
          components={{
            toolbar: CustomToolbar,
          }}
          onSelectEvent={(event) => {
            if (!selectedApartment) {
              return;
            }

            dispatch(
              openModal({
                type: MODALS.ReserveApartment,
                apartment: selectedApartment,
                clinic: clinic,
                initialValues: event.resource,
                callback: () => {
                  loadData();
                },
              })
            );
          }}
          selectable
          onSelectSlot={(slotInfo) => {
            if (!selectedApartment) {
              return;
            }

            if (!hasPermission(PERMISSIONS.WRITE_RESERVE_APARTMENTS)) {
              return;
            }

            dispatch(
              openModal({
                type: MODALS.ReserveApartment,
                apartment: selectedApartment,
                clinic: clinic,
                initialValues: {
                  from: slotInfo.start,
                  to: moment(slotInfo.end).subtract(1, "day").endOf("day").toDate(),
                },
                callback: () => {
                  loadData();
                },
              })
            );
          }}
          popup
          eventPropGetter={(event) => {
            return {
              style: {
                cursor: "pointer",
                backgroundColor: colorHash.hex(event.resource.user._id),
              },
            };
          }}
        />
      </Box>
    </Stack>
  );
};

export default ApartmentsSchedule;
