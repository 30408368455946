import React, { useCallback, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import _ from "lodash";
import ColorHash from "color-hash";

import { Stack } from "@mui/material";
import moment, { Moment } from "moment";
import axios from "axios";
import { IProstheticReportChart } from "../../../types/prostheticReport";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Summa pēc labor. izd. atskaitīšanas",
    },
  },
};

let monthLabels: Date[] = [];

const startDate = moment().subtract(1, "year").startOf("month");
const endDate = moment();
let currentDate = startDate.clone();
while (currentDate.isSameOrBefore(endDate)) {
  monthLabels.push(currentDate.toDate());
  currentDate.add(1, "month");
}

const ProstheticReportCharts: React.FunctionComponent<{}> = () => {
  var colorHash = new ColorHash();

  const [data, setData] = React.useState<IProstheticReportChart[]>([]);

  const loadData = useCallback(() => {
    axios.get("/prosthetics/reports/analytics").then((res) => {
      setData(res.data);
    });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Stack>
      <Bar
        options={options}
        data={{
          labels: monthLabels.map((month) => moment(month).format("MMMM")),
          datasets: data.map((item) => {
            return {
              label: item.clinic.name,
              data: monthLabels.map((month) => {
                const monthData = item.data.find(
                  (record) =>
                    moment(month).month() + 1 === record.month &&
                    moment(month).year() === record.year
                );

                return monthData ? monthData.total - monthData.laboratoryPrice : 0;
              }),
              backgroundColor: colorHash.hex(item.clinic._id),
            };
          }),
        }}
      />
    </Stack>
  );
};

export default ProstheticReportCharts;
