import { IWarehouseTakeItemsProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import * as yup from "yup";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  TextField,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { NumericFormat } from "react-number-format";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useCallback, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const getSchema = (max: number) =>
  yup.object().shape({
    count: yup.number().max(max).required(),
  });

const WarehouseTakeItemsModal: React.FunctionComponent<
  IWarehouseTakeItemsProps
> = ({ id, material, clinic, callback }) => {
  const dispatch = useAppDispatch();
  const max = material.statuses.reduce((acc, curr) => {
    if (curr._id === "inStock") {
      return acc + curr.count;
    }
    return acc;
  }, 0);
  const schema = getSchema(max);

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      count: 1,
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(async (values: yup.InferType<typeof schema>) => {
    try {
      setIsLoading(true);

      await axios.post(
        `/clinics/${clinic}/warehouse/take/${material._id}`,
        values
      );

      toast.success("Materiāli paņemti");

      setIsLoading(false);

      callback();
      handleClose();
    } catch (error) {
      toast.error("Neizdevās paņemt materiālus");
    }
  }, []);

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={true} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Paņemt materiālus</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              id="material"
              label="Materiāls"
              value={material.name}
              disabled
            />
            <TextField
              id="category"
              label="Kategorija"
              fullWidth
              value={material.category.name}
              disabled
            />

            <Controller
              name="count"
              control={control}
              render={({ field }) => (
                <NumericFormat
                  {...field}
                  customInput={TextField}
                  min={1}
                  max={max}
                  InputProps={{
                    startAdornment: (
                      <Button
                        size="large"
                        disabled={field.value === 1}
                        onClick={() => {
                          setValue("count", field.value - 1);
                        }}
                      >
                        -
                      </Button>
                    ),
                    endAdornment: (
                      <Button
                        size="large"
                        disabled={field.value >= max}
                        onClick={() => {
                          setValue("count", field.value + 1);
                        }}
                      >
                        +
                      </Button>
                    ),
                  }}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  allowNegative={false}
                  fixedDecimalScale
                  placeholder="Daudzums"
                  label="Daudzums"
                  fullWidth
                  error={Boolean(errors.count)}
                  helperText={errors.count?.message}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Aizvērt</Button>

          <LoadingButton loading={isLoading} type="submit" variant="contained">
            Paņemt
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default WarehouseTakeItemsModal;
