import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

import {
  Grid,
  Typography,
  Stack,
  IconButton,
  Avatar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Autocomplete,
  Popover,
  TextField,
  Badge,
} from "@mui/material";

import _ from "lodash";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";

import { IAllProstheticsReportsResponse } from "../../../types/prostheticReport";
import { StyledTableFooterRow, StyledTableRow } from "../../ProstheticsReport";
import { ReportStatuses, REPORT_STATUSES } from "../../../constants/reportStatuses";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { IUserBaseResponse } from "../../../types/user";
import { ClinicResponse } from "../../../types/clinic";
import { ILaboratoryResponse } from "../../../types/classifiers";
import ROLES from "../../../constants/roles";
import { useDebouncedCallback } from "use-debounce";

import ProstheticReportCharts from "./Charts";

const ProstheticsReports: React.FunctionComponent<{}> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState<Date>(moment().toDate());
  const [records, setRecords] = useState<IAllProstheticsReportsResponse[]>([]);

  const [filterValues, setFilterValues] = useState<IFilterValues>({});
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFiltersAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFiltersAnchorEl(null);
  };

  const handleFilterChange = useCallback((values: IFilterValues) => {
    setFilterValues(values);
  }, []);

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IAllProstheticsReportsResponse[]>(`/prosthetics/reports`, {
        params: { date: date, filters: filterValues },
      })
      .then((response) => {
        setRecords(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
        setRecords([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [date, filterValues]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Protezēšanas</Typography>
        </Grid>
      </Grid>
      <ProstheticReportCharts />
      <Typography variant="h5">Pārskats</Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton
            onClick={() =>
              setDate((currentDate) => moment(currentDate).subtract(1, "month").toDate())
            }
          >
            <ChevronLeftIcon />
          </IconButton>
          <Typography>
            {moment(date).startOf("month").format("LL")} -{" "}
            {moment(date).endOf("month").format("LL")}
          </Typography>
          <IconButton
            onClick={() => setDate((currentDate) => moment(currentDate).add(1, "month").toDate())}
          >
            <ChevronRightIcon />
          </IconButton>
        </Stack>

        <IconButton onClick={handleFilterClick}>
          <Badge
            color="secondary"
            badgeContent={Object.values(filterValues).filter((item) => item).length}
          >
            <FilterAltIcon />
          </Badge>
        </IconButton>
      </Stack>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ārsts</TableCell>
              <TableCell>Klinika</TableCell>
              <TableCell>Statuss</TableCell>
              <TableCell>Pacients</TableCell>
              <TableCell>Zobu tehniķis/ laboratorija</TableCell>
              <TableCell align="right">Laboratorijas cena</TableCell>
              <TableCell align="right">Summa par protezēšanu</TableCell>
              <TableCell align="right">Summa pēc labor. izd. atskaitīšanas</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record) => {
              return (
                <StyledTableRow key={record.record._id}>
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar src={record.user?.image} />
                      <Typography>{record.user?.name}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>{record.clinic?.name}</TableCell>
                  <TableCell>
                    <Chip
                      label={REPORT_STATUSES[record.status].label}
                      variant={record.status === ReportStatuses.Opened ? "filled" : "outlined"}
                      size="small"
                      color={record.status === ReportStatuses.Opened ? "success" : "default"}
                    />
                  </TableCell>
                  <TableCell>{record.record.patient}</TableCell>
                  <TableCell>{record.record.laboratory?.name}</TableCell>
                  <TableCell align="right">{record.record.laboratoryPrice?.toFixed(2)}</TableCell>
                  <TableCell align="right">{record.record.price?.toFixed(2)}</TableCell>
                  <TableCell>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center">
                      <Typography>
                        {_.round(
                          (record.record.price ? record.record.price : 0) -
                            (record.record.laboratoryPrice ? record.record.laboratoryPrice : 0),
                          2
                        ).toFixed(2)}
                      </Typography>
                    </Stack>
                  </TableCell>
                </StyledTableRow>
              );
            })}
            <StyledTableFooterRow>
              <TableCell align="right" colSpan={5}>
                KOPĀ
              </TableCell>
              <TableCell align="right">
                {_.round(
                  _.sumBy(records, (record) =>
                    record.record.laboratoryPrice ? record.record.laboratoryPrice : 0
                  ),
                  2
                ).toFixed(2)}
              </TableCell>
              <TableCell align="right">
                {_.round(
                  _.sumBy(records, (record) => (record.record.price ? record.record.price : 0)),
                  2
                ).toFixed(2)}
              </TableCell>
              <TableCell align="right">
                {_.round(
                  _.sumBy(
                    records,
                    (record) =>
                      (record.record.price ? record.record.price : 0) -
                      (record.record.laboratoryPrice ? record.record.laboratoryPrice : 0)
                  ),
                  2
                ).toFixed(2)}
              </TableCell>
            </StyledTableFooterRow>
            <StyledTableFooterRow>
              <TableCell align="right" colSpan={7}>
                Apmaksai 40%
              </TableCell>
              <TableCell align="right">
                {_.round(
                  _.sumBy(
                    records,
                    (record) =>
                      (record.record.price ? record.record.price : 0) -
                      (record.record.laboratoryPrice ? record.record.laboratoryPrice : 0)
                  ) * 0.4,
                  2
                )}
              </TableCell>
            </StyledTableFooterRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Popover
        open={Boolean(filtersAnchorEl)}
        anchorEl={filtersAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
      >
        <Filters values={filterValues} onFilterChange={handleFilterChange} />
      </Popover>
    </Stack>
  );
};

interface IFilterValues {
  search?: string;
  user?: string;
  clinic?: string;
  laboratory?: string;
}

interface IFIlterProps {
  values: IFilterValues;
  onFilterChange: (values: IFilterValues) => void;
}

const Filters: React.FunctionComponent<IFIlterProps> = ({ values, onFilterChange }) => {
  const [users, setUsers] = useState<IUserBaseResponse[]>([]);
  const [clinics, setClinics] = useState<ClinicResponse[]>([]);
  const [laboratories, setLaboratories] = useState<ILaboratoryResponse[]>([]);

  const [patient, setPatient] = useState<string | undefined>(values.search);

  const debounced = useDebouncedCallback(() => {
    onFilterChange({
      ...values,
      search: patient,
    });
  }, 800);

  useEffect(() => {
    debounced();
  }, [patient]);

  useEffect(() => {
    axios
      .get<IUserBaseResponse[]>(`/users/list`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
      });

    axios
      .get<ClinicResponse[]>("/clinics")
      .then((res) => {
        setClinics(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });

    axios
      .get<ILaboratoryResponse[]>("/classifiers/laboratories")
      .then((res) => {
        setLaboratories(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  useEffect(() => {
    onFilterChange(values);
  }, [values]);

  return (
    <Stack spacing={2} sx={{ padding: 2, width: 400, maxWidth: "100%" }}>
      <Typography variant="h6">Filtri</Typography>
      <TextField
        label="Pacients"
        value={patient ? patient : ""}
        onChange={(e) => setPatient(e.target.value)}
        inputProps={{
          maxLength: 50,
        }}
        InputProps={{
          endAdornment: patient ? (
            <IconButton size="small" onClick={() => setPatient(undefined)}>
              <ClearIcon fontSize="small" />
            </IconButton>
          ) : undefined,
        }}
      />
      <Autocomplete
        options={users.filter((user) => _.some(user.roles, (role) => ROLES.Doctor === role))}
        value={users ? users.find((item) => item._id === values.user) : null}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Ārsts" />}
        renderOption={(props, option) => {
          return (
            <Stack
              {...props}
              component="li"
              direction="row"
              spacing={1}
              alignItems="center"
              key={option._id}
            >
              <Avatar alt={option.name} src={option.image} sx={{ width: 24, height: 24 }} />
              <Typography>{option.name}</Typography>
            </Stack>
          );
        }}
        onChange={(e, value) => {
          onFilterChange({
            ...values,
            user: value ? value._id : undefined,
          });
        }}
      />
      <Autocomplete
        options={clinics}
        value={clinics ? clinics.find((item) => item._id === values.clinic) : null}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Klīnika" />}
        onChange={(e, value) => {
          onFilterChange({
            ...values,
            clinic: value ? value._id : undefined,
          });
        }}
      />
      <Autocomplete
        options={laboratories}
        value={laboratories ? laboratories.find((item) => item._id === values.laboratory) : null}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Zobu tehniķis/ laboratorija" />}
        onChange={(e, value) => {
          onFilterChange({
            ...values,
            laboratory: value ? value._id : undefined,
          });
        }}
      />
    </Stack>
  );
};

export default ProstheticsReports;
