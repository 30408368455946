import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import MODALS from "../constants/modals";
import {
  IProstheticReportRecordResponse,
  IProstheticReportResponse,
} from "../types/prostheticReport";
import { useAppDispatch } from "../hooks/redux";
import { openModal } from "../features/modals";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { toast } from "react-toastify";

import {
  Stack,
  Grid,
  Typography,
  IconButton,
  Skeleton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  TableRowProps,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { alpha, styled } from "@mui/material/styles";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import UndoIcon from "@mui/icons-material/Undo";

import { ReportStatuses } from "../constants/reportStatuses";

export const StyledTableRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
  transition: "background-color 0.7s linear",
  "&.changed": {
    backgroundColor: alpha(theme.palette.success.light, 0.2),
    "&:hover": {
      backgroundColor: alpha(theme.palette.success.light, 0.4),
    },
  },
}));

export const StyledTableFooterRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  "& .MuiTableCell-root": {
    fontWeight: 700,
  },
  "& :last-child": {
    fontWeight: 900,
    backgroundColor: alpha(theme.palette.primary.light, 0.2),
  },
}));

const ViewProstheticsReport: React.FunctionComponent = () => {
  const { clinic, id } = useParams<{ clinic: string; id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState<IProstheticReportResponse>();
  const [records, setRecords] = useState<IProstheticReportRecordResponse[]>([]);

  const [hoveredId, setHoveredId] = useState<string | null>(null);
  const [lastActiveId, setLastActiveId] = useState<string | null>(null);

  const handleReportClose = useCallback(() => {
    if (report) {
      dispatch(
        openModal({
          type: MODALS.Approve,
          title: "Aizvērt un nosūtīt atskaiti?",
          message:
            "Atskaiti vairs nebūs iespējams rediģēt un tā tiks nosūtīta uz gramatvediba@gimeneszobarstnieciba.lv",
          onApprove: () => {
            const toastId = toast.loading("Notiek atskaites sūtīšana...");
            axios
              .post<IProstheticReportResponse>(`/prosthetics/close/${report._id}`)
              .then((res) => {
                toast.update(toastId, {
                  render: "Atskaite veiksmīgi nosūtīta",
                  type: "success",
                  isLoading: false,
                  autoClose: 5000,
                  closeOnClick: true,
                });
                setReport(res.data);
              })
              .catch((err) => {
                toast.update(toastId, {
                  render: "Notika neparedzēta kļūda, atskaite, iespējams, netika nosūtīta",
                  type: "error",
                  isLoading: false,
                  autoClose: 5000,
                  closeOnClick: true,
                });
                console.log("error:", err);
              });
          },
        })
      );
    }
  }, [report]);

  const handleReportDelete = useCallback(() => {
    if (report) {
      dispatch(
        openModal({
          type: MODALS.Approve,
          title: "Vai tiešām vēlaties dzēst?",
          message: "Atskaite tiks neatgriezeniski dzēsta",
          onApprove: () => {
            axios
              .delete<IProstheticReportRecordResponse>(`/prosthetics/${report._id}`)
              .then((res) => {
                navigate(`/${clinic}/prosthetics`);
              })
              .catch((err) => {
                console.log("error:", err);
              });
          },
        })
      );
    }
  }, [report]);

  const handleRecordDelete = useCallback(
    (recordId: string) => {
      if (report) {
        axios
          .delete<IProstheticReportRecordResponse>(`/prosthetics/${report._id}/${recordId}`)
          .then((res) => {
            setRecords((e) => e.filter((record) => record._id !== recordId));

            const toastId = toast.success(
              () => (
                <Stack
                  direction="row"
                  style={{ width: "100%" }}
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Typography>Ieraksts veiksmīgi dzēsts</Typography>
                  <IconButton
                    size="small"
                    onClick={() => {
                      toast.update(toastId, {
                        render: "Atsauc izmaiņas...",
                        isLoading: true,
                        closeOnClick: false,
                        autoClose: false,
                        draggable: false,
                      });

                      axios
                        .put<IProstheticReportRecordResponse>(
                          `/prosthetics/${report._id}`,
                          res.data
                        )
                        .then((res) => {
                          setRecords((e) => [...e, res.data]);
                          setLastActiveId(res.data._id);
                          toast.update(toastId, {
                            render: "Ieraksts veiksmīgi atgriezts",
                            type: "success",
                            isLoading: false,
                            closeOnClick: true,
                            draggable: true,
                            autoClose: 5000,
                          });
                        })
                        .catch((err) => {
                          toast.update(toastId, {
                            render: "Notika neparedzēta kļūda",
                            type: "error",
                            isLoading: false,
                            closeOnClick: true,
                            draggable: true,
                            autoClose: 5000,
                          });
                          console.log("error:", err);
                        });
                    }}
                  >
                    <UndoIcon />
                  </IconButton>
                </Stack>
              ),
              {
                closeOnClick: false,
              }
            );
          })
          .catch((err) => {
            console.log("error:", err);
          });
      }
    },
    [report]
  );

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<{ report: IProstheticReportResponse; records: IProstheticReportRecordResponse[] }>(
        `/prosthetics/${clinic}/${id}`
      )
      .then((res) => {
        setReport(res.data.report);
        setRecords(res.data.records);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clinic]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (lastActiveId) {
      setTimeout(() => {
        setLastActiveId(null);
      }, 1700);
    }
  }, [lastActiveId]);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item container alignItems="center" spacing={1} xs>
          <Grid item>
            <IconButton component={RouterLink} to={`/${clinic}/prosthetics`}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item md>
            <Stack direction="row">
              <Typography variant="h4">
                Protezēšanas atskaite:{" "}
                {!isLoading && report ? moment(report.date).format("MMMM, YYYY") : null}
              </Typography>
              {isLoading ? <Skeleton width={200} height={40} /> : null}
            </Stack>
          </Grid>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              startIcon={<AddIcon />}
              disabled={
                !Boolean(clinic) || !id || isLoading || report?.status !== ReportStatuses.Opened
              }
              onClick={() => {
                if (report) {
                  dispatch(
                    openModal({
                      type: MODALS.ProstheticReportRecord,
                      report: report._id,
                      callback: (newRecord) => {
                        setRecords((e) => [...e, newRecord]);
                        setLastActiveId(newRecord._id);
                      },
                    })
                  );
                }
              }}
            >
              Pievienot
            </LoadingButton>
            <IconButton aria-haspopup="true" onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  handleReportClose();
                }}
                disableRipple
              >
                <ListItemIcon>
                  <ForwardToInboxIcon />
                </ListItemIcon>
                <ListItemText>
                  {report?.status === ReportStatuses.Closed
                    ? "Nosūtīt atkārtoti"
                    : "Aizvērt un nosūtīt"}
                </ListItemText>
              </MenuItem>
              <MenuItem
                disabled={report?.status !== ReportStatuses.Opened}
                onClick={() => {
                  handleMenuClose();
                  handleReportDelete();
                }}
                disableRipple
              >
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText>Dzēst atskaiti</ListItemText>
              </MenuItem>
            </Menu>
          </Stack>
        </Grid>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pacients</TableCell>
              <TableCell>Zobu tehniķis/ laboratorija</TableCell>
              <TableCell align="right">Laboratorijas cena</TableCell>
              <TableCell align="right">Summa par protezēšanu</TableCell>
              <TableCell align="right">Summa pēc labor. izd. atskaitīšanas</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record) => (
              <StyledTableRow
                className={record._id === lastActiveId ? "changed" : undefined}
                key={record._id}
                hover={report?.status === ReportStatuses.Opened}
                onMouseEnter={() => setHoveredId(record._id)}
                onMouseLeave={() => setHoveredId(null)}
              >
                <TableCell>{record.patient}</TableCell>
                <TableCell>{record.laboratory?.name}</TableCell>
                <TableCell align="right">{record.laboratoryPrice?.toFixed(2)}</TableCell>
                <TableCell align="right">{record.price?.toFixed(2)}</TableCell>
                <TableCell>
                  <Stack direction="row" justifyContent="flex-end" alignItems="center">
                    <Typography>
                      {_.round(
                        (record.price ? record.price : 0) -
                          (record.laboratoryPrice ? record.laboratoryPrice : 0),
                        2
                      ).toFixed(2)}
                    </Typography>
                    <Collapse
                      orientation="horizontal"
                      sx={{ paddingLeft: 1 }}
                      in={hoveredId === record._id && report?.status === ReportStatuses.Opened}
                    >
                      <Stack direction="row">
                        <IconButton
                          onClick={() => {
                            if (report) {
                              dispatch(
                                openModal({
                                  type: MODALS.ProstheticReportRecord,
                                  report: report._id,
                                  record,
                                  callback: (newRecord) => {
                                    setRecords((e) =>
                                      e.map((record) =>
                                        record._id === newRecord._id ? newRecord : record
                                      )
                                    );
                                    setLastActiveId(newRecord._id);
                                  },
                                })
                              );
                            }
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            handleRecordDelete(record._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </Collapse>
                  </Stack>
                </TableCell>
              </StyledTableRow>
            ))}
            <StyledTableFooterRow>
              <TableCell align="right" colSpan={2}>
                KOPĀ
              </TableCell>
              <TableCell align="right">
                {_.round(
                  _.sumBy(records, (record) =>
                    record.laboratoryPrice ? record.laboratoryPrice : 0
                  ),
                  2
                ).toFixed(2)}
              </TableCell>
              <TableCell align="right">
                {_.round(
                  _.sumBy(records, (record) => (record.price ? record.price : 0)),
                  2
                ).toFixed(2)}
              </TableCell>
              <TableCell align="right">
                {_.round(
                  _.sumBy(
                    records,
                    (record) =>
                      (record.price ? record.price : 0) -
                      (record.laboratoryPrice ? record.laboratoryPrice : 0)
                  ),
                  2
                ).toFixed(2)}
              </TableCell>
            </StyledTableFooterRow>
            <StyledTableFooterRow>
              <TableCell align="right" colSpan={4}>
                Apmaksai 40%
              </TableCell>
              <TableCell align="right">
                {_.round(
                  _.sumBy(
                    records,
                    (record) =>
                      (record.price ? record.price : 0) -
                      (record.laboratoryPrice ? record.laboratoryPrice : 0)
                  ) * 0.4,
                  2
                )}
              </TableCell>
            </StyledTableFooterRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ViewProstheticsReport;
