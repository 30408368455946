import { RouteProps } from "react-router";
import PERMISSIONS from "../constants/permissions";

export enum RouteType {
  Menu = "Menu",
  Basic = "Basic",
  Divider = "Divider",
}

export type RouteBase = RouteProps & {
  // path: string;
  // element: React.ReactNode;
  permission?: PERMISSIONS | PERMISSIONS[];
  routes?: TRoute[];
};

export type MenuRoute = RouteBase & {
  type: RouteType.Menu;
  name: string;
  icon: JSX.Element;
};

export type BasicRoute = RouteBase & {
  type: RouteType.Basic;
};

export interface IMenuDivider {
  type: RouteType.Divider;
}

export type TRoute = MenuRoute | BasicRoute | IMenuDivider;
