import "react-big-calendar/lib/css/react-big-calendar.css";

import { useCallback, useEffect, useState } from "react";

import MODALS from "../constants/modals";
import { ITimesheetEventResponse, TimesheetResponse } from "../types/timesheet";
import { IUserScheduleResponse, IScheduleEvent } from "../types/user";
import { IAbsenceResponse } from "../types/absence";

import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { useAppDispatch } from "../hooks/redux";
import { openModal } from "../features/modals";

import { Grid, Typography, Stack, IconButton, Box } from "@mui/material";

import { Calendar, Event, momentLocalizer } from "react-big-calendar";
import { usePermissions } from "../hooks/permissions";
import PERMISSIONS from "../constants/permissions";
import { ChairTypes } from "../constants/chairTypes";
import { ABSENCES_STATUSES, AbsenceStatuses } from "../constants/absenceStatuses";
import { IHolidayResponse } from "../types/holiday";
import CustomToolbar from "../components/CalendarCustomToolbar";

const UserSchedule: React.FunctionComponent<{}> = () => {
  const dispatch = useAppDispatch();
  const { hasPermission } = usePermissions();

  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState<Date>(moment().toDate());
  const [data, setData] = useState<IUserScheduleResponse>();

  const [events, setEvents] = useState<IScheduleEvent[]>([]);
  const [userEvents, setUserEvents] = useState<IScheduleEvent[]>([]);
  const [userAbsences, setUserAbsences] = useState<IScheduleEvent[]>([]);
  const [userHolidays, setUserHolidays] = useState<IScheduleEvent[]>([]);

  const loadData = useCallback(() => {
    setIsLoading(true);

    Promise.all([
      axios.get<IUserScheduleResponse>(`/users/userschedule`, { params: { date: date } }),
      axios.get<ITimesheetEventResponse[]>("/users/events", { params: { date: date } }),
      axios.get<IAbsenceResponse[]>("/absences/userAbsences", { params: { date: date } }),
      axios.get<IHolidayResponse[]>("/holidays/userHolidays", { params: { date: date } }),
    ])
      .then((res) => {
        setIsLoading(false);
        setData(res[0].data);

        setUserEvents(
          res[1].data.map((event) => ({
            title: event.clinic.name,
            start: moment(event.startedAt).toDate(),
            end: moment(event.finishedAt).toDate(),
            resource: event,
            eventType: "timesheet",
          }))
        );

        setUserAbsences(
          res[2].data.map((absence) => ({
            title: `${absence.type!.name} (${ABSENCES_STATUSES[absence.status].label})`,
            start: moment(absence.from).toDate(),
            end: moment(absence.to).toDate(),
            resource: absence,
            eventType: "absence",
          }))
        );

        setUserHolidays(
          res[3].data.map((holiday) => ({
            title: `${holiday.name} (${holiday.clinics.map((clinic) => clinic.name)})`,
            start: moment(holiday.from).toDate(),
            end: moment(holiday.to).toDate(),
            resource: holiday,
            eventType: "holiday",
          }))
        );
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [date]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (data) {
      let tmpEvents: IScheduleEvent[] = [];
      data?.schedule.forEach((schedule) => {
        schedule.chairs.forEach((chair) => {
          tmpEvents.push({
            title: `${chair.chairName} (${chair.clinic?.name})`,
            start: chair.report
              ? moment(chair.report.startedAt).toDate()
              : moment(chair.date).startOf("day").toDate(),
            end: chair.report
              ? moment(chair.report.finishedAt).toDate()
              : moment(chair.date).endOf("day").toDate(),
            resource: chair,
            eventType: "event",
          });
        });
      });

      setEvents(tmpEvents);
    }
  }, [data]);

  const localizer = momentLocalizer(moment);
  const onNavigate = useCallback((newDate: Date) => setDate(newDate), [setDate]);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Mans grafiks</Typography>
        </Grid>
      </Grid>
      {data ? (
        <Box sx={{ height: 800 }}>
          <Calendar
            date={date}
            events={_.concat(events, userEvents, userAbsences, userHolidays)}
            localizer={localizer}
            views={["month", "week"]}
            drilldownView={null}
            onNavigate={onNavigate}
            components={{
              toolbar: CustomToolbar,
            }}
            onSelectEvent={(event) => {
              switch (event.eventType) {
                case "event":
                  if (Object.values(ChairTypes).includes(event.resource.type)) {
                    dispatch(
                      openModal({
                        type: MODALS.Timesheet,
                        chairSchedule: event.resource,
                        initialValues: event.resource.report,
                        user: data.user,
                        callback: () => {
                          loadData();
                        },
                      })
                    );
                  }
                  break;

                case "timesheet":
                  const resource = event.resource as TimesheetResponse | undefined;

                  if (!resource) {
                    return;
                  }

                  switch (resource.type) {
                    case "event":
                      if (!hasPermission(PERMISSIONS.WRITE_TIMESHEET_EVENT)) {
                        return;
                      }
                      dispatch(
                        openModal({
                          type: MODALS.TimesheetEvent,
                          date: moment(event.start).toDate(),
                          initialValues: resource,
                          callback: () => {
                            loadData();
                          },
                        })
                      );
                      break;
                    case "schedule":
                      dispatch(
                        openModal({
                          type: MODALS.Timesheet,
                          chairSchedule: event.resource,
                          initialValues: event.resource.report,
                          user: data.user,
                          callback: () => {
                            loadData();
                          },
                        })
                      );
                      break;
                    default:
                  }
                  break;

                case "absence":
                case "holiday":
                default:
              }
            }}
            selectable
            onSelectSlot={(slotInfo) => {
              if (!hasPermission(PERMISSIONS.WRITE_TIMESHEET_EVENT)) {
                return;
              }

              if (moment(slotInfo.start).isAfter(moment())) {
                return;
              }

              dispatch(
                openModal({
                  type: MODALS.TimesheetEvent,
                  date: slotInfo.start,
                  callback: () => {
                    loadData();
                  },
                })
              );
            }}
            popup
            eventPropGetter={(event) => {
              let color = "white";
              let backgroundColor = "#f44336";
              let border = "0px";
              let cursor = "pointer";

              switch (event.eventType) {
                case "event":
                case "timesheet":
                  const resource = event.resource as TimesheetResponse | {} | undefined;

                  if (!resource) {
                    return {};
                  }

                  if ("type" in resource) {
                    switch (resource.type) {
                      case "event":
                        backgroundColor = "#4caf50";
                        break;
                      case "schedule":
                      default:
                        if (moment(event.start).isAfter(moment())) {
                          backgroundColor = "#2196f3";
                        } else if (event.resource.report) {
                          backgroundColor = "#4caf50";
                        }
                        break;
                    }
                  }
                  break;
                case "absence":
                  const absence = event.resource as IAbsenceResponse | undefined;

                  if (!absence) {
                    return {};
                  }

                  cursor = "default";
                  switch (absence.status) {
                    case AbsenceStatuses.Approved:
                      backgroundColor = "#4caf50";
                      break;
                    case AbsenceStatuses.Requested:
                    default:
                      backgroundColor = "#f5f5f5";
                      border = "1px solid #333";
                      color = "#333";
                      break;
                  }
                  break;

                case "holiday":
                  cursor = "default";
                  backgroundColor = "#777";
                  break;

                default:
                  break;
              }

              // TODO: define colors for all events (reported schedule, unreported schedule, absence, holidays)
              let style = {
                backgroundColor: backgroundColor,
                color: color,
                borderRadius: "0px",
                border: border,
                opacity: 1,
                display: "block",
                cursor: cursor,
              };
              return {
                style: style,
              };
            }}
          />
        </Box>
      ) : null}
    </Stack>
  );
};

export default UserSchedule;
