// utils
import { createSlice } from "@reduxjs/toolkit";

export interface IAuth {
  isAuthenticated: boolean;
}

const initialState: IAuth = {
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth: () => initialState,
    setUser: (state, { payload }: { payload: any }) => {
      state.isAuthenticated = true;
    },
    clearUser: (state) => {
      state.isAuthenticated = false;
    },
  },
});

export const { clearAuth, setUser, clearUser } = authSlice.actions;

export default authSlice.reducer;
