import { useState, useEffect, useCallback } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import jwt_decode from "jwt-decode";

export function usePermissions() {
  const { getAccessTokenSilently } = useAuth0();

  const [permissions, setPermissions] = useState<string[]>([]);

  const getPermissions = async () => {
    const token = await getAccessTokenSilently();
    const decoded = jwt_decode(token);

    // @ts-ignore
    if (decoded.permissions) {
      // @ts-ignore
      setPermissions(decoded.permissions);
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const hasPermission: (permission: string) => boolean = useCallback(
    (permission: string) => {
      return permissions.includes(permission);
    },
    [permissions]
  );

  return { hasPermission };
}
