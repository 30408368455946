import * as yup from "yup";
import { HolidayTypes } from "../constants/holidayTypes";

const holidaySchema = yup.object({
  from: yup.date().required(),
  to: yup.date().required(),
  name: yup.string().required(),
  clinics: yup.array().of(yup.string()).min(1),
  type: yup.string().required().oneOf(["holiday", "workday"]),
});

export type HolidayBase = yup.InferType<typeof holidaySchema> & {
  type: HolidayTypes;
};

export default holidaySchema;
