import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import moment from "moment";

import { Grid, Typography, Stack, IconButton, Avatar } from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IWorkload } from "../types/clinic";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const Workload: React.FunctionComponent<{}> = () => {
  const { clinic } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState<Date>(moment().toDate());
  const [data, setData] = useState<IWorkload[]>([]);

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IWorkload[]>(`/clinics/${clinic}/assistantsWorkload`, { params: { date: date } })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [date]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns: GridColDef[] = [
    {
      field: "user",
      headerName: "Darbinieks",
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={params.row.user.name} src={params.row.user.image} />
            <Typography>{params.row.user.name}</Typography>
          </Stack>
        );
      },
      flex: 1,
    },
    {
      field: "daysWorking",
      headerName: "Darba dienas",
      sortable: false,
      type: "number",
      minWidth: 110,
      align: "center",
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Asistentu noslodze</Typography>
        </Grid>
      </Grid>
      <Stack direction="row" spacing={2} alignItems="center">
        <IconButton
          onClick={() =>
            setDate((currentDate) => moment(currentDate).subtract(1, "month").toDate())
          }
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography>
          {moment(date).startOf("month").format("LL")} - {moment(date).endOf("month").format("LL")}
        </Typography>
        <IconButton
          onClick={() => setDate((currentDate) => moment(currentDate).add(1, "month").toDate())}
        >
          <ChevronRightIcon />
        </IconButton>
      </Stack>
      <DataGrid
        loading={isLoading}
        getRowId={(row) => row.user._id}
        rows={data}
        columns={columns}
        hideFooterPagination
        autoHeight
        disableSelectionOnClick
        disableColumnMenu
        hideFooter
      />
    </Stack>
  );
};

export default Workload;
