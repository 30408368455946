import { useEffect, useState, useCallback } from "react";

import MODALS from "../constants/modals";

import { useAppDispatch } from "../hooks/redux";
import { openModal } from "../features/modals";
import axios from "axios";

import { Stack, Grid, Typography, Button, Avatar, Chip, TextField, IconButton, Popover, Badge, Autocomplete } from "@mui/material";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { IUserResponse } from "../types/user";
import PermissionGuard from "../components/PermissionGuard";
import PERMISSIONS from "../constants/permissions";
import { ClinicResponse } from "../types/clinic";
import ROLES, { ROLES_LIST } from "../constants/roles";

const columns: GridColDef[] = [
  {
    field: "image",
    headerName: "",
    sortable: false,
    width: 40,
    renderCell: (params) => {
      if (!params.value) {
        return null;
      }

      return <Avatar alt={params.row.name} src={params.value} />;
    },
  },
  {
    field: "name",
    headerName: "Vārds, Uzvārds",
    sortable: false,
    flex: 1,
  },
  {
    field: "email",
    headerName: "E-pasts",
    sortable: false,
    flex: 1,
  },
  {
    field: "roles",
    headerName: "Loma",
    sortable: false,
    flex: 1,
    renderCell: (params) => {
      return (
        <Grid container spacing={0.5}>
          {params.value.map((role: ROLES) => {
            return (
              <Grid item key={role}>
                <Chip
                  label={ROLES_LIST[role].name}
                  variant="outlined"
                  size="small"
                  sx={{ fontSize: "0.7rem", height: 1 }}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    },
  },
  {
    field: "clinics",
    headerName: "Klīnikas",
    sortable: false,
    renderCell: (params) => {
      return (
        <Grid container spacing={0.5}>
          {params.value.map((clinic: ClinicResponse) => {
            return (
              <Grid key={clinic._id} item>
                <Chip
                  label={clinic.name}
                  variant="outlined"
                  size="small"
                  sx={{ fontSize: "0.7rem", height: 1 }}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    },
    flex: 1,
  },
];

const Users: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<IUserResponse[]>([]);

  const [filterValues, setFilterValues] = useState<IFilterValues>({});
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [search, setSearch] = useState<string | undefined>();


  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFiltersAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFiltersAnchorEl(null);
  };

  const handleFilterChange = useCallback((values: IFilterValues) => {
    setFilterValues(values);
  }, []);

  const handleSync = useCallback(() => {
    axios
      .post("/users/sync")
      .then((res) => {
        console.log("res:", res);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IUserResponse[]>("/users", {
        params: { search: search, filters: filterValues },
      })
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [search, filterValues]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Lietotāji</Typography>
        </Grid>
        <PermissionGuard permission={PERMISSIONS.SYNC_USERS}>
          {({ hasAccess }) => (
            <>
              {hasAccess ? (
                <Grid item>
                  <Button startIcon={<AddIcon />} onClick={() => handleSync()}>
                    Sinhronizēt
                  </Button>
                </Grid>
              ) : null}
            </>
          )}
        </PermissionGuard>
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center" gap={1}>
        <Grid item>
          <TextField
            label="Meklēt"
            value={search ? search : ""}
            onChange={(e) => setSearch(e.target.value)}
            inputProps={{
              maxLength: 50,
            }}
            InputProps={{
              endAdornment: search ? (
                <IconButton size="small" onClick={() => setSearch(undefined)}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              ) : undefined,
            }}
            size="small"
          />
        </Grid>
        <Grid item>
          <IconButton onClick={handleFilterClick}>
            <Badge
              color="secondary"
              badgeContent={Object.values(filterValues).filter((item) => item).length}
            >
              <FilterAltIcon />
            </Badge>
          </IconButton>
        </Grid>
      </Grid>
      <PermissionGuard permission={PERMISSIONS.WRITE_USERS}>
        {({ hasAccess }) => (
          <DataGrid
            sx={{
              "& .MuiDataGrid-row": { cursor: "pointer" },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: 1 },
            }}
            loading={isLoading}
            getRowId={(row) => row._id}
            onRowClick={(params) => {
              dispatch(
                openModal({
                  type: MODALS.User,
                  user: params.row,
                  callback: (newUser) => {
                    setUsers((e) => e.map((user) => (user._id === newUser._id ? newUser : user)));
                  },
                })
              );
            }}
            rows={users}
            columns={columns}
            getRowHeight={() => "auto"}
            hideFooterPagination
            autoHeight
            disableSelectionOnClick
            disableColumnMenu
            hideFooter
          />
        )}
      </PermissionGuard>
      <Popover
        open={Boolean(filtersAnchorEl)}
        anchorEl={filtersAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
      >
        <Filters values={filterValues} onFilterChange={handleFilterChange} />
      </Popover>
    </Stack>
  );
};

interface IFilterValues {
  search?: string;
  role?: ROLES;
  clinic?: string;
}

interface IFIlterProps {
  values: IFilterValues;
  onFilterChange: (values: IFilterValues) => void;
}

const Filters: React.FunctionComponent<IFIlterProps> = ({ values, onFilterChange }) => {
  const [clinics, setClinics] = useState<ClinicResponse[]>([]);

  useEffect(() => {
    onFilterChange(values);
  }, [values]);

  useEffect(() => {
    axios
      .get<ClinicResponse[]>("/clinics")
      .then((res) => {
        setClinics(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  return (
    <Stack spacing={2} sx={{ padding: 2, width: 400, maxWidth: "100%" }}>
      <Typography variant="h6">Filtri</Typography>
      <Autocomplete
        options={clinics}
        value={values.clinic ? clinics.find((clinic) => values.clinic === clinic._id) : null}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Klīnika" />}
        onChange={(e, value) => {
          onFilterChange({
            ...values,
            clinic: value ? value._id : undefined,
          });
        }}
      />
      <Autocomplete
        options={Object.keys(ROLES_LIST) as ROLES[]}
        value={values.role ?  values.role : null}
        getOptionLabel={(option) => ROLES_LIST[option].name}
        renderInput={(params) => <TextField {...params} label="Loma" />}
        onChange={(e, value) => {
          onFilterChange({
            ...values,
            role: value ? value : undefined,
          });
        }}
      />
    </Stack>
  );
};

export default Users;
