import { Card, CardMedia, CircularProgress, IconButton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import FsLightbox from "fslightbox-react";
import axios from "axios";
import { BasicFile } from "../types/common";

import DeleteIcon from "@mui/icons-material/Delete";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import UndoIcon from "@mui/icons-material/Undo";

import { openLightbox } from "../features/lightbox";
import { useAppDispatch } from "../hooks/redux";

interface ImageThumbnailProps {
  value: BasicFile;
  basePath?: string;
  onDelete?: () => void;
  onRestore?: () => void;
}

const ImageThumbnail: React.FC<ImageThumbnailProps> = ({
  value,
  basePath,
  onDelete,
  onRestore,
}) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [preview, setPreview] = useState<string>();

  const [isActionsVisible, setIsActionsVisible] = useState(false);

  useEffect(() => {
    if (!value) return;

    if ("src" in value) {
      setIsLoading(true);
      axios.get(basePath ? `${basePath}${value.src}` : value.src).then((res) => {
        // set response as base64 image
        setPreview("data:image/png;base64," + res.data);
        setIsLoading(false);
      });

      return;
    }

    // create the preview
    const objectUrl = URL.createObjectURL(value.file);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [value]);

  if (!value) return null;

  return (
    <Card
      style={{
        position: "relative",
      }}
      elevation={0}
      sx={{
        maxWidth: 120,
      }}
      onMouseEnter={() => setIsActionsVisible(true)}
      onMouseLeave={() => setIsActionsVisible(false)}
    >
      {isLoading ? (
        <div
          style={{
            width: 120,
            height: 120,
            maxWidth: "100%",
            maxHeight: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <CardMedia
            component="img"
            sx={{
              height: 120,
              width: 120,
              objectFit: "contain",
              maxWidth: "100%",
              opacity: "deleted" in value && value.deleted ? 0.1 : 1,
            }}
            image={preview}
            alt="uploaded image"
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              transition: "all 0.3s ease-in-out",
              opacity: isActionsVisible ? 1 : 0,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: "rgba(255,255,255,0.5)",
              }}
            />
            <Stack
              style={{ height: "100%" }}
              flex={1}
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {onDelete && (
                <>
                  {"deleted" in value && value.deleted ? (
                    onRestore && (
                      <IconButton onClick={onRestore}>
                        <UndoIcon />
                      </IconButton>
                    )
                  ) : (
                    <IconButton onClick={onDelete}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </>
              )}

              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!preview) return;

                  dispatch(
                    openLightbox({
                      index: 0,
                      images: [preview],
                    })
                  );
                }}
              >
                <OpenInFullIcon />
              </IconButton>
            </Stack>
          </div>
        </>
      )}
    </Card>
  );
  // <>
  //   {/* {preview && (
  //     <div>
  //       <FsLightbox toggler={toggler} sources={[preview]} showThumbsOnMount={true} type="image" />
  //     </div>
  //   )}
  // </> */}
};

export default ImageThumbnail;
