import { Box, CircularProgress } from "@mui/material";

function FullScreenLoader() {
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
      <CircularProgress color="inherit" />
    </Box>
  );
}

export default FullScreenLoader;
