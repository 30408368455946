import * as yup from "yup";

const roentgenSchema = yup.object({
  name: yup.string().required(),
  clinic: yup.string().required(),
});

export type RoentgenBase = yup.InferType<typeof roentgenSchema>;

export default roentgenSchema;
