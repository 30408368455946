import { IReceivedItemCountModalProps } from "../types/modal";
import {
  ReceivedItemCountBase,
  receivedItemCountSchema,
} from "../schemas/order";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import axios from "axios";
import { useFormik } from "formik";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  TextField,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const ReceivedItemCount: React.FunctionComponent<
  IReceivedItemCountModalProps
> = ({ id, orderId, item, callback }) => {
  const dispatch = useAppDispatch();

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    submitCount,
    isSubmitting,
  } = useFormik<ReceivedItemCountBase>({
    initialValues: {
      receivedCount:
        item.receivedCount !== undefined ? item.receivedCount : item.count,
    },
    onSubmit: (values) => {
      axios
        .put(`/order/${orderId}/item/${item._id}/received-count`, values)
        .then(() => {
          callback();
          handleClose();
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    validationSchema: receivedItemCountSchema,
  });

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={true} onClose={handleClose}>
      <DialogTitle>Saņemtais daudzums</DialogTitle>
      <DialogContent>
        <TextField
          id="receivedCount"
          label="Saņemtais daudzums"
          fullWidth
          type="number"
          value={values.receivedCount}
          onChange={handleChange}
          error={Boolean(errors.receivedCount) && submitCount > 0}
          autoFocus
          onFocus={(event) => {
            event.target.select();
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Aizvērt</Button>

        <LoadingButton
          loading={isSubmitting}
          onClick={() => {
            handleSubmit();
          }}
          variant="contained"
        >
          Saglabāt
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ReceivedItemCount;
