import type {} from "redux-thunk/extend-redux";

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import axios from "axios";

import "moment/locale/lv";

import { Provider } from "react-redux";
import { store } from "./store";

import { Auth0Provider } from "@auth0/auth0-react";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./themes/default";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain="dev-xj32cs6v.us.auth0.com"
        clientId="gbk4xkZMqmu20xFgPjDXkKMJdBXRrsab"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://boilerplate.boilerplate",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar
            closeOnClick
            pauseOnFocusLoss
            closeButton={false}
            draggable
            pauseOnHover
            theme="light"
          />
        </LocalizationProvider>
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
