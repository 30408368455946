import { useEffect, useState, useCallback, useMemo } from "react";

import { ORDER_STATUSES, OrderStatus } from "../../../constants/orderStatuses";
import {
  IOrderFile,
  IOrderItemResponse,
  IOrderResponse,
} from "../../../types/order";
import MODALS from "../../../constants/modals";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { grey } from "@mui/material/colors";

import { useParams } from "react-router";
import moment from "moment";
import axios from "axios";
import { useAppDispatch } from "../../../hooks/redux";
import { openModal } from "../../../features/modals";

import { Link as RouterLink } from "react-router-dom";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import {
  Stack,
  Grid,
  Typography,
  Chip,
  MenuItem,
  IconButton,
  Divider,
  Box,
  Menu,
  Link,
} from "@mui/material";
import FileList from "../../../components/FileList";
import EditIcon from "@mui/icons-material/Edit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faHouseChimneyMedical,
  faHashtag,
  faEllipsisVertical,
  faCalendar,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import FileUpload from "../../../components/FileUpload";
import { saveAs } from "file-saver";

const ReceiveOrder: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<IOrderResponse>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IOrderResponse>(`/order/${id}`)
      .then((res) => {
        console.log("res:", res.data);
        setResponse(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const changeStatus = useCallback(
    (status: OrderStatus) => {
      dispatch(
        openModal({
          type: MODALS.Approve,
          title: "Mainīt pasūtījuma statusu?",
          message: `Pasūtījuma stauss tiks mainīts uz "${ORDER_STATUSES[status].label}".`,
          onApprove: () => {
            axios
              .patch(`/order/${id}`, {
                status,
              })
              .then((res) => {
                loadData();
              })
              .catch((err) => {
                console.log("error:", err);
              });
          },
          onReject: () => {},
        })
      );
    },
    [dispatch, loadData, id]
  );

  const handleFiledDownload = useCallback(
    (file: IOrderFile) => {
      if (!response?._id) {
        return;
      }

      axios
        .get(`/order/${response._id}/files/${file._id}`, {
          responseType: "blob",
        })
        .then((res) => {
          saveAs(res.data, file.name);
        })
        .catch((err) => {
          console.log("error:", err);
        });
    },
    [response?._id]
  );

  const handleFileDelete = useCallback(
    (file: IOrderFile) => {
      if (!response?._id) {
        return;
      }

      dispatch(
        openModal({
          type: MODALS.Approve,
          title: "Dzēst failu?",
          message: `Vai tiešām vēlaties dzēst failu "${file.name}"?`,
          onApprove: () => {
            axios
              .delete(`/order/${response._id}/files/${file._id}`)
              .then((res) => {
                loadData();
              })
              .catch((err) => {
                console.log("error:", err);
              });
          },
          onReject: () => {},
        })
      );
    },
    [response?._id]
  );

  const handleUpload = useCallback(
    (files: File[]) => {
      if (!response?._id) {
        return;
      }

      console.log("files:", files);

      const formData = new FormData();

      files.forEach((file) => {
        formData.append("files", file, `${response._id}_${file.name}`);
      });

      axios
        .post(`/order/${response._id}/files`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            console.log(
              "progress:",
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
            // setUploadProgress(
            //   Math.round((progressEvent.loaded / progressEvent.total) * 100)
            // );
          },
        })
        .then((res) => {
          loadData();
          // setData(res.data);
        })
        .catch((err) => {
          console.log("err:", err);
        })
        .finally(() => {
          // setUploading(false);
          // setUploadProgress(0);
        });
    },
    [response?._id]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = useMemo<MRT_ColumnDef<IOrderItemResponse>[]>(
    () => [
      {
        header: "Nosaukums",
        accessorKey: "material.name",
      },
      {
        header: "Daudzums",
        accessorKey: "count",
        enableColumnFilter: false,
        maxSize: 90,
        size: 90,
        minSize: 90,
        accessorFn: (originalRow) => {
          if (
            originalRow.receivedCount !== undefined &&
            originalRow.receivedCount !== originalRow.count
          ) {
            return (
              <Stack direction="row" spacing={1}>
                <Typography
                  style={{
                    textDecoration: "line-through",
                  }}
                  color={grey[400]}
                >
                  {originalRow.count}
                </Typography>
                <Typography color="red">{originalRow.receivedCount}</Typography>
              </Stack>
            );
          }

          return originalRow.count;
        },
      },
    ],
    []
  );

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Stack direction="row" spacing={1} alignItems="center">
            <Link component={RouterLink} to="/manage/orders">
              <IconButton>
                <Box width={26} height={26}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Box>
              </IconButton>
            </Link>
            <Typography variant="h4">Pasūtījums</Typography>

            {response && (
              <Chip
                label={ORDER_STATUSES[response.status].label}
                color={ORDER_STATUSES[response.status].color}
                variant="outlined"
                size="small"
              />
            )}
          </Stack>
        </Grid>
        <Grid item>
          <IconButton onClick={handleMenuOpen}>
            <Box width={26} height={26}>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </Box>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {response?.status === OrderStatus.New && (
              <MenuItem
                onClick={() => {
                  changeStatus(OrderStatus.Paid);
                  handleMenuClose();
                }}
              >
                Atzīmēt kā apmaksātu
              </MenuItem>
            )}
            {response?.status === OrderStatus.Paid && (
              <MenuItem
                onClick={() => {
                  changeStatus(OrderStatus.Completed);
                  handleMenuClose();
                }}
              >
                Atzīmēt kā saņemtu
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                if (response) {
                  dispatch(
                    openModal({
                      type: MODALS.OrderNumber,
                      order: response,
                      callback: loadData,
                    })
                  );
                }
                handleMenuClose();
              }}
            >
              Labot pasūtījuma numuru
            </MenuItem>
            {response?.status !== OrderStatus.Cancelled && (
              <MenuItem
                onClick={() => {
                  changeStatus(OrderStatus.Cancelled);
                  handleMenuClose();
                }}
              >
                Atzīmēt kā atceltu
              </MenuItem>
            )}
          </Menu>
        </Grid>
      </Grid>

      {response && <OrderDetails order={response} />}

      {response && (
        <MaterialReactTable
          key={`order-${response._id}-${response.status}`}
          columns={columns}
          data={response.items}
          getRowId={(row) => row._id}
          enableRowActions={response.status === OrderStatus.Paid}
          positionActionsColumn="last"
          localization={{
            actions: "Darbības",
          }}
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination={false}
          enableSorting={false}
          enableBottomToolbar={false}
          enableTopToolbar={false}
          enableGlobalFilter={false}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              size: 60,
            },
          }}
          renderRowActions={({ row }) => {
            return (
              <IconButton
                onClick={() => {
                  dispatch(
                    openModal({
                      type: MODALS.ReceivedItemCount,
                      orderId: response._id,
                      item: row.original,
                      callback: loadData,
                    })
                  );
                }}
              >
                <EditIcon />
              </IconButton>
            );
          }}
          state={{
            isLoading,
          }}
        />
      )}

      <FileList
        files={response?.files ? response.files : []}
        onDelete={handleFileDelete}
        onDownload={handleFiledDownload}
      />
      <FileUpload handleUpload={handleUpload} />
    </Stack>
  );
};

const OrderDetails: React.FC<{ order: IOrderResponse }> = ({ order }) => {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      divider={<Divider orientation="vertical" flexItem variant="middle" />}
      spacing={{
        sm: 2,
      }}
    >
      <OrderDetailsItem
        label="Pasūtījuma nr."
        value={order.orderNumber ? order.orderNumber : "-"}
        icon={faHashtag}
      />
      <OrderDetailsItem
        label="Klīnika"
        value={order.clinic.name}
        icon={faHouseChimneyMedical}
      />
      <OrderDetailsItem
        label="Piegādātājs"
        value={order.supplier.name}
        icon={faTruck}
        last
      />
      <OrderDetailsItem
        label="Pasūtījuma datums"
        value={moment(order.createdAt).format("L")}
        icon={faCalendar}
      />
    </Stack>
  );
};

const OrderDetailsItem: React.FC<{
  label: string;
  value: string | number;
  icon: IconProp;
  last?: boolean;
}> = ({ label, value, icon, last }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2} flex={1}>
      <Stack flex={1}>
        <Typography variant="caption" color={grey[400]}>
          {label}
        </Typography>
        <Typography variant="h6">{value}</Typography>
      </Stack>
      <FontAwesomeIcon icon={icon} size="xl" color={grey[400]} />
    </Stack>
  );
};

export default ReceiveOrder;
