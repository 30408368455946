import * as yup from "yup";

const categorySchema = yup.object({
  name: yup.string().required(),
  parent: yup.string().nullable(),
});

export type CategoryBase = yup.InferType<typeof categorySchema>;

export default categorySchema;
