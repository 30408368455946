import { useState } from "react";
import { IAbsenceTypeModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById, openModal } from "../features/modals";

import { useFormik } from "formik";
import axios from "axios";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  TextField,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import absenceTypeSchema, { AbsenceTypeBase } from "../schemas/absenceType";
import PermissionGuard from "../components/PermissionGuard";
import PERMISSIONS from "../constants/permissions";
import MODALS from "../constants/modals";

const AbsenceTypeModal: React.FunctionComponent<IAbsenceTypeModalProps> = ({
  id,
  callback,
  initialValues,
}) => {
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState(false);

  const {
    values,
    dirty,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    resetForm,
    submitCount,
  } = useFormik<AbsenceTypeBase>({
    initialValues: {
      name: initialValues ? initialValues.name : "",
      abbreviation: initialValues ? initialValues.abbreviation : "",
    },
    onSubmit: (values) => {
      axios
        .put(
          "/classifiers/absenceTypes",
          initialValues ? { _id: initialValues._id, ...values } : values
        )
        .then(() => {
          callback();
          handleClose();
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    validationSchema: absenceTypeSchema,
  });

  const handleDelete = (id: string) => {
    setIsDeleting(true);
    axios
      .delete(`/classifiers/absenceTypes/${id}`)
      .then(() => {
        callback();
        handleClose();
      })
      .catch((err) => {
        console.log("err:", err);
        setIsDeleting(false);
      });
  };

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={true} onClose={handleClose}>
      <DialogTitle>{"_id" in values ? "Labot" : "Pievienot"} prombūtnes veidu</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            id="name"
            label="Nosaukums"
            variant="standard"
            value={values.name}
            onChange={handleChange}
            error={Boolean(errors.name) && submitCount > 0}
          />
          <TextField
            id="abbreviation"
            label="Saīsinājums"
            variant="standard"
            inputProps={{
              maxLength: 3,
            }}
            value={values.abbreviation}
            onChange={handleChange}
            error={Boolean(errors.abbreviation) && submitCount > 0}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <PermissionGuard permission={PERMISSIONS.DELETE_ABSENCE_TYPES}>
          {({ hasAccess }) => (
            <>
              {hasAccess && initialValues ? (
                <LoadingButton
                  loading={isDeleting}
                  onClick={() => {
                    setIsDeleting(true);
                    dispatch(
                      openModal({
                        type: MODALS.Approve,
                        title: "Vai tiešām dzēst prombūtnes veidu?",
                        message: "Prombūtnes veids tiks dzēsts neatgriezeniski.",
                        onApprove: () => {
                          handleDelete(initialValues._id);
                        },
                        onReject: () => {
                          setIsDeleting(false);
                        },
                      })
                    );
                  }}
                  color="error"
                  variant="outlined"
                >
                  Dzēst
                </LoadingButton>
              ) : null}
            </>
          )}
        </PermissionGuard>
        <Stack direction="row" spacing={2} justifyContent="flex-end" flex={1}>
          <Button onClick={handleClose}>Aizvērt</Button>
          <Button
            disabled={isDeleting}
            onClick={() => {
              handleSubmit();
            }}
            variant="contained"
          >
            {initialValues ? "Saglabāt" : "Pievienot"}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AbsenceTypeModal;
