enum ROLES {
  Moderator = "rol_Cq9Wh2KrTRdH26ZE",
  Doctor = "rol_vGbuTGSP7jc1ZBft",
  Assistant = "rol_uFzdMXliTv6W61uy",
  Hygienist = "rol_3gvmzdYnJjJgx4gK",
  Orthodontist = "rol_L9I51x8y33XJgeQ7",
  Administration = "rol_bvTrZgZ5x05vYyc8",
  Sanitary = "rol_qZisuuPIG9Zrsxx0",
  TechnicalStaff = "rol_lqPcHKvYDzRCuWXw",
  Apartment = "rol_q3TTwrv4JrDhCYpI",
  Driver = "rol_UFGpgYraVk5KqPvN",
}

export interface IRole {
  name: string;
}

type IRolesList = {
  [key in ROLES]: IRole;
};

export const ROLES_LIST: IRolesList = {
  [ROLES.Moderator]: {
    name: "Moderators",
  },
  [ROLES.Doctor]: {
    name: "Ārsts",
  },
  [ROLES.Assistant]: {
    name: "Asistents",
  },
  [ROLES.Hygienist]: {
    name: "Higiēnists",
  },
  [ROLES.Orthodontist]: {
    name: "Ortodonts",
  },
  [ROLES.Administration]: {
    name: "Administrācija",
  },
  [ROLES.Sanitary]: {
    name: "Sanitārs",
  },
  [ROLES.TechnicalStaff]: {
    name: "Tehniskais personāls",
  },
  [ROLES.Apartment]: {
    name: "Dienesta dzīvoklis",
  },
  [ROLES.Driver]: {
    name: "Šoferis",
  },
};

export default ROLES;
