import { useCallback, useEffect, useState } from "react";

import ROLES, { ROLES_LIST } from "../../../constants/roles";

import writeXlsxFile, { Row, SheetData } from "write-excel-file";

import moment from "moment";
import _ from "lodash";

import {
  Grid,
  Typography,
  Stack,
  IconButton,
  Avatar,
  Autocomplete,
  Popover,
  TextField,
  Badge,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import { DataGrid, GridColDef } from "@mui/x-data-grid";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axios from "axios";
import { ChairTypes } from "../../../constants/chairTypes";
import { IUserBaseResponse } from "../../../types/user";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GridOnIcon from "@mui/icons-material/GridOn";
import { LoadingButton } from "@mui/lab";
import { ClinicResponse } from "../../../types/clinic";
import { IAbsenceTypeResponse } from "../../../types/classifiers";

const TimesheetsReports: React.FunctionComponent<{}> = () => {
  const [isExporting, setIsExporting] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [absenceTypes, setAbsenceTypes] = useState<IAbsenceTypeResponse[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState<Date>(moment().toDate());

  const [columns, setColumns] = useState<GridColDef<ITimesheetsReportResponse>[]>([]);

  const [filterValues, setFilterValues] = useState<IFilterValues>({});
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFiltersAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFiltersAnchorEl(null);
  };

  const handleFilterChange = useCallback((values: IFilterValues) => {
    setFilterValues(values);
  }, []);

  // TODO: move to types and reuse nested types
  interface ITimesheetsReportResponse {
    _id: string;
    user: {
      _id: string;
      name: string;
      image: string;
    };
    timesheets: {
      _id: string;
      startedAt: Date;
      finishedAt: Date;
      schedule: {
        _id: string;
        type: ChairTypes;
      };
    }[];
    absences: {
      _id: string;
      from: Date;
      to: Date;
      status: string;
      type: {
        _id: string;
        name: string;
        abbreviation: string;
      };
    }[];
  }

  const [data, setData] = useState<ITimesheetsReportResponse[]>([]);

  useEffect(() => {
    let monthDayColumns: GridColDef<ITimesheetsReportResponse>[] = [];
    const daysInMonth = moment(date).daysInMonth();
    for (let i = 1; i <= daysInMonth; i++) {
      monthDayColumns.push({
        field: `day${i}`,
        headerName: i.toString(),
        width: 25,
        headerAlign: "center",
        sortable: false,
        align: "center",
        renderCell: (params) => {
          const timesheet = params.row.timesheets.filter((timesheet) => {
            return moment(timesheet.startedAt).date() === i;
          });

          if (!timesheet) {
            return null;
          }

          const absence = params.row.absences.find((absence) => {
            return moment(absence.from).date() <= i && moment(absence.to).date() >= i;
          });

          if (absence) {
            return (
              <Tooltip title={absence.type.name}>
                <Typography>{absence.type.abbreviation}</Typography>
              </Tooltip>
            );
          }

          let duration = moment.duration(0);

          timesheet.forEach((timesheet) => {
            duration.add(
              moment.duration(moment(timesheet.finishedAt).diff(moment(timesheet.startedAt)))
            );
          });

          let isSurgeon = false;
          timesheet.forEach((timesheet) => {
            if (timesheet.schedule && timesheet.schedule.type === ChairTypes.Surgeon) {
              isSurgeon = true;
            }
          });

          return (
            <Typography color={isSurgeon ? "error" : "default"}>
              {_.round(duration.asHours(), 2)}
            </Typography>
          );
        },
      });
    }

    let absenceTypeColumns: GridColDef<ITimesheetsReportResponse>[] = [];
    absenceTypes.forEach((absenceType) => {
      absenceTypeColumns.push({
        field: absenceType._id,
        renderHeader(params) {
          return (
            <Tooltip title={absenceType.name}>
              <Typography>{absenceType.abbreviation}</Typography>
            </Tooltip>
          );
        },
        sortable: false,
        width: 40,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const absences = params.row.absences.filter((absence) => {
            return absence.type._id === absenceType._id;
          });

          let totalDays = 0;

          absences.forEach((absence) => {
            totalDays += moment(absence.to).diff(moment(absence.from), "days") + 1;
          });

          return totalDays;
        },
      });
    });

    setColumns([
      {
        field: "image",
        headerName: "",
        sortable: false,
        width: 40,
        valueGetter: (params) => params.row.user?.image,
        renderCell: (params) => {
          if (!params.value) {
            return null;
          }

          return <Avatar alt={params.row.user?.name} src={params.value} />;
        },
      },
      {
        field: "name",
        headerName: "Vārds, Uzvārds",
        valueGetter: (params) => params.row.user?.name,
        sortable: false,
        flex: 1,
        minWidth: 200,
      },
      ...monthDayColumns,
      {
        field: "totalHours",
        headerName: "Stundas kopā",
        sortable: false,
        width: 110,
        align: "center",
        valueGetter: (params) => {
          const totalHours = params.row.timesheets.reduce((totalHours, timesheet) => {
            const duration = moment.duration(
              moment(timesheet.finishedAt).diff(moment(timesheet.startedAt))
            );

            return totalHours + duration.asHours();
          }, 0);

          return _.round(totalHours, 2);
        },
      },
      {
        field: "totalDays",
        headerName: "Dienu skaits",
        sortable: false,
        width: 100,
        align: "center",
        valueGetter: (params) => {
          return params.row.timesheets.length;
        },
      },
      ...absenceTypeColumns,
    ]);
  }, [date, absenceTypes]);

  useEffect(() => {
    axios
      .get<IAbsenceTypeResponse[]>(`/classifiers/absenceTypes`)
      .then((response) => {
        setAbsenceTypes(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get<ITimesheetsReportResponse[]>("/timesheets", {
        params: {
          date: date,
          filters: {
            user: filterValues.user?._id,
            clinic: filterValues.clinic?._id,
            role: filterValues.role,
          },
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [date, filterValues]);

  const open = Boolean(menuAnchorEl);

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleExportClose = () => {
    setMenuAnchorEl(null);
  };

  const exportXlsx = useCallback(async () => {
    setIsExporting(true);

    const daysInMonth = moment(date).daysInMonth();
    const totalColumns = daysInMonth + 3 + absenceTypes.length;
    const defaultFontSize = 12;

    let sheetData: SheetData = [];

    let companyName: Row = [
      {
        value: 'SIA "ĢIMENES ZOBĀRSTNIECĪBA"',
        fontWeight: "bold",
        span: totalColumns,
      },
      ...new Array(totalColumns).fill(null),
    ];
    sheetData.push(companyName);

    let companyAddress: Row = [
      {
        value: "Valmieras iela 9, Cēsis, Cēsu novads, LV-4101",
        fontWeight: "bold",
        span: totalColumns,
      },
      ...new Array(totalColumns).fill(null),
    ];
    sheetData.push(companyAddress);

    let companyRegistrationNumber: Row = [
      {
        value: "Reģ. Nr. 40003289189",
        fontWeight: "bold",
        span: totalColumns,
      },
      ...new Array(totalColumns).fill(null),
    ];
    sheetData.push(companyRegistrationNumber);

    if (filterValues.clinic) {
      let department: Row = [
        {
          value: `struktūrvienība - ${filterValues.clinic.name}`,
          fontWeight: "bold",
          span: totalColumns,
        },
        ...new Array(totalColumns).fill(null),
      ];

      sheetData.push(department);
    }

    let abbreviations: Row = [
      {
        value: absenceTypes
          .map((absenceType) => {
            return `${absenceType.abbreviation}: ${absenceType.name}`;
          })
          .join(", "),
        span: totalColumns,
        align: "right",
      },
      ...new Array(totalColumns).fill(null),
    ];
    sheetData.push(abbreviations);

    sheetData.push([]);

    let firstRow: Row = [
      {
        value: `Darba laika uzskaite par: ${moment(date).startOf("month").format("L")}-${moment(
          date
        )
          .endOf("month")
          .format("L")}${filterValues.user ? ` - ${filterValues.user.name}` : ""}`,
        fontWeight: "bold",
        span: totalColumns,
        align: "center",
      },
      ...new Array(totalColumns).fill(null),
    ];
    sheetData.push(firstRow);
    sheetData.push([]);

    let tableHeaders: Row = [{ value: "Darbinieks", fontWeight: "bold", borderStyle: "thin" }];
    let columns = [{ width: 20 }];

    for (let i = 1; i <= daysInMonth; i++) {
      tableHeaders.push({ value: i, fontWeight: "bold", align: "center", borderStyle: "thin" });
      columns.push({ width: 5 });
    }

    tableHeaders.push({
      value: "Stundas kopā",
      fontWeight: "bold",
      align: "center",
      borderStyle: "thin",
    });
    columns.push({ width: 15 });

    tableHeaders.push({
      value: "Dienu skaits",
      fontWeight: "bold",
      align: "center",
      borderStyle: "thin",
    });
    columns.push({ width: 15 });

    absenceTypes.forEach((absenceType) => {
      tableHeaders.push({
        value: absenceType.abbreviation,
        fontWeight: "bold",
        align: "center",
        borderStyle: "thin",
      });
      columns.push({ width: 5 });
    });

    sheetData.push(tableHeaders);

    data.forEach((row) => {
      let rowData: Row = [{ value: row.user.name, fontSize: defaultFontSize, borderStyle: "thin" }];

      let totalHours = 0;

      for (let i = 1; i <= daysInMonth; i++) {
        const timesheet = row.timesheets.filter((timesheet) => {
          return moment(timesheet.startedAt).date() === i;
        });

        const absence = row.absences.find((absence) => {
          return moment(absence.from).date() <= i && moment(absence.to).date() >= i;
        });

        if (absence) {
          rowData.push({
            value: absence.type.abbreviation,
            fontSize: defaultFontSize,
            align: "center",
            borderStyle: "thin",
          });
          continue;
        }

        if (!timesheet) {
          rowData.push({ value: "", fontSize: defaultFontSize, type: Number, borderStyle: "thin" });
          continue;
        }

        let duration = moment.duration(0);

        timesheet.map((timesheet) => {
          duration.add(
            moment.duration(moment(timesheet.finishedAt).diff(moment(timesheet.startedAt)))
          );
        });

        totalHours += duration.asHours();

        let isSurgeon = false;

        timesheet.map((timesheet) => {
          if (timesheet.schedule && timesheet.schedule.type === ChairTypes.Surgeon) {
            isSurgeon = true;
          }
        });

        rowData.push({
          value: _.round(duration.asHours(), 2),
          fontSize: defaultFontSize,
          align: "center",
          backgroundColor: isSurgeon ? "#FFD966" : undefined,
          borderStyle: "thin",
        });
      }

      rowData.push({
        value: _.round(totalHours, 2),
        fontSize: defaultFontSize,
        backgroundColor: "#e6ecff",
        align: "center",
        borderStyle: "thin",
      });

      rowData.push({
        value: row.timesheets.length,
        fontSize: defaultFontSize,
        backgroundColor: "#e6ecff",
        borderStyle: "thin",
      });

      absenceTypes.forEach((absenceType) => {
        const absences = row.absences.filter((absence) => {
          return absence.type._id === absenceType._id;
        });

        let totalDays = 0;

        absences.forEach((absence) => {
          totalDays += moment(absence.to).diff(moment(absence.from), "days") + 1;
        });

        rowData.push({
          value: totalDays,
          fontSize: defaultFontSize,
          align: "center",
          backgroundColor: "#e6ecff",
          borderStyle: "thin",
        });
      });

      sheetData.push(rowData);
    });

    const fileName = `${filterValues.clinic ? `${filterValues.clinic.name}_` : ""}${
      filterValues.user ? `${filterValues.user.name}_` : ""
    }timesheet_${moment(date).startOf("month").format("L")}-${moment(date)
      .endOf("month")
      .format("L")}`.slice(0, -1);

    await writeXlsxFile(sheetData, {
      columns,
      fileName: fileName + ".xlsx",
      sheet: "timesheet",
    });

    setIsExporting(false);
  }, [data, date, filterValues, absenceTypes]);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Noziņotās stundas</Typography>
        </Grid>
        <Grid item>
          <LoadingButton
            loading={isExporting}
            startIcon={<FileDownloadIcon />}
            aria-controls={open ? "export-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="export as"
            aria-haspopup="menu"
            onClick={handleExportClick}
          >
            Eksportēt
          </LoadingButton>
          <Menu id="export-menu" anchorEl={menuAnchorEl} open={open} onClose={handleExportClose}>
            <MenuItem
              onClick={() => {
                exportXlsx();
                setMenuAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <GridOnIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="xlsx"></ListItemText>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton
            onClick={() =>
              setDate((currentDate) => moment(currentDate).subtract(1, "month").toDate())
            }
          >
            <ChevronLeftIcon />
          </IconButton>
          <Typography>
            {moment(date).startOf("month").format("LL")} -{" "}
            {moment(date).endOf("month").format("LL")}
          </Typography>
          <IconButton
            onClick={() => setDate((currentDate) => moment(currentDate).add(1, "month").toDate())}
          >
            <ChevronRightIcon />
          </IconButton>
        </Stack>

        <IconButton onClick={handleFilterClick}>
          <Badge
            color="secondary"
            badgeContent={Object.values(filterValues).filter((item) => item).length}
          >
            <FilterAltIcon />
          </Badge>
        </IconButton>
      </Stack>
      <DataGrid
        disableColumnMenu
        loading={isLoading}
        getRowId={(row) => row._id}
        autoHeight
        rows={data}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />

      <Popover
        open={Boolean(filtersAnchorEl)}
        anchorEl={filtersAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
      >
        <Filters values={filterValues} onFilterChange={handleFilterChange} />
      </Popover>
    </Stack>
  );
};

interface IFilterValues {
  user?: IUserBaseResponse;
  clinic?: ClinicResponse;
  role?: ROLES;
}

interface IFIlterProps {
  values: IFilterValues;
  onFilterChange: (values: IFilterValues) => void;
}

const Filters: React.FunctionComponent<IFIlterProps> = ({ values, onFilterChange }) => {
  const [users, setUsers] = useState<IUserBaseResponse[]>([]);
  const [clinics, setClinics] = useState<ClinicResponse[]>([]);

  useEffect(() => {
    axios
      .get<IUserBaseResponse[]>(`/users/list`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
      });

    axios
      .get<ClinicResponse[]>("/clinics")
      .then((res) => {
        setClinics(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  useEffect(() => {
    onFilterChange(values);
  }, [values]);

  return (
    <Stack spacing={2} sx={{ padding: 2, width: 400, maxWidth: "100%" }}>
      <Typography variant="h6">Filtri</Typography>
      <Autocomplete
        options={Object.keys(ROLES_LIST) as ROLES[]}
        value={values.role}
        getOptionLabel={(option) => ROLES_LIST[option].name}
        renderInput={(params) => <TextField {...params} label="Loma" />}
        onChange={(e, value) => {
          onFilterChange({
            ...values,
            role: value ? value : undefined,
          });
        }}
      />
      <Autocomplete
        options={users}
        value={values.user}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Darbinieks" />}
        renderOption={(props, option) => {
          return (
            <Stack
              {...props}
              component="li"
              direction="row"
              spacing={1}
              alignItems="center"
              key={option._id}
            >
              <Avatar alt={option.name} src={option.image} sx={{ width: 24, height: 24 }} />
              <Typography>{option.name}</Typography>
            </Stack>
          );
        }}
        onChange={(e, value) => {
          onFilterChange({
            ...values,
            user: value ? value : undefined,
          });
        }}
      />
      <Autocomplete
        options={clinics}
        value={values.clinic}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Klīnika" />}
        onChange={(e, value) => {
          onFilterChange({
            ...values,
            clinic: value ? value : undefined,
          });
        }}
      />
    </Stack>
  );
};

export default TimesheetsReports;
