import { Typography, Link } from "@mui/material";

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href={window.location.origin}>
        Schedule
      </Link>{" "}
      {new Date().getFullYear()}
      {"."} v:{process.env.REACT_APP_VERSION}
    </Typography>
  );
}

export default Copyright;
