// utils
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICartItemResponse } from "../types/cartItem";

export const getCartItems = createAsyncThunk("cart/getItems", async () => {
  const response = await axios.get<ICartItemResponse[]>("/cart");

  return response.data;
});

export const increaseCount = createAsyncThunk("cart/increaseCount", async (itemId: string) => {
  const response = await axios.put<ICartItemResponse>(`/cart/${itemId}/increase`);

  return response.data;
});

export const decreaseCount = createAsyncThunk("cart/decreaseCount", async (itemId: string) => {
  const response = await axios.put<ICartItemResponse>(`/cart/${itemId}/decrease`);

  return response.data;
});

export const removeItem = createAsyncThunk("cart/removeItem", async (itemId: string) => {
  const response = await axios.delete<ICartItemResponse>(`/cart/${itemId}`);

  return response.data;
});

interface ICart {
  cartItems: ICartItemResponse[];
}

const initialState: ICart = {
  cartItems: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // clearAuth: () => initialState,
    // setUser: (state, { payload }: { payload: any }) => {
    //   state.isAuthenticated = true;
    // },
    // clearUser: (state) => {
    //   state.isAuthenticated = false;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getCartItems.fulfilled, (state, action) => {
      state.cartItems = action.payload;
    });
    builder.addCase(increaseCount.fulfilled, (state, action) => {
      const index = state.cartItems.findIndex((item) => item._id === action.payload._id);

      state.cartItems[index] = action.payload;
    });
    builder.addCase(decreaseCount.fulfilled, (state, action) => {
      const index = state.cartItems.findIndex((item) => item._id === action.payload._id);

      state.cartItems[index] = action.payload;
    });
    builder.addCase(removeItem.fulfilled, (state, action) => {
      const index = state.cartItems.findIndex((item) => item._id === action.payload._id);

      state.cartItems.splice(index, 1);
    });
  },
});

// export const { clearAuth, setUser, clearUser } = authSlice.actions;

export default cartSlice.reducer;
