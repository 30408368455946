export enum ReportStatuses {
  Opened = "opened",
  Closed = "closed",
}

export interface IReportStatus {
  label: string;
}

type IReportStatusesList = {
  [key in ReportStatuses]: IReportStatus;
};

export const REPORT_STATUSES: IReportStatusesList = {
  [ReportStatuses.Opened]: {
    label: "Atvērts",
  },
  [ReportStatuses.Closed]: {
    label: "Aizvērts",
  },
};
