import * as yup from "yup";

const supplierSchema = yup.object({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string(),
});

export type SupplierBase = yup.InferType<typeof supplierSchema>;

export default supplierSchema;
