import { useCallback, useEffect, useState } from "react";
import { IWarehouseAddItemsModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";
import * as yup from "yup";
import axios from "axios";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  TextField,
  Stack,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import warehouseManualItemAddSchema from "../schemas/warehouse";
import { IMaterialResponse } from "../types/classifiers";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import { yupResolver } from "@hookform/resolvers/yup";

const WarehouseAddItemsModal: React.FunctionComponent<
  IWarehouseAddItemsModalProps
> = ({ id, clinic, callback }) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [materials, setMaterials] = useState<IMaterialResponse[]>([]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      count: 1,
      materialId: "",
    },
    // @ts-ignore
    resolver: yupResolver(warehouseManualItemAddSchema),
  });

  const onSubmit = useCallback(
    async (values: yup.InferType<typeof warehouseManualItemAddSchema>) => {
      try {
        console.log("values:", values);

        setIsLoading(true);
        await axios.post(`/clinics/${clinic}/warehouse`, values).then(() => {
          callback();
          handleClose();
        });

        toast.success("Materiāli pievienoti");

        callback();
        handleClose();
      } catch (error) {
        toast.error("Neizdevās pievienot materiālus");
      }

      setIsLoading(false);
    },
    []
  );

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    axios
      .get<IMaterialResponse[]>("/classifiers/materials")
      .then((res) => {
        setMaterials(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={true} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Pievienot materiālu noliktavai</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Controller
              name="materialId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={materials}
                  getOptionLabel={(option) => option.name}
                  value={materials.find(
                    (material) => field.value === material._id
                  )}
                  onChange={(
                    event: any,
                    newValue: IMaterialResponse | null
                  ) => {
                    setValue("materialId", newValue ? newValue._id : "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Materiāls"
                      error={Boolean(errors.materialId)}
                    />
                  )}
                />
              )}
            />

            <Controller
              name="count"
              control={control}
              render={({ field }) => (
                <NumericFormat
                  {...field}
                  customInput={TextField}
                  min={1}
                  max={1000}
                  InputProps={{
                    startAdornment: (
                      <Button
                        size="large"
                        disabled={field.value === 1}
                        onClick={() => {
                          setValue("count", field.value - 1);
                        }}
                      >
                        -
                      </Button>
                    ),
                    endAdornment: (
                      <Button
                        size="large"
                        disabled={field.value >= 1000}
                        onClick={() => {
                          setValue("count", field.value + 1);
                        }}
                      >
                        +
                      </Button>
                    ),
                  }}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  allowNegative={false}
                  fixedDecimalScale
                  placeholder="Daudzums"
                  label="Daudzums"
                  fullWidth
                  error={Boolean(errors.count)}
                  helperText={errors.count?.message}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Aizvērt</Button>
          <LoadingButton loading={isLoading} type="submit" variant="contained">
            Pievienot
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default WarehouseAddItemsModal;
