import { useEffect, useState } from "react";
import { IAddToCartProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import { useFormik } from "formik";
import axios from "axios";
import _ from "lodash";
import { toast } from "react-toastify";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  TextField,
  Stack,
  Autocomplete,
  Typography,
  Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { ClinicResponse } from "../types/clinic";
import { IMaterialSupplierResponse } from "../types/classifiers";
import addToCartSchema, { AddToCartBase } from "../schemas/addToCart";
import { getCartItems } from "../features/cart";

const AddToCart: React.FunctionComponent<IAddToCartProps> = ({ id, material }) => {
  const dispatch = useAppDispatch();

  const [clinics, setClinics] = useState<ClinicResponse[]>([]);

  const { values, handleChange, setFieldValue, handleSubmit, isSubmitting, errors, submitCount } =
    useFormik<AddToCartBase>({
      initialValues: {
        count: 1,
        supplierId: "",
        clinicId: "",
      },
      onSubmit: (values, { setSubmitting }) => {
        setSubmitting(true);
        axios
          .post(`/cart`, {
            materialId: material._id,
            ...values,
          })
          .then(() => {
            // callback();
            dispatch(getCartItems());
            handleClose();
            toast.success("Materiāls pievienots pasūtījumam");
          })
          .catch((err) => {
            console.log("err:", err);
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
      validationSchema: addToCartSchema,
    });

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    if (material.suppliers.length > 0) {
      const supplier = _.minBy(material.suppliers, "price");

      setFieldValue("supplierId", supplier?.supplier._id);
    }
  }, [material.suppliers]);

  useEffect(() => {
    axios
      .get<ClinicResponse[]>("/clinics")
      .then((res) => {
        setClinics(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle>Pievienot materiālu pasūtījumam</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Autocomplete
            options={clinics}
            getOptionLabel={(option) => option.name}
            value={clinics.find((clinic) => values.clinicId === clinic._id) || null}
            onChange={(event: any, newValue: ClinicResponse | null) => {
              setFieldValue("clinicId", newValue ? newValue._id : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Klīnika"
                error={Boolean(errors.clinicId) && submitCount > 0}
              />
            )}
          />
          <Autocomplete
            options={material.suppliers}
            getOptionLabel={(option) => `${option.supplier.name} (€${option.price})`}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    flex={1}
                  >
                    <Typography>{option.supplier.name}</Typography>
                    <Chip label={`€${option.price}`} />
                  </Stack>
                </li>
              );
            }}
            value={
              material.suppliers.find((supplier) => values.supplierId === supplier.supplier._id) ||
              null
            }
            onChange={(event: any, newValue: IMaterialSupplierResponse | null) => {
              setFieldValue("supplierId", newValue ? newValue.supplier._id : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Piegādātājs"
                error={Boolean(errors.supplierId) && submitCount > 0}
              />
            )}
          />
          <TextField
            id="count"
            label="Daudzums"
            variant="standard"
            type="number"
            value={values.count}
            onChange={handleChange}
            error={Boolean(errors.count) && submitCount > 0}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Aizvērt</Button>
        <LoadingButton
          loading={isSubmitting}
          onClick={() => {
            handleSubmit();
          }}
          variant="contained"
        >
          Pievienot
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddToCart;
