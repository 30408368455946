import { IApproveModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";

const ApproveModal: React.FunctionComponent<IApproveModalProps> = ({
  id,
  title,
  message,
  onApprove,
  onReject,
}) => {
  const dispatch = useAppDispatch();

  const handleApprove = () => {
    onApprove();
    handleClose();
  };

  const handleReject = () => {
    if (onReject) {
      onReject();
    }
    handleClose();
  };

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={true} onClose={handleReject}>
      <DialogTitle>{title}</DialogTitle>
      {message ? (
        <DialogContent>
          <Typography>{message}</Typography>
        </DialogContent>
      ) : null}
      <DialogActions>
        <Button onClick={handleReject}>Atcelt</Button>
        <Button onClick={handleApprove}>Apstiprināt</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveModal;
