import { useEffect, useState, useCallback } from "react";

import MODALS from "../../../constants/modals";

import { useAppDispatch } from "../../../hooks/redux";
import { openModal } from "../../../features/modals";
import axios from "axios";

import { Stack, Grid, Typography, Button } from "@mui/material";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { IPaginationResponse } from "../../../types/common";
import { ISupplierResponse } from "../../../types/classifiers";
import PermissionGuard from "../../../components/PermissionGuard";
import PERMISSIONS from "../../../constants/permissions";



const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Nosaukums",
    sortable: true,
    flex: 1,
  },
  {
    field: "email",
    headerName: "E-pasts",
    sortable: false,
    flex: 1,
  },
  {
    field: "phone",
    headerName: "Tālrunis",
    sortable: false,
    flex: 1,
  },
];

const Suppliers: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<IPaginationResponse<ISupplierResponse>>();
  const [filters, setFilters] = useState({
    page: 0,
    pageSize: 25,
  });

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IPaginationResponse<ISupplierResponse>>("/classifiers/suppliers/list", { params: filters })
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filters]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Piegādātāji</Typography>
        </Grid>
        <PermissionGuard permission={PERMISSIONS.WRITE_SUPPLIERS}>
          {({ hasAccess }) => (
            <>
              {hasAccess ? (
                <Grid item>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      dispatch(
                        openModal({
                          type: MODALS.Supplier,
                          callback: () => loadData(),
                        })
                      );
                    }}
                  >
                    Pievienot
                  </Button>
                </Grid>
              ) : null}
            </>
          )}
        </PermissionGuard>
      </Grid>
      <DataGrid
        sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
        loading={isLoading}
        getRowId={(row) => row._id}
        onRowClick={(params) => {
          dispatch(
            openModal({
              type: MODALS.Supplier,
              initialValues: params.row,
              callback: () => loadData(),
            })
          );
        }}
        rows={response ? response.docs : []}
        onPageSizeChange={(pageSize) => {
          setFilters({ ...filters, page: 0, pageSize: pageSize });
        }}
        onPageChange={(page) => {
          setFilters({ ...filters, page: page });
        }}
        page={filters.page}
        pageSize={filters.pageSize}
        paginationMode="server"
        rowCount={response ? response.totalDocs : 0}
        columns={columns}
        autoHeight
        disableSelectionOnClick
        disableColumnMenu
      />
    </Stack>
  );
};

export default Suppliers;
