import * as yup from "yup";

const apartmentReservationSchema = yup.object({
  from: yup.date().required(),
  to: yup.date().required(),
});

export type ApartmentReservationBase = yup.InferType<typeof apartmentReservationSchema>;

export default apartmentReservationSchema;
