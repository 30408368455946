// utils
import { useAuth0 } from "@auth0/auth0-react";

import { Container, Stack, Box, Avatar, Button } from "@mui/material";

import Copyright from "../components/Copyright";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import logo from "../assets/img/logo.png";

const Auth = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Stack style={{ flex: 1 }} alignItems="center" justifyContent="center">
        <img src={logo} alt="Logo" style={{ maxWidth: 300 }} />
        <Box sx={{ mt: 1 }}>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => loginWithRedirect()}
          >
            Pieslēgties
          </Button>
        </Box>
      </Stack>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

export default Auth;
